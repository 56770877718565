import {
  Box,
  Typography,
} from "@material-ui/core";
import Button from "@components/Button";
import useFetch from "@services/useFetch.js";
import React, { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { Title } from "./style.js";

// Internacionalização
import { i18n } from '../../../../translate/i18n';

export default function Completed({ viewResult, preview }) {
  const { surveyKey, participantKey } = useParams();
  const api = useFetch();
  const [execIntegration, setExecIntegration] = useState(false);

  const handleClickResult = useCallback(
    async (e) => {
      e.preventDefault();
      setExecIntegration(true);
      await handleIntegration(e, api, { surveyKey, participantKey });
      setExecIntegration(false);
    },
    [api, participantKey, surveyKey]
  );

  return (
    <Title>
      <Typography variant="h4" gutterBottom>
        {i18n.t("pages.answer.completed.surveyCompleted")}
      </Typography>
      <Typography>{i18n.t("pages.answer.completed.answerSended")}</Typography>
      {viewResult && !preview ? (
        <Box style={{ paddingTop: "30px" }}>
          <Typography>{i18n.t("pages.answer.completed.clickAccessResult")}</Typography>
          <br/><br/>
          <Button disabled={execIntegration} onClick={handleClickResult}>
            {execIntegration ? i18n.t("messages.wait") : i18n.t("pages.answer.completed.accessResult")}
          </Button>
        </Box>
      ) : (
        <></>
      )}
    </Title>
  );
}

export async function handleIntegration(e, api, { surveyKey, participantKey }) {
  e.preventDefault();
  window.open(`${window.origin}/answer/result/${surveyKey}/${participantKey}`);
}
