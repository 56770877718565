import styled from "styled-components";

export const DrawerContainer = styled.div``;

export const Container = styled.div`
  .buttons {
    display: inline-flex;
    width: 100%;
    justify-content: flex-end;
  }
  .buttons > div {
    margin-right: 20px;
  }
`;

export const Form = styled.div`
  padding: 40px;
  width: 90vw;

  .buttons-container {
    width: 100%;
    text-align: right;
    margin-top: 50px;
  }

  .buttons {
    display: inline-flex;
    width: min-content;
  }

  .buttons button {
    margin-left: 10px;
  }

  .spacing {
    margin-top: 50px;
  }

  @media (max-width: 800px) {
    .buttons button {
      margin-left: 5px;
    }
  }
`;

export const Title = styled.div`
  display: flex;
  .buttons {
    width: min-content;
    height: min-content;
    margin-top: 8px;
  }
`;

export const TitleContainer = styled.div`
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  background-color: #fff;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  width: 95%;
  margin-right: 5%;
`;
