import {Icon,} from "@material-ui/core";
import {i18n} from "../../../../translate/i18n";
import DataGrid, {Column, Sorting} from "devextreme-react/data-grid";
import React from "react";
import {ButtonGroup} from "@components/Button";
import {Button} from "@components/index";

const CampaignOccurrences = ({occurrences, handleParticipants}) => {

  const actionButtons = ({ data }) =>
    <ButtonGroup color="default" size="small">
      <Button>
        <Icon fontSize="small" onClick={() => handleParticipants(data)}>groups</Icon>
      </Button>
    </ButtonGroup>;

  return <>
    <DataGrid
      dataSource={occurrences || []}
      allowColumnResizing={true}
      noDataText={ i18n.t('messages.noRecordsFound') }
      columnResizingMode="widget"
      keyExpr="id"
      style={{
        maxHeight: "calc(100vh - 150px)",
        marginTop: "14px"
      }}
      wordWrapEnabled={true}
      columnAutoWidth={true}
      showBorders={true}
      showRowLines={true}
      showColumnLines={true}
    >
      <Sorting  mode="single" />

      <Column dataField="name" caption={ i18n.t('pages.campaign.occurrences.list.occurrence') } />

      <Column dataField="createdate"
              dataType="datetime"
              defaultSortOrder="desc"
              allowSorting={true}
              caption={ i18n.t('pages.campaign.occurrences.list.startDate') }
              width={140}
              alignment="center"/>

      <Column dataField="finishdate"
              dataType="datetime"
              caption={ i18n.t('pages.campaign.occurrences.list.stopDate') }
              width={140}
              alignment="center" />

      <Column dataField="status"
              caption={ i18n.t('pages.campaign.occurrences.list.status') }
              width={100}
              alignment="center"
/*               cellRender={({data: {enabled}}) => {
                  return i18n.t("pages.campaign.occurrences.list.".concat(enabled ? 'active' : 'inactive'));
              }}  */
              />

      <Column dataField="surveysent"
              caption={ i18n.t('pages.campaign.occurrences.list.response') }
              width={100}
              alignment="center" />

      <Column dataField="participants"
              caption={ i18n.t('pages.campaign.occurrences.list.participants') }
              width={100}
              alignment="center" />

      <Column cellRender={actionButtons}
              width={60}
              alignment="center" />
    </DataGrid>
  </>
};

export default CampaignOccurrences;