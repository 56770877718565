import React, {useCallback, useEffect, useState} from "react";
import useFetch from "@services/useFetch";
import {useAuth} from "@contexts/auth";
import {useSnackbar} from "notistack";
import {i18n} from '../../../../translate/i18n';
import CampaignList from "./CampaignList";
import CampaignForm from "./CampaignForm";
import CampaignDialog from "./CampaignDialog";
import CampaignOccurrences from "./CampaignOccurrences";
import LoadPanel from "devextreme-react/load-panel";
import CampaignOccurrenceParticipants from "./CampaignOccurrenceParticipants";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import {Button} from "@components/index";

const Campaign = ({ surveyId, handleClosed }) => {
  const api = useFetch();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();

  const [campaign, setCampaign] = useState(null);
  const [campaigns, setCampaigns] = useState(null);
  const [recurrences, setRecurrences] = useState(null);
  const [occurrences, setOccurrences] = useState(null);
  const [occurrenceParticipants, setOccurrenceParticipants] = useState(null);
  const [participants, setParticipants] = useState(null);
  const [load, setLoad] = useState(false);
  const [activePage, setActivePage] = useState("campaigns");
  const [actionsOccurrence, setActionsOccurrence] = useState({ confirmation: false, submit: false})

  const findAllCampaigns = useCallback(() => {
    setLoad(true);
    api.get(`/u/project/${user.projectId}/survey/${surveyId}/campaign`)
      .then(({data}) => setCampaigns(data))
      .catch(() => enqueueSnackbar(i18n.t("messages.onloadError"), {variant: "error"}))
      .then(() => setLoad(false));
  }, [api, enqueueSnackbar, surveyId, user]);

  const findAllRecurrences = () => {
    if ( recurrences != null )
      return;

    api.get(`/u/project/${user.projectId}/trigger`)
      .then(({ data }) => {
        setRecurrences(data);
      })
      .catch(() => {
        enqueueSnackbar(i18n.t("messages.onloadError"), {variant: "error"});
      })
  };

  const findAllOccurrences = (campaign) => {
    setLoad(true);
    api.get(`/u/project/${user.projectId}/occurrence/campaign/${campaign.id}`)
      .then(({ data }) => {
        setOccurrences(data);
      })
      .catch(() => {
        enqueueSnackbar(i18n.t("messages.onloadError"), {variant: "error"});
      })
      .then(() => setLoad(false))
  };

  const findAllOccurrenceParticipants = (occurrence) => {
    setLoad(true);
    api.get(`/u/project/${user.projectId}/occurrence/${occurrence.id}/participants`)
      .then(({ data }) => {
        setOccurrenceParticipants(data);
      })
      .catch(() => {
        enqueueSnackbar(i18n.t("messages.onloadError"), {variant: "error"});
      })
      .then(() => setLoad(false))
  };

  const findAllParticipants = (data) => {
    setParticipants(null);
    const campaignPath = data == null ? '' : `${data.id}/`;

    api.get(`/u/project/${user.projectId}/campaign/${campaignPath}participants`)
      .then(({data}) => setParticipants(data))
      .catch(() => {
        enqueueSnackbar(i18n.t("messages.onloadError"), {variant: "error"});
      })
  };

  const validateNewTrigger = ({idschtrigger}) => {
    if ( !idschtrigger ) {
      setRecurrences(null);
      findAllRecurrences();
    }
  }

  const handleSaved = (data, callback) => {
    const body = { ...data, surveyid: surveyId, participant: data.participants };
    delete body.participants;

    api.post(`/u/project/${user.projectId}/campaign`, body)
      .then(() => {
        findAllCampaigns();
        validateNewTrigger(data);
        setCampaign(null);
        setActivePage("campaigns");
        enqueueSnackbar(i18n.t("messages.saveSuccess"), {variant: "success"});
      })
      .catch(() => {
        enqueueSnackbar(i18n.t("messages.saveError"), {variant: "error"});
      })
      .then(() => callback())
  };

  const handleEdited = (data, callback) => {
    const body = {
      ...data,
      surveyid: surveyId,
      idschtriggerjob: campaign.schtriggerjob.idschtriggerjob,
      participant: data.participants
    };
    delete body.participants;

    api.put(`/u/project/${user.projectId}/campaign/${campaign.id}`, body)
      .then(() => {
        findAllCampaigns();
        validateNewTrigger(data);
        setCampaign(null);
        setActivePage("campaigns");
        enqueueSnackbar(i18n.t("messages.updateSuccess"), {variant: "success"});
      })
      .catch(() => {
        enqueueSnackbar(i18n.t("messages.updateError"), {variant: "error"});
      })
      .then(() => callback())
  };

  const handleDeleted = async (data) => {
    await api.delete(`/u/project/${user.projectId}/campaign/${data.id}`)
      .then(() => {
        enqueueSnackbar(i18n.t("messages.campaignExcluded"), {variant: "success"});
        findAllCampaigns();
      })
      .catch(() => {
        enqueueSnackbar(i18n.t("messages.campaignNotExcluded"), {variant: "error"});
      });
  };

  const handleOccurrences = (data) => {
    setActivePage("campaigns.occurrences");
    setCampaign(data)
    findAllOccurrences(data);
  }

  const handleOccurrenceParticipants = (data) => {
    setOccurrenceParticipants(null);
    setActivePage("campaigns.occurrences.participants")
    findAllOccurrenceParticipants(data);
  }

  const handleInitOccurrence = async () => {
    setActionsOccurrence(old => ({...old, submit: true}));
    await api.post(`/u/project/${user.projectId}/occurrence/campaign/${campaign.id}/async`)
        .then(() => {
          enqueueSnackbar(i18n.t("pages.campaign.occurrences.list.occurrenceCreated"), {variant: "warning"});
          enqueueSnackbar(i18n.t("messages.saveSuccess"), {variant: "success"});
          findAllOccurrences(campaign);
        })
        .catch(() => {
          enqueueSnackbar(i18n.t("messages.saveError"), {variant: "error"});
        })
        .then(() => setActionsOccurrence({confirmation: false, submit: false}));
  };

  const handleSendEmailParticipants = async (participantsKey) => {
    setLoad(true)
    await api.post(`/u/project/${user.projectId}/survey/${surveyId}/publish/participants/notify`, participantsKey)
        .then(() => {
          enqueueSnackbar(i18n.t("messages.saveSuccess"), {variant: "success"});
        })
        .catch(() => {
          enqueueSnackbar(i18n.t("messages.saveError"), {variant: "error"});
        })
        .then(() => setLoad(false));
  };

  const toggleForm = (isOpen, data) => {
    if ( activePage === "campaigns" || activePage === "campaigns.create" || activePage === "campaigns.edit" ) {
      toggleFormCampaign(isOpen, data)
    } else if ( activePage === "campaigns.occurrences" ) {
      setActionsOccurrence(old => ({...old, confirmation: true}));
    }
  };

  const toggleFormCampaign = (isOpen, data) => {
    if ( !isOpen ) {
      setActivePage("campaigns")
      setCampaign(null);
    } else {
      const campaignAdjuste = adjustDate(data || {})
      setActivePage(campaignAdjuste.id ? "campaigns.edit" : "campaigns.create")
      setCampaign(campaignAdjuste);
      findAllRecurrences();
      findAllParticipants(data);
    }

  };

  const adjustDate = (campaign) => {
    if (!!campaign.startdate)
      campaign.startdate = campaign.startdate.replace("T", " ");
    if (!!campaign.stopdate)
      campaign.stopdate = campaign.stopdate.replace("T", " ");
    return campaign;
  };

  const onSave = (data, callback) => {
    !campaign.id ? handleSaved(data, callback) : handleEdited(data, callback);
  };

  useEffect(() => {
    findAllCampaigns();
  }, []);

  const campaignPages = [
    {key: "campaigns", description: "pages.campaign.dialog.list", pages: [
      {key: "create", description: "pages.campaign.dialog.create"},
      {key: "edit", description: "pages.campaign.dialog.edit"},
      {key: "occurrences", description: "pages.campaign.dialog.occurrences.list", pages: [
        {key: "participants", description: "pages.campaign.dialog.occurrences.participants"},
      ]}
    ]}
  ];

  return (
    <React.Fragment>
      <CampaignDialog
        handleClosed={handleClosed}
        headerAction={
          ( activePage === "campaigns" || activePage === "campaigns.occurrences" ) &&
          (() => toggleForm(true, null))
        }
        pageList={campaignPages}
        pageActive={activePage}
        setActivePage={setActivePage}>

        { load &&
            <LoadPanel shadingColor="rgba(0,0,0,0.4)"
                       visible={true}
                       position={{of: ".MuiDialog-container"}}
                       message={i18n.t("messages.loading")} />
        }

        { activePage === "campaigns" &&
            <CampaignList campaigns={campaigns}
                          handleDeleted={handleDeleted}
                          handleEdited={(data) => toggleForm(true, data)}
                          handleOccurrences={handleOccurrences} /> }

        { ( activePage === "campaigns.create" || activePage === "campaigns.edit" ) &&
            <CampaignForm participants={participants}
                          recurrences={recurrences}
                          campaign={{
                            ...campaign,
                            startdate: !campaign?.startdate ? null : new Date(Date.parse(campaign.startdate)),
                            stopdate: !campaign?.stopdate ? null : new Date(Date.parse(campaign.stopdate))
                          }}
                          handleClosed={() => toggleForm(false)}
                          handleSubmit={onSave} /> }

        { activePage === "campaigns.occurrences" &&
            <CampaignOccurrences occurrences={occurrences}
                                 handleParticipants={handleOccurrenceParticipants} /> }

        { activePage === "campaigns.occurrences.participants" &&
            <CampaignOccurrenceParticipants participants={occurrenceParticipants}
                                            handleNotifyParticipants={handleSendEmailParticipants}
                                            submit={load}/> }

      </CampaignDialog>

      <Dialog open={actionsOccurrence.confirmation}
              onClose={() => setActionsOccurrence({confirmation: false, submit: false})}>
        <DialogTitle>{i18n.t("messages.confirmation")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {i18n.t("messages.initOccurrence")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleInitOccurrence()} disabled={actionsOccurrence.submit}>
            {!actionsOccurrence.submit ? i18n.t("buttons.yes") : i18n.t("messages.processing")}
          </Button>
          <Button onClick={() => setActionsOccurrence({confirmation: false, submit: false})}
                  disabled={actionsOccurrence.submit}>{i18n.t("buttons.no")}</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default Campaign;