import React, { useState, forwardRef } from "react";

import { Button, TextField, HtmlEditor } from "../../../components";

import { Form } from "../../style";

import {
  Grid,
  Slide,
  Select,
  Dialog,
  AppBar,
  Toolbar,
  MenuItem,
  InputLabel,
  IconButton,
  Typography,
  FormControl,
} from "@material-ui/core";

import {
  Close as CloseIcon,
  Cancel as CancelIcon,
  Backspace as BackspaceIcon,
  AddCircle as AddCircleIcon,
} from "@material-ui/icons";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  dialog: {
    top: "64px !important",
    bottom: "0",
    //left: "70px !important",
    right: "0",
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  formControl: {
    margin: 12,
    minWidth: 350,
  },
}));

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function FullScreenDialog({ handleNovo }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const [clients, setClients] = useState([]);
  const [client, setClient] = useState("");

  const [name, setName] = useState("");
  const [repository, setRepository] = useState("");
  const [description, setDescription] = useState("");

  function clean() {
    setName("");
    setClient("");
    setRepository("");
    setDescription("");
    setClients("");
  }

  return (
    <div>
      <Button startIcon={<AddCircleIcon />} onClick={() => setOpen(true)}>
        Novo
      </Button>
      <Dialog
        fullScreen
        className={classes.dialog}
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setOpen(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Novo Projeto
            </Typography>
            <Button
              onClick={() => {
                handleNovo.bind({
                  name,
                  repository,
                  description,
                })();
                setOpen(false);
              }}
            >
              Salvar
            </Button>
          </Toolbar>
        </AppBar>
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <TextField
                onChange={(e) => setName(e.target.value)}
                value={name}
                label="Nome"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography color="primary" component="label">
                Descrição
              </Typography>
              <HtmlEditor
                value={description}
                onValueChanged={(e) => setDescription(e.value)}
                height={300}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                onChange={(e) => setRepository(e.target.value)}
                value={repository}
                height={20}
                label="Repositório"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl className={classes.formControl} variant="outlined">
                <InputLabel id="select-label">Cliente</InputLabel>
                <Select
                  labelId="select-label"
                  value={client}
                  onChange={(e) => setClient(e.target.value)}
                  variant="outlined"
                  label="Cliente"
                >
                  <MenuItem value="" disabled>
                    Selecione o Cliente
                  </MenuItem>
                  {clients.map((data, index) => {
                    return (
                      <MenuItem key={index} value={data}>
                        {data}
                      </MenuItem>
                    );
                  })}
                  {clients.map((data, index) => {
                    return (
                      <MenuItem key={index} value={data}>
                        {data}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <div className="buttons-container">
            <div className="buttons">
              <Button color="secondary" startIcon={<CancelIcon />}>
                Cancelar
              </Button>
              <Button onClick={clean} startIcon={<BackspaceIcon />}>
                Limpar
              </Button>
            </div>
          </div>
        </Form>
      </Dialog>
    </div>
  );
}
