import React from "react";
import TextFieldUI from "@material-ui/core/TextField";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import ptLocale from "date-fns/locale/pt-BR";
import {
  KeyboardDatePicker,
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

export default function TextField(props) {
  if (props.date || props.dateTime) {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
        {keyboardPicker(props)}
      </MuiPickersUtilsProvider>
    );
  } else
    return <TextFieldUI variant="outlined" size="small" fullWidth {...props} />;
}

function keyboardPicker(props) {
  if (props.date) {
    return (
      <KeyboardDatePicker
        autoOk
        variant="inline"
        inputVariant="outlined"
        size="small"
        format="dd/MM/yyyy"
        placeholder="00/00/0000"
        invalidDateMessage="Data inválida"
        maxDateMessage="A data não deve ser maior que a data máxima"
        minDateMessage="A data não deve ser menor que a data mínima"
        fullWidth
        {...{ ...props, date: "true" }}
      />
    );
  } else if (props.dateTime) {
    return (
      <KeyboardDateTimePicker
        autoOk
        variant="inline"
        inputVariant="outlined"
        size="small"
        format="dd/MM/yyyy HH:mm"
        placeholder="00/00/0000 00:00"
        invalidDateMessage="Data e horário inválido"
        maxDateMessage="A data e horário não devem ser maiores que a data e horário máximo"
        minDateMessage="A data e horário não devem ser menores que a data e horário mínimo"
        fullWidth
        ampm={false}
        {...{ ...props, dateTime: "true" }}
      />
    );
  }
}
