import React, { memo, useCallback, useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  Icon,
  IconButton,
  InputAdornment,
  InputBase,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { PageCard, PageHeader, PageContent } from "../style";

// Internacionalização
import { i18n } from '../../../../translate/i18n';

import PageSurveyContext from "./PageSurveyContext";
import QuestionList from "../Question/QuestionList";

import { useDrop } from "react-dnd";
import clsx from "clsx";
import { Button } from "../../../../components";

function PageSurvey({
  index,
  pageSurveyData,
  savePageSurvey,
  deletePageSurvey,
  excludingPage,
  setExcludingPage,
}) {
  const classes = useStyles();
  const [title, setTitle] = useState(pageSurveyData.title);
  const [fadeScreen, setFadeScreen] = useState(true);
  const [pageSurveyDelete, setPageSurveyDelete] = useState(null);
  const [savingTitle, setSavingTitle] = useState(false);

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: "questionType",
    drop: () => ({ pageSurveyData }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  const isActive = canDrop && isOver;

  useEffect(() => {
    if (!fadeScreen) {
      setFadeScreen(true);
      setTimeout(() => {
        deletePageSurvey(pageSurveyData);
      }, 195);
    }
  }, [deletePageSurvey, fadeScreen, pageSurveyData]);

  const handleDelete = useCallback(
    (event) => {
      setExcludingPage(true);
      setFadeScreen(false);
      setPageSurveyDelete(null);
    },
    [setExcludingPage]
  );

  const handleConfirmDelete = useCallback(
    (event) => {
      if (pageSurveyData.questions.length === 0) {
        handleDelete(event);
      } else {
        setPageSurveyDelete(pageSurveyData);
      }
    },
    [handleDelete, pageSurveyData]
  );

  return (
    <PageSurveyContext.Provider value={{ pageSurveyData }}>
      <Fade in={fadeScreen}>
        <PageCard id={`page${index}`}>
          <PageHeader className={classes.pageHeader}>
            <InputBase
              className={classes.inputHeader}
              fullWidth
              placeholder={ i18n.t("pages.survey.pageSurvey.noTitle") }
              value={title}
              onChange={(event) => setTitle(event.target.value)}
              onBlur={(event) => {
                setSavingTitle(true);
                savePageSurvey(
                  { ...pageSurveyData, title: event.target.value },
                  () => {
                    setSavingTitle(false);
                  }
                );
              }}
              inputProps={{ "aria-label": "naked" }}
              readOnly={!pageSurveyData.id}
              endAdornment={
                <InputAdornment position="end">
                  {!pageSurveyData.id || savingTitle ? (
                    <IconButton
                      tabIndex="-1"
                      className={clsx("spinning", classes.iconLoading)}
                    >
                      <Icon fontSize="small">autorenew</Icon>
                    </IconButton>
                  ) : (
                    <IconButton
                      tabIndex="-1"
                      className={classes.iconDelete}
                      onClick={handleConfirmDelete}
                      disabled={excludingPage}
                    >
                      <Icon fontSize="small">delete</Icon>
                    </IconButton>
                  )}
                  <Typography noWrap>
                  { i18n.t("pages.survey.pageSurvey.page") } {pageSurveyData.pageNumber}
                  </Typography>
                </InputAdornment>
              }
            />
          </PageHeader>
          <PageContent
            ref={drop}
            className={clsx({
              [classes.dragging]: canDrop,
              [classes.draggingOver]: isActive,
            })}
          >
            <QuestionList />
          </PageContent>
        </PageCard>
      </Fade>
      <Dialog
        open={!!pageSurveyDelete}
        onClose={() => setPageSurveyDelete(null)}
      >
        <DialogTitle>{ i18n.t("messages.confirmation") }</DialogTitle>
        <DialogContent>
          <DialogContentText>
            { i18n.t("pages.survey.pageSurvey.deleteConfirmation") }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDelete}>{ i18n.t("buttons.yes") }</Button>
          <Button onClick={() => setPageSurveyDelete(null)}>{ i18n.t("messages.no") }</Button>
        </DialogActions>
      </Dialog>
    </PageSurveyContext.Provider>
  );
}

const useStyles = makeStyles((theme) => ({
  pageHeader: {
    backgroundColor: theme.palette.primary[theme.palette.type],
    color: theme.palette.primary.contrastText,
  },
  inputHeader: {
    color: theme.palette.primary.contrastText,
    border: "none",
  },
  iconDelete: {
    color: theme.palette.primary.contrastText,
  },
  iconLoading: {
    color: theme.palette.primary.contrastText,
  },
  dragging: {
    borderColor: `${theme.palette.primary[theme.palette.type]} !important`,
    zIndex: 1,
    "&:before": {
      content: '" "',
      position: "absolute",
      top: "0",
      bottom: "0",
      left: "0",
      right: "0",
      zIndex: 2,
      backgroundColor: theme.palette.background.paper,
      transition: theme.transitions.create("backgroundColor", {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    "&:after": {
      content: i18n.t("pages.survey.pageSurvey.dragging"),
      position: "absolute",
      top: "0",
      bottom: "0",
      left: "0",
      right: "0",
      display: "flex",
      flex: "1",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 3,
      backgroundColor: theme.palette.primary.opacity1,
      transition: theme.transitions.create("backgroundColor", {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
  draggingOver: {
    "&:after": {
      content: i18n.t("pages.survey.pageSurvey.dragging"),
      backgroundColor: theme.palette.primary.opacity5,
      color: "#fff",
    },
  },
}));

export default memo(PageSurvey);
