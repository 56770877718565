import React from "react";
import GridChild from "./GridChild";
import {
  MobileScrolling,
  ComponentGrid,
  ComponentGridHeader,
  ComponentGridBody,
  ComponentGridColumn,
  ComponentGridRow,
} from "../style";

const Grid = ({ question, answer, handleChanged, justSee }) => {
  const columns = question.children[0].options;

  return (
    <MobileScrolling>
      <ComponentGrid>
        <ComponentGridHeader>
          <ComponentGridColumn></ComponentGridColumn>
          {columns.map((column, index) => (
            <ComponentGridColumn key={index}>{column.description}</ComponentGridColumn>
          ))}
        </ComponentGridHeader>
        {question.children.map((child, index) => {
          return (
            <ComponentGridBody className="grid_child" key={index}>
              <ComponentGridRow>{child.description}</ComponentGridRow>
              <GridChild
                question={question}
                answer={answer}
                justSee={justSee}
                handleChanged={handleChanged}
                child={child}
              />
            </ComponentGridBody>
          );
        })}
      </ComponentGrid>
    </MobileScrolling>
  );
};

export default Grid;
