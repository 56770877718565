import React, { useState, forwardRef } from "react";

import { Button, TextField } from "../../../components";

import { Form } from "../../style";

import {
  Grid,
  Slide,
  Select,
  Dialog,
  AppBar,
  Toolbar,
  MenuItem,
  InputLabel,
  IconButton,
  Typography,
  FormControl,
} from "@material-ui/core";

import {
  Close as CloseIcon,
  Cancel as CancelIcon,
  Backspace as BackspaceIcon,
  AddCircle as AddCircleIcon,
} from "@material-ui/icons";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  dialog: {
    top: "64px !important",
    bottom: "0",
    //left: "70px !important",
    right: "0",
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  formControl: {
    margin: 12,
    minWidth: 350,
  },
}));

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function FullScreenDialog({ handleNovo }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [cnpj, setCnpj] = useState("");
  const [status, setStatus] = useState("");
  const [fantasyName, setFantasyName] = useState("");
  const [socialReason, setSocialReason] = useState("");

  function clean() {
    setCnpj("");
    setStatus("");
    setFantasyName("");
    setSocialReason("");
  }

  return (
    <div>
      <Button startIcon={<AddCircleIcon />} onClick={() => setOpen(true)}>
        Novo
      </Button>
      <Dialog
        fullScreen
        className={classes.dialog}
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setOpen(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Novo Cliente
            </Typography>
            <Button
              onClick={() => {
                handleNovo.bind({ cnpj, status, fantasyName, socialReason });
                setOpen(false);
              }}
            >
              Salvar
            </Button>
          </Toolbar>
        </AppBar>
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                onChange={(e) => setCnpj(e.target.value)}
                value={cnpj}
                label="CNPJ"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                onChange={(e) => setFantasyName(e.target.value)}
                value={fantasyName}
                label="Nome Fantasia"
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                onChange={(e) => setSocialReason(e.target.value)}
                value={socialReason}
                label="Razão Social"
              />
            </Grid>
            <FormControl className={classes.formControl} variant="outlined">
              <InputLabel id="select-label">Status</InputLabel>
              <Select
                labelId="select-label"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                variant="outlined"
                label="Status"
              >
                <MenuItem value="" disabled>
                  Selecione o status do cliente
                </MenuItem>
                <MenuItem value="Ativo">Ativo</MenuItem>
                <MenuItem value="Inativo">Inativo</MenuItem>
                <MenuItem value="Suspenso">Suspenso</MenuItem>
                <MenuItem value="Cancelado">Cancelado</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <div className="buttons-container">
            <div className="buttons">
              <Button
                color="secondary"
                onClick={() => setOpen(false)}
                startIcon={<CancelIcon />}
              >
                Cancelar
              </Button>
              <Button onClick={clean} startIcon={<BackspaceIcon />}>
                Limpar
              </Button>
            </div>
          </div>
        </Form>
      </Dialog>
    </div>
  );
}
