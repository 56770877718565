import React from "react";
import styled from "styled-components";
import {
  Box,
  TextField,
  Radio,
  FormControlLabel,
  RadioGroup,
  FormGroup,
  Checkbox,
} from "@material-ui/core";

export const ComponentContent = styled(Box)`
  padding-left: 36px;
  padding-right: 24px;
  padding-top: 1px;
  padding-bottom: 30px;
  margin-bottom: 12px;
  @media (max-width: 599px) {
    padding-left: 5px;
    padding-right: 5px;
    margin-left: -16px;
    margin-right: -16px;
  }
`;
export const ComponentText = styled(Box)`
  position: relative;
  ${({ number }) => {
    if (!!number) {
      return `&:before {
        content: "${number})";
        position: absolute;
        top: 0;
        left: -24px;
      }`;
    }
  }}
  ${({ required }) => {
    if (required)
      return `&:after {
      content: '*';
      color: red;
      position: absolute;
      left: -8px;
      top: 0;
    }`;
  }}
  & > div {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .simple-popover-component {
      button {
        margin-top: -12px;
      }
    }
  }
  p {
    margin-top: 0;
  }
  @media (max-width: 599px) {
    margin-left: 28px;
    margin-right: 14px;
  }
`;
export const ComponentInput = (props) => (
  <MobileTextField>
    <TextField
      variant="outlined"
      size="small"
      label="Resposta"
      fullWidth
      {...props}
    />
  </MobileTextField>
);

export const ComponentTextArea = (props) => (
  <ComponentInput
    multiline
    rows={4}
    style={{ height: "110px" }}
    {...props}
  />
);

export const ComponentRadioGroup = styled(RadioGroup)``;
export const ComponentRadio = styled(({ value, label, required, ...props }) => (
  <>
    {label && (
      <FormControlLabel
        value={value}
        control={<Radio />}
        label={label}
        disabled={props.disabled}
      />
    )}
    {!label && <Radio value={value} {...props} />}
  </>
))``;
export const ComponentCheckboxGroup = React.forwardRef(({ ...props }, ref) => (
  <FormGroup ref={ref} {...props} />
));
export const ComponentCheckbox = styled(({ label, ...props }) => (
  <>
    {label && (
      <FormControlLabel
        control={<Checkbox {...props} />}
        label={label}
        {...props}
      />
    )}
    {!label && <Checkbox {...props} />}
  </>
))``;
export const MobileOption = styled.div`
  @media (max-width: 599px) {
    background: #f4f4f4;
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 8px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
`;
const MobileTextField = styled.div`
  @media (max-width: 599px) {
    margin-left: 8px;
    margin-right: 8px;
  }
`;
export const MobileScrolling = styled.div`
  @media (max-width: 599px) {
    overflow-x: auto;
    overflow-y: hidden;
  }
`;
export const ComponentGrid = styled.table`
  border-collapse: collapse;
  width: 100%;
`;
export const ComponentGridHeader = styled.tr``;
export const ComponentGridBody = styled.tr`
  background-color: #f8f9fa;
  border-bottom: 4px solid #fff;
`;
export const ComponentGridColumn = styled.td`
  text-align: center;
  padding: 8px;
`;
export const ComponentGridRow = styled.td`
  padding: 8px;
`;
export const ComponentGridOption = styled.td`
  text-align: center;
`;
