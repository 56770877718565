import Cron from "react-cron-generator";
import React from "react";
import {i18nComponent, localeApp} from "@components/CronGenerator/CronTranslate";

const CrunForm = ({cron, cronHandle}) => {

    return <Cron onChange={(value) => cronHandle && cronHandle(value)}
                 value={cron?.expression}
                 showResultText={true}
                 showResultCron={true}
                 translateFn={key => i18nComponent[key] || key}
                 locale={localeApp} />
}

export default CrunForm;