import React from "react";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./contexts/auth";
import AppTheme from "./theme";

import Routes from "./routes";
import {locale} from "devextreme/localization";

function App() {
  locale(navigator.language);

  return (
    <BrowserRouter>
      <AuthProvider>
        <AppTheme>
          <Routes />
        </AppTheme>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
