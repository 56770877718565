import React from "react";
import { Switch, Route } from "react-router-dom";
import SetupRoutes from "./app.routes.setup";
import QuestRoutes from "./app.routes.quest";
import { useAuth } from "../contexts/auth";
import AuthRoutes from "./auth.routes";
import SsoRoutes from "./sso.routes";

export default function AppRoutes() {
  const { signed } = useAuth();

  return (
    <Switch>
      <Route path="/" exact component={signed ? SetupRoutes : AuthRoutes} />
      <Route path="/setup" component={signed ? SetupRoutes : AuthRoutes} />
      <Route path="/quest" component={signed ? QuestRoutes : AuthRoutes} />
      <Route path="/sso" component={SsoRoutes} />
    </Switch>
  );
}
