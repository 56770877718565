import axios from "axios";
import { useAuth } from "../contexts/auth";
import { baseURL } from "./api";

export default function useFetch(props) {
  const { token, tokenType, sessionExpired } = useAuth();

  const api = axios.create({
    baseURL: baseURL,
    validateStatus: function (status) {
      const ret = status >= 200 && status < 300; // default

      //if (!ret) enqueueSnackbar("Não esqueça de tratar os erros! useFetch");

      return ret;
    },
    transformResponse: [
      (data) => {
        let resp;
        try {
          resp = JSON.parse(data);
        } catch (_e) {
          resp = {};
        }

        if (resp?.error === "invalid_token" && !props?.isPublic) {
          sessionExpired();
        } else return resp;
      },
    ],
  });

  if (!props?.isPublic)
    api.defaults.headers.Authorization = `${tokenType} ${token}`;

  return api;
}
