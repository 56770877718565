import styled from "styled-components";
import { Grid } from '@material-ui/core';

export const BreadcrumbsContainer = styled.div`
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  background-color: #fff;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
`;

export const TotalContainer = styled.div`
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  background-color: #fff;
  border-radius: 4px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const TotalItem = styled.div`
  border-bottom: 1px solid #ddd;
  padding: 10px;
`;

export const Panel = styled.div`
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  background-color: #fff;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 15px;
`;

export const Form = styled.form`
  margin-top: 20px;
`;

export const AlignRight = styled.div`
  text-align: right;
`;

export const AlignCenter = styled.div`
  text-align: center;
`;

export const PageCard = styled.div`
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 10px;
`;

export const PageHeader = styled.div`
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 10px 15px;
`;

export const PageContent = styled.div`
  padding: 10px 15px;
  min-height: 150px;
  border: 2px dashed transparent;
  position: relative;
  &:empty:after {
    content: "Arraste e solte um tipo de componente aqui";
    opacity: 0.5;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
  }
`;

export const SurveyDescriptionContainer = styled(Grid)`
  margin-top: 24px;
`;

export const Info = styled.div`
  font-size: 15px;
  color: rgba(0, 0, 0, 0.5);

  p {
    margin: 0;

    &.bold {
      font-weight: 500;
    }
  }
`;

export const ColorPicker = styled(Grid)`
  @media (max-width: 599px) {
    & > p {
      margin-bottom: 4px;
      text-align: center;
    }
  }

  & > div {
    @media (max-width: 599px) {
      margin: 0 auto;
    }
  }
`;

export const FileUploadContainer = styled(Grid)`
  @media (max-width: 599px) {
    margin-top: 24px;

    & > p {
      margin-bottom: 4px;
      text-align: center;
    }
  }
`;

export const FileUpload = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 16px;
  width: fit-content;
  border-radius: 4px;
  text-transform: uppercase;
  line-height: 0;
  cursor: pointer;
  background-color: #3F51B5;
  color: #fff;

  transition: background-color 200ms;

  @media (max-width: 599px) {
    width: 100%;
    justify-content: center;
  }

  &:hover {
    background-color: #303F9F;
  }

  svg {
    margin-right: 8px;
  }

  input {
    display: none;
  }
`;

export const UploadedFile = styled.img`
  display: block;
  width: 200px;
  height: 200px;
  object-fit: contain;
  object-position: center center;

  @media (max-width: 599px) {
    margin: 0 auto;
  }
`;