import React from "react";
import { Icon } from "@material-ui/core";
import { Option, OptionIcon, OptionText, AnswerText } from "./style.js";

export default function OptionsSample({ question }) {
  if (question.questionType.hasOptions) {
    if (["radio", "checkbox"].includes(question.questionType.code)) {
      return question.options.map((option, index) => {
        return (
          <Option key={index}>
            <OptionIcon>
              <Icon>{getIcon(question.questionType)}</Icon>
            </OptionIcon>
            <OptionText>{option.description}</OptionText>
          </Option>
        );
      });
    } else if (
      ["grid_radio", "grid_checkbox"].includes(question.questionType.code)
    ) {
      const columns = question.children[0].options;
      const icon = getIcon(question.questionType);
      const bg = "#f8f9fa";
      return (
        <table style={{ borderCollapse: "collapse", width: "100%" }}>
          <tbody>
            <tr>
              <td></td>
              {columns.map((column, index) => (
                <td key={index} style={{ textAlign: "center", padding: "8px" }}>
                  {column.description}
                </td>
              ))}
            </tr>
            {question.children.map((question, index) => {
              return (
                <tr
                  key={index}
                  style={{ backgroundColor: bg, borderBottom: "4px solid #fff" }}
                >
                  <td style={{ padding: "8px" }}>{question.description}</td>
                  {question.options.map((option, index) => {
                    return (
                      <td key={index} style={{ textAlign: "center" }}>
                        <Icon>{icon}</Icon>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    }
  } else if (question.questionType.code === "text") {
    return <React.Fragment />;
  } else {
    return <AnswerText type={question.questionType.code} />;
  }
}

function getIcon(questionType) {
  if (questionType.code === "radio") return "radio_button_unchecked";
  else if (questionType.code === "grid_radio") return "radio_button_unchecked";
  else if (questionType.code === "checkbox") return "check_box_outline_blank";
  else if (questionType.code === "grid_checkbox")
    return "check_box_outline_blank";
}
