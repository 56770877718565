import React, { useEffect, useState, useCallback } from "react";
import DataGrid, {
  Column,
  Scrolling,
  Sorting,
} from "devextreme-react/data-grid";
import LoadPanel from "devextreme-react/load-panel";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import {
  Breadcrumbs,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Icon,
  makeStyles,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { AddCircle as AddCircleIcon } from "@material-ui/icons";
import Button, { ButtonGroup } from "../../../components/Button";
import { Link, useHistory, useLocation } from "react-router-dom";
import { BreadcrumbsContainer, TotalContainer, TotalItem } from "./style.js";
import useFetch from "../../../services/useFetch";
import { useAuth } from "../../../contexts/auth";
import { useSnackbar } from "notistack";
import { preview, access } from "./Survey";
import Campaign from "./Campaigns";
import SurveyContext from "./SurveyContext";

// Internacionalização
import { i18n } from '../../../translate/i18n';

export default function SurveyList() {
  const api = useFetch();
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const mobile = useMediaQuery("(max-width:599px)");

  const [published, setPublished] = useState(0);
  const [drafts, setDrafts] = useState(0);
  const [data, setData] = useState([]);
  const [loadPanelVisible, setLoadPanelVisible] = useState(true);

  const [surveyIdDelete, setSurveyIdDelete] = useState(null);
  const [excluding, setExcluding] = useState(false);
  const [openCampaignPerId, setOpenCampaignPerId] = useState(null);
  const history = useHistory();
  let location = useLocation();


  const classes = useStyles();

  const [surveyIdPublish, setSurveyIdPublish] = useState(null);

  const getData = useCallback(() => {
    setLoadPanelVisible(true);
    api
      .get(`/u/project/${user.projectId}/survey`)
      .then(({ data: { published, drafts, surveys } }) => {
        setPublished(published);
        setDrafts(drafts);
        setData(surveys);
      })
      .catch(() => {
        enqueueSnackbar(i18n.t("messages.onloadError"), {
          variant: "error",
        });
      })
      .then(() => setLoadPanelVisible(false));
  }, [api, enqueueSnackbar, user.projectId]);

  useEffect(() => {
    getData();
  }, []);

  const handleEdit = useCallback(
    (id) => {
      history.push(`${location.pathname}/${id}`);
    },
    [history, location.pathname]
  );

  const handleConfirmDelete = useCallback((id) => {
    setSurveyIdDelete(id);
  }, []);

  const handleDelete = useCallback(() => {
    setExcluding(true);
    api
      .delete(`/u/project/${user.projectId}/survey/${surveyIdDelete}`)
      .then((response) => {
        enqueueSnackbar(i18n.t("messages.deleteSuccess"), {
          variant: "success",
        });
        setSurveyIdDelete(null);
        getData();
      })
      .catch((error) => {
        enqueueSnackbar(i18n.t("messages.deleteError"), {
          variant: "error",
        });
      })
      .then(() => {
        setExcluding(false);
      });
  }, [api, enqueueSnackbar, getData, surveyIdDelete, user.projectId]);

  const handleVisibility = useCallback((survey) => {
    preview(survey);
  }, []);

  const handleAccess = useCallback((survey) => {
    access(survey);
  }, []);

  const afterPublish = useCallback(() => {
    getData();
  }, [getData]);

  const dateFormat = useCallback((value) => {
    const date = new Date(value);
    return (
      date.getDate() +
      "/" +
      parseInt(date.getMonth() + 1) +
      "/" +
      date.getFullYear()
    );
  }, []);

  const cellSurvey = useCallback(
    ({ data }) => {
      return (
        <div
          onClick={() => {
            if (mobile) {
              handleEdit(data.id);
            }
          }}
        >
          <Typography display="block">{data.name}</Typography>
          <Typography variant="caption">
            {i18n.t("pages.survey.surveyList.created")} {dateFormat(data.createDate)} | {i18n.t("pages.survey.surveyList.modified")} {" "}
            {dateFormat(data.updateDate)}
          </Typography>
        </div>
      );
    },
    [dateFormat, handleEdit, mobile]
  );

  return (
    <>
      <BreadcrumbsContainer>
        <Breadcrumbs className="breadcrumb">
          <Typography color="textPrimary">{i18n.t("pages.survey.title")}</Typography>
        </Breadcrumbs>
      </BreadcrumbsContainer>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={2}>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <TotalContainer>
                <TotalItem>
                  <Typography>{data.length} {i18n.t("pages.survey.title")}</Typography>
                </TotalItem>
              </TotalContainer>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Link
                to={(location) => `${location.pathname}/novo`}
                style={{ textDecoration: "none" }}
              >
                <Button color="primary" startIcon={<AddCircleIcon />} fullWidth>
                {i18n.t("buttons.new")}
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={10}>
          <DataGrid
            dataSource={data}
            allowColumnResizing={true}
            noDataText={i18n.t("messages.noRecordsFound")}
            columnResizingMode="widget"
            style={{
              maxHeight: "calc(100vh - 150px)",
            }}
            wordWrapEnabled={true}
            columnAutoWidth={true}
            showBorders={true}
            showRowLines={true}
            showColumnLines={false}
            keyExpr="id"
          >
            <Scrolling mode="virtual" />
            <Sorting mode="none" />
            <Column dataField={i18n.t("pages.survey.title")} cellRender={cellSurvey} />
            <Column
              visible={!mobile}
              cellRender={({ data }) => (
                <ButtonGroup color="default" size="small">
                  <Button title={i18n.t("pages.survey.surveyList.surveyEdit")}>
                    <Icon fontSize="small" onClick={() => handleEdit(data.id)}>
                      edit
                    </Icon>
                  </Button>
                  <Button disabled style={{ display: "none" }}>
                    <Icon fontSize="small">content_copy</Icon>
                  </Button>
                  <Button title={i18n.t("pages.survey.surveyList.surveyDelete")}>
                    <Icon
                      fontSize="small"
                      onClick={() => handleConfirmDelete(data.id)}
                    >
                      delete
                    </Icon>
                  </Button>
                  
                  {/*
                  data.published ? (
                    <Button title={i18n.t("pages.survey.surveyList.surveyAccess")}>
                      <Icon fontSize="small" onClick={() => handleAccess(data)}>
                        north_east
                      </Icon>
                    </Button>
                  ) : (
                    <Button title={i18n.t("pages.survey.surveyList.surveyView")}>
                      <Icon
                        fontSize="small"
                        onClick={() => handleVisibility(data)}
                      >
                        visibility
                      </Icon>
                    </Button>
                  )*/}
                  
                </ButtonGroup>
              )}
              alignment="right"
            ></Column>
            <Column
              visible={!mobile}
              width={120}
              cellRender={({ data }) => (
                <Button
                  disableElevation
                  size="small"
                  onClick={() => setOpenCampaignPerId(data.id)}
                  fullWidth
                >
                  { i18n.t("buttons.campaigns") }
                </Button>
              )}
            ></Column>
          </DataGrid>
          <LoadPanel
            shadingColor="rgba(0,0,0,0.1)"
            position={{ to: ".dx-datagrid" }}
            onHiding={() => setLoadPanelVisible(false)}
            visible={loadPanelVisible}
            showIndicator={true}
            shading={true}
            showPane={true}
            closeOnOutsideClick={false}
            className={classes.loadPanel}
            message={i18n.t("messages.loading")}
          />
        </Grid>
      </Grid>
      <SurveyContext.Provider
        value={{ surveyIdPublish, setSurveyIdPublish, afterPublish }}
      >
        {
          !!openCampaignPerId &&
          <Campaign surveyId={openCampaignPerId} handleClosed={() => setOpenCampaignPerId(null)}/>
        }
      </SurveyContext.Provider>
      <Dialog open={!!surveyIdDelete} onClose={() => setSurveyIdDelete(null)}>
        <DialogTitle>{i18n.t("messages.confirmation")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
          {i18n.t("messages.deleteConfirm")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDelete} disabled={excluding}>
            {!excluding ? i18n.t("buttons.yes") : i18n.t("messages.excluding")}
          </Button>
          <Button onClick={() => setSurveyIdDelete(null)}>Não</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  loadPanel: {
    zIndex: `${theme.zIndex.drawer} !important`,
  },
}));
