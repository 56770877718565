import React from "react";

import { ComponentCheckboxGroup } from "../style.js";
import CheckboxItem from "./CheckboxItem.jsx";

const Checkbox = ({ question, answer, handleChanged, justSee }) => {

  const handleChangeOption = (option) => {
    handleChanged({
      ...question,
      options: question.options.map(opt => {
        if (opt.index === option.index)
          opt.checked = option.checked;
        return {...opt};
      })
    });
  };

  return (
    <ComponentCheckboxGroup>
      {question.options.map((option, index) => (
        <CheckboxItem
          option={option}
          answer={answer}
          justSee={justSee}
          handleChanged={handleChangeOption}
          key={index}
        />
      ))}
    </ComponentCheckboxGroup>
  );
};

export default Checkbox;
