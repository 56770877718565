import React from "react";

import {ComponentCheckbox, MobileOption} from "../style.js";

const CheckboxItem = ({ option, answer, handleChanged, justSee }) => {
  const getChecked = () => answer.options.find(op => op.index === option.index).checked;

  const handleChange = (event) => {
    handleChanged({index: option.index, checked: event.target.checked});
  };

  return (
    <MobileOption>
      <ComponentCheckbox
        name={option.index}
        label={option.description}
        checked={getChecked()}
        disabled={justSee}
        onChange={handleChange}
      />
    </MobileOption>
  );
};

export default CheckboxItem;