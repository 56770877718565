import { ButtonGroup } from "@components/Button/Button";
import TextField from "@components/TextField/TextField";
import { Grid, Icon, IconButton, Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useState, useEffect, useCallback, useContext } from "react";
import NumberFormat from "react-number-format";
import SurveyContext from "../SurveyContext";
import OptionGridContext from "./OptionGridContext";

// Internacionalização
import { i18n } from '../../../../translate/i18n';

export default function OptionGridLines() {
  const { questionData, setQuestionData } = useContext(SurveyContext);
  const { columnsDefault } = useContext(OptionGridContext);
  const [questions, setQuestions] = useState([]);
  const [flagAdd, setFlagAdd] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setQuestions(questionData.children);
  }, [questionData?.children]);

  useEffect(() => {
    if (!!flagAdd && questions.length > 0) {
      const field = document.getElementById(`question_grid-${flagAdd}`);
      field && field.focus();
      setFlagAdd(null);
    }
  }, [flagAdd, questions]);

  const reorderIndex = useCallback((array) => {
    return array.map((data, index) => ({
      ...data,
      index: index,
    }));
  }, []);

  const handleAdd = useCallback(
    (question) => {
      const index = question.index + 1;
      const newQuestions = questionData.children.slice();
      newQuestions.splice(index, 0, {
        id: "",
        description: "",
        options: question.options.map((data, index) => {
          return {
            ...data,
            id: "",
            description: columnsDefault[index].description,
            score: columnsDefault[index].score,
          };
        }),
      });

      setFlagAdd(index);
      setQuestionData({
        ...questionData,
        children: reorderIndex(newQuestions),
      });
    },
    [columnsDefault, questionData, reorderIndex, setQuestionData]
  );

  const handleDelete = useCallback(
    (question) => {
      if (questionData.children.length === 2) {
        enqueueSnackbar( i18n.t("pages.survey.optionGridLines.twoLinesMinimum") , {
          variant: "warning",
        });
      } else {
        const newQuestion = questionData.children.slice();
        newQuestion.splice(question.index, 1);
        setQuestionData({
          ...questionData,
          children: reorderIndex(newQuestion),
        });
      }
    },
    [enqueueSnackbar, questionData, reorderIndex, setQuestionData]
  );

  const handleChange = useCallback(
    (event, question) => {
      const newQuestions = questions.slice();
      newQuestions.splice(question.index, 1, {
        ...question,
        [event.target.name]: event.target.value,
      });
      setQuestions(newQuestions);
    },
    [questions]
  );

  const handleBlur = useCallback(
    (event, question) => {
      const newQuestions = questionData.children.slice();
      newQuestions.splice(question.index, 1, {
        ...question,
        [event.target.name]: event.target.value,
      });
      setQuestionData({ ...questionData, children: newQuestions });
    },
    [questionData, setQuestionData]
  );

  const handleChangeOption = useCallback(
    (event, question, option) => {
      const newOptions = question.options.slice();
      newOptions.splice(option.index, 1, {
        ...option,
        [event.target.name]: event.target.value,
      });

      const newQuestions = questions.slice();
      newQuestions.splice(question.index, 1, {
        ...question,
        options: newOptions,
      });

      setQuestions(newQuestions);
    },
    [questions]
  );

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6}>
        <Typography
          variant="body1"
          style={{
            borderBottom: "1px solid rgba(0,0,0,.1)",
            marginTop: "15px",
            marginBottom: "10px",
          }}
        >
          { i18n.t("pages.survey.optionGridLines.line") }
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography
          variant="body1"
          style={{
            borderBottom: "1px solid rgba(0,0,0,.1)",
            marginTop: "15px",
            marginBottom: "10px",
          }}
        >
        { i18n.t("pages.survey.optionGridLines.columnPunctuation") }
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {questions.map((question) => {
          return (
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item xs={12} sm={6}>
                <Grid container spacing={1}>
                  <Grid item xs={10}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item>
                        <Typography variant="body1">{`#${
                          question.index + 1
                        }`}</Typography>
                      </Grid>
                      <Grid item xs>
                        <TextField
                          id={`question_grid-${question.index}`}
                          label={ i18n.t("pages.survey.optionGridLines.lineText") }
                          name="description"
                          value={question.description}
                          onBlur={(event) => handleBlur(event, question)}
                          onChange={(event) => handleChange(event, question)}
                          required={true}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={2}>
                    <ButtonGroup size="small">
                      <IconButton
                        onClick={() => handleAdd(question)}
                        title={ i18n.t("pages.survey.optionGridLines.addTextLine") }
                      >
                        <Icon>add</Icon>
                      </IconButton>
                      <IconButton
                        onClick={() => handleDelete(question)}
                        title={ i18n.t("pages.survey.optionGridLines.deleteTextLine") }
                      >
                        <Icon>delete</Icon>
                      </IconButton>
                    </ButtonGroup>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={1}>
                  {question.index === 0 &&
                    question.options &&
                    question.options.map((option) => {
                      return (
                        <Grid item xs>
                          <Typography variant="body1">{`#${
                            option.index + 1
                          }`}</Typography>
                        </Grid>
                      );
                    })}
                </Grid>
                <Grid container spacing={1}>
                  {question.options &&
                    question.options.map(function (option) {
                      return (
                        <Grid item xs>
                          <TextField
                            name="score"
                            value={option.score}
                            onBlur={(event) => handleBlur(event, question)}
                            onChange={(event) =>
                              handleChangeOption(event, this, option)
                            }
                            inputProps={{
                              maxLength: "15",
                              style: { textAlign: "right" },
                            }}
                            InputProps={{
                              inputComponent: NumberFormatScore,
                            }}
                            placeholder="1,00"
                          />
                        </Grid>
                      );
                    }, question)}
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
}

function NumberFormatScore(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString={true}
      decimalSeparator=","
      decimalScale={2}
    />
  );
}
