import React, {useCallback, useContext, useEffect, useState} from "react";
import { useParams } from "react-router-dom";
import {
  Grid,
  Button,
  Typography
} from "@material-ui/core";
import Component from "../Component";
import useFetch from "@services/useFetch";
import { handleIntegration } from "../Completed/Completed";

import { i18n } from '../../../../translate/i18n';

import Loading from "@components/Loading";

import {
  PageContent,
  PageTitle,
  PageComponents,
  PageActions,
} from "./style.js";
import AnswerContext from "../../AnswerContext";

export default function Page({
  survey,
  answers,
  setAnswers,
  pageIndex,
  pageRead,
  justSee,
  submit,
  pagination,
  alreadyAnswered,
  viewResult
}) {
  const isFirst = pageIndex === 0;
  const isLast = pageIndex === survey?.pages?.length - 1;

  const { savingResponses } = useContext(AnswerContext);
  const api = useFetch();
  const { surveyKey, participantKey } = useParams();
  const [resultLoading, setResultLoading] = useState(false);
  const [invalidFields, setInvalidFields] = useState([]);

  const validate = useCallback((question) => {
    if ( !question.required )
      return false;

    const answer = answers.find(a => a.questionId===question.id);
    const errors = {
      "input": !answer?.response,
      "textarea": !answer?.response,
      "radio": !answer?.options?.find(option => option.checked),
      "checkbox": !answer?.options?.find(option => option.checked),
      "grid_radio": answer?.children?.find(children => !children.options.find(option => option.checked)),
      "grid_checkbox": answer?.children?.find(children => !children.options.find(option => option.checked)),
    };
    return errors[question.type.code];
  }, [answers]);

  const validateMoment = (question) => {
    if ( pageIndex === pageRead )
      return false;
    return validate(question);
  }

  const validateFields = useCallback((pageNumber) => {
    const erros = survey.pages
      .filter((page, index) => index < pageNumber)
      .map(page => ({
        pageNumber: page.pageNumber,
        questions : page.questions.filter(quest => validate(quest)).map(quest => quest.number)
      }))
      .filter(page => page.questions.length > 0);
    setInvalidFields(erros);
    return erros.length === 0;
  }, [setInvalidFields, survey, validate]);

  useEffect(() => {
    validateFields(pageRead)
  }, [validateFields, pageIndex, pageRead])

  const changeResponse = (question) => {
    setAnswers( old => old.map(answer => {
      if ( answer.questionId === question.id )
        answer.response = question.response;
      return answer;
    }));
  };

  const changeOption = (question) => {
    const options = question.options.map(({index, checked}) => ({ index, checked }));
    setAnswers( old => old.map(answer => {
      if ( answer.questionId === question.id )
        answer.options = options;
      return answer;
    }));
  };

  const changeChildren = (question) => {
    const children = question.children.map(({id, index, options}) => ({
      id,
      index,
      options: options.map(({index, checked}) => ({ index, checked }))
    }));
    setAnswers( old => old.map(answer => {
      if ( answer.questionId === question.id )
        answer.children = children;
      return answer;
    }));
  };

  const handleQuestion = (question) => {
    switch (question.type.code) {
      case ("input"):
        changeResponse(question);
        break;
      case ("textarea"):
        changeResponse(question);
        break;
      case ("radio"):
        changeOption(question);
        break;
      case ("checkbox"):
        changeOption(question);
        break;
      case ("grid_radio"):
        changeChildren(question);
        break;
      case ("grid_checkbox"):
        changeChildren(question);
        break;
      default: throw new Error("Process not found")
    };
  }

  const handleClickSubmit = useCallback(() => {
    if ( validateFields( ( survey.pages.length = 1 && isLast ) ? survey.pages.length : survey.pages.length -1) )
      submit(true);
    }, [validateFields, survey, submit]
  );

  const alertRequired = () => {
    const errosPage = invalidFields.map(page => page.questions.length);
    if ( errosPage.length === 0 )
      return;

    const size = errosPage.reduce((total, sum) => total+sum);
    const msg = size > 1 ? ["s", "m","foram"] : ["", "", "foi"];

    return size > 0 && (
      <Grid item xs={12} style={{ paddingTop: "10px" }}>
        <Typography variant="caption" style={{ color: "#a80000" }}>
          <strong>
            { `Atenção: ${size} pergunta${msg[0]} obrigatória${msg[0]} não ${msg[2]} respondida${msg[0]}:` }
          </strong>
          {
            invalidFields.map((page, index) => <div key={index}>
              <strong>
                { `Pagina ${page.pageNumber}: ` }
              </strong>
              {
                page.questions.map(number => `Pergunta ${number}`).join(", ")
              }
            </div>)
          }
        </Typography>
      </Grid>
    )
  }

  return (
    <>
      <PageContent>
        <PageTitle>{survey.pages[pageIndex].title}</PageTitle>
        <PageComponents>
          {
            survey.pages[pageIndex].questions.map((question, index) =>
              <Component
                question={question}
                answers={answers}
                handleQuestion={handleQuestion}
                justSee={justSee && survey.surveySent}
                showError={validateMoment(question)}
                key={index}/>
            )
          }
        </PageComponents>
        <PageActions>
          <Grid container spacing={1}>
            {
              !isFirst &&
              <Grid item xs={6} sm={3} md={2}>
                <Button
                  type="button"
                  onClick={() => pagination(-1)}
                  variant="contained"
                  disabled={savingResponses}
                  fullWidth>{i18n.t("buttons.back")}</Button>
              </Grid>
            }
            <Grid item xs={6} sm={4} md={2}>
              <Button
                type="button"
                variant="contained"
                color="primary"
                disabled={ savingResponses || resultLoading || (justSee && survey.surveySent && isLast) || ( isLast && invalidFields.length > 0 ) || !survey.acceptedTerm }
                fullWidth
                title={ i18n.t(alreadyAnswered ? "buttons.viewResult" : "messages.sendAnswer") }
                onClick={ () => isLast ? handleClickSubmit() : pagination(+1)}>
                { i18n.t( isLast ? !savingResponses ? "buttons.send" : "messages.sending" : "buttons.next") }
              </Button>
            </Grid>
            {
              ( (!isLast && !(justSee && survey.surveySent)) || ( isLast && isFirst && !(justSee && survey.surveySent)) ) &&
              <Grid item xs={6} sm={4} md={3}  style={{ marginLeft: "auto" }}>
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  disabled={resultLoading || savingResponses || !survey.acceptedTerm}
                  fullWidth
                  onClick={() => submit(false)}>
                  { i18n.t("buttons.savePartial") }
                </Button>
              </Grid>
            }


            {viewResult && isLast ? (
              <Grid style={{ margin: "0 0 0 auto" }} item xs={6} sm={4} md={3}>
                <Button
                  disabled={resultLoading}
                  onClick={async (e) => {
                    setResultLoading(true);
                    await handleIntegration(e, api, {
                      surveyKey,
                      participantKey,
                    });
                    setResultLoading(false);
                  }}
                  variant="contained"
                  color="primary"
                >
                  {resultLoading ? i18n.t("messages.loading") : i18n.t("buttons.viewResult")}
                </Button>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
          {
            alertRequired()
          }
        </PageActions>
      </PageContent>
      <Loading title="Carregando..." open={resultLoading}>
        O resultado está sendo processado.
        <br />
        Aguarde e em seguida você será redirecionado.
      </Loading>
    </>
  );
}
