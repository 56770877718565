import i18n from "i18next";
import DataGrid, {Column, FilterRow, HeaderFilter, Sorting} from "devextreme-react/data-grid";
import React from "react";
import {i18nComponent, translateCrun} from "@components/CronGenerator/CronTranslate";
import {Radio} from "@material-ui/core";

const CronList = ({values, selected, handleSelected}) => {

    const dataSource = values.map(item => {
        item.description = !!item.expression ? translateCrun(item.expression) : null;
        return item;
    });

    const getCellRadio = ({data}) => {
        return <Radio style={{ padding: 0 }}
                      size="small"
                      onClick={() => handleSelected && handleSelected(data)}
                      checked={data.expression === selected}/>
    };

    return <DataGrid dataSource={dataSource}
                     noDataText={ i18n.t('messages.noRecordsFound') }
                     columnResizingMode="widget"
                     keyExpr="id"
                     wordWrapEnabled={true}
                     columnAutoWidth={true}
                     showBorders={true}
                     showRowLines={true}
                     showColumnLines={true}
                     height={380}>
        <Sorting />

        <FilterRow visible={true} />
        <HeaderFilter visible={true} />

        <Column cellRender={ getCellRadio } alignment="center" width={60} />
        <Column dataField="description"
                dataType="string"
                caption={ i18nComponent.columns.description }/>
        <Column dataField="expression"
                dataType="string"
                caption={ i18nComponent.columns.expression }
                width={140}
                alignment="center"/>
    </DataGrid>
}

export default CronList;