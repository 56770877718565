import React from "react";

import {ComponentInput} from "../style.js";

const Input = ({ question, answer, handleChanged, justSee }) => {
  const getValue = () => answer.response;

  const handleChange = (event) => {
    handleChanged({...question, response: event.target.value});
  };

  return (
    <ComponentInput
      name={question.id}
      value={getValue()}
      disabled={justSee}
      onChange={handleChange}
      onKeyPress={(event) => event.key === "Enter" && event.preventDefault()}
    />
  );
};

export default Input;
