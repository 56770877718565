import React, { useEffect, useState, useCallback } from "react";

// Material ui core
import { Grid, Typography } from "@material-ui/core";

// Material ui style
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

// Material ui icons
import { Delete as DeleteIcon } from "@material-ui/icons";

// API
import useFetch from "../../../services/useFetch";

// Components
import { Button } from "../../../components";
import Drawer from "./Drawer";

// DevExtreme
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import DataGrid, { Column, Selection } from "devextreme-react/data-grid";
import { ButtonGroup } from "devextreme-react";

import { Container, TitleContainer, Title } from "../../style";
import { useAuth } from "../../../contexts/auth";

const theme = createTheme({
  overrides: {
    MuiDrawer: {
      paper: {
        height: "calc(100% - 64px)",
        top: "initial",
        bottom: "0",
      },
    },
  },
});

export default function Client() {
  const { user } = useAuth(); // eslint-disable-line
  const api = useFetch();
  const [data, setData] = useState([]);
  const [selectedItemKeys, setSelectedItemKeys] = useState([]);

  const getAll = useCallback(async () => {
    const { data } = await api.get(`/u/client/${user.projectId}`);
    setData(data);
  }, [api, user.projectId]);

  useEffect(() => {
    getAll();
  }, []); // eslint-disable-line

  function handleNovo() {
    api
      .post(`/u/client/${user.projectId}`, {
        cnpj: this.cnpj,
        status: this.status,
        fantasyName: this.fantasyName,
        socialReason: this.socialReason,
      })
      .then((response) => getAll())
      .catch((error) => console.log(error));
  }

  function actionButtons(data) {
    const actionButtons = [
      {
        icon: "edit",
        type: "edit",
        hint: "Alterar",
      },
      {
        icon: "trash",
        type: "trash",
        hint: "Excluir",
      },
      {
        icon: "activefolder",
        type: "view",
        hint: "Visualizar",
      },
    ];

    return (
      <ButtonGroup
        items={actionButtons}
        keyExpr="type"
        onItemClick={(e) => console.log(e)}
        stylingMode="contained"
      />
    );
  }

  function deleteRecords() {
    selectedItemKeys.forEach((key) => {
      console.log(`Apagando ${key}`);
      api
        .delete(`/u/category/${user.projectId}/${key}`)
        .then((_response) => getAll())
        .catch((error) => console.log(error));
    });
    setSelectedItemKeys([]);
  }

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <Title>
          <TitleContainer>
            <Typography color="textPrimary">Cliente</Typography>
          </TitleContainer>
          <div className="buttons">
            <Drawer handleNovo={handleNovo} />
            <Button
              disabled={!selectedItemKeys.length}
              onClick={deleteRecords}
              startIcon={<DeleteIcon />}
              color="secondary"
            >
              Excluir
            </Button>
          </div>
        </Title>
        <Grid item xs={12} sm={12}>
          <DataGrid
            dataSource={data}
            allowColumnResizing={true}
            noDataText="Nenhum registro encontrado"
            columnResizingMode="widget"
            wordWrapEnabled={true}
            onSelectedRowKeysChange={(selected) =>
              setSelectedItemKeys(selected)
            }
            keyExpr="id"
            selectedRowKeys={selectedItemKeys}
            columnAutoWidth={true}
            showBorders={true}
            showRowLines={true}
            showColumnLines={false}
          >
            <Selection mode="multiple" />
            <Column dataField="id" caption="Id" />
            <Column dataField="fantasyName" caption="Nome fantasia" />
            <Column dataField="cnpj" caption="CNPJ" />
            <Column dataField="status" caption="Status" />
            <Column cellRender={actionButtons} width={280} alignment="right" />
          </DataGrid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
}
