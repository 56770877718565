import React, { useEffect, useState, useCallback } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";

// Internacionalização
import { i18n } from '../../../translate/i18n';

//DevExtreme
import { LoadPanel } from "devextreme-react/load-panel";
import { 
  TreeList,
  Column,
  Paging,
  Pager,
  Toolbar,
  Item} from 'devextreme-react/tree-list';
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import { Button as ButtonDE } from 'devextreme-react/button';

// Material ui core
import {
  Icon,
  Grid,
  Dialog,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";

// Material ui style
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

// Material ui icons
import { 
//  Delete as DeleteIcon,
  AddCircle as AddCircleIcon 
} from "@material-ui/icons";

// API
import useFetch from "@services/useFetch";

// Components
import Button, { ButtonGroup } from "@components/Button";
import Drawer from "./Drawer";

import { Container, TitleContainer, Title } from "../../style";
import { useAuth } from "@contexts/auth";

const theme = createTheme({
  overrides: {
    MuiDrawer: {
      paper: {
        height: "calc(100% - 64px)",
        top: "initial",
        bottom: "0",
      },
    },
  },
});

export default function ParticipantGroup() {
  const { user } = useAuth();
  const api = useFetch();
  const history = useHistory();
  let location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const { id } = useParams();

  const [data, setData] = useState([]);
  const [excluding, setExcluding] = useState(false);
  const [clusterIdDelete, setClusterIdDelete] = useState(null);
  const [selectedItemKeys, setSelectedItemKeys] = useState([]);
  const [selectedDeleteConfirm, setSelectedDeleteConfirm] = useState(false);

  const [loadPanelVisible, setLoadPanelVisible] = useState(false);  
  
  const [expanded, setExpanded] = useState(false);  
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
 
  const onOptionChanged = useCallback((e) => {
    if(e.name === 'expandedRowKeys') {
      setExpandedRowKeys(e.value);
    }
  }, []);

  const getAll = useCallback(async () => {
    setLoadPanelVisible(true);
    await api
      .get(`/u/project/${user.projectId}/cluster`)
      .then(({ data }) => {
        setData(data);
      })
      .catch(() => {
        enqueueSnackbar( i18n.t('messages.onloadError'), {
          variant: "error",
        });
      });
    setLoadPanelVisible(false);
  }, [api, enqueueSnackbar, user.projectId]);

  useEffect(() => {
    getAll();
  }, []); // eslint-disable-line

  async function handleSubmit() {
    const url = `/u/project/${user.projectId}/cluster/${this.clusterId || ""}`;

    api[this.clusterId ? "put" : "post"](url, {
      name: this.name
    });
  }

  const handleEdit = useCallback(
    (id) => {
      history.push(`${location.pathname}/${id}`);
    },
    [history, location.pathname]
  );

  const handleConfirmDelete = useCallback((id) => {
    setClusterIdDelete(id);
  }, []);

//  const handleConfirmSelectedDelete = useCallback(() => {
//    setSelectedDeleteConfirm(true);
//  }, []);

  const actionButtons = useCallback(
    ({ data }) => {
      return (
        <ButtonGroup color="default" size="small">
          <Button>
            <Icon fontSize="small" onClick={() => handleEdit(data.id)}>
              edit
            </Icon>
          </Button>
          <Button>
            <Icon fontSize="small" onClick={() => handleConfirmDelete(data.id)}>
              delete
            </Icon>
          </Button>
        </ButtonGroup>
      );
    },
    [handleConfirmDelete, handleEdit]
  );

  const handleDelete = useCallback(async () => {
    setExcluding(true);
    api
      .delete(`/u/project/${user.projectId}/cluster/${clusterIdDelete}`)
      .then((_response) => {
        enqueueSnackbar( i18n.t('messages.deleteSuccess'), {
          variant: "success",
        });
        setClusterIdDelete(null);
        getAll();
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          enqueueSnackbar( i18n.t('messages.deleteErrorInUse'), {
            variant: "error",
          });
        } else {
          enqueueSnackbar( i18n.t('messages.deleteErrorInUse'), {
            variant: "error",
          });
        }
      })
      .then(() => {
        setExcluding(false);
      });
  }, [api, user.projectId, clusterIdDelete, enqueueSnackbar, getAll]);

  const handleDeleteSelected = useCallback(async () => {
    setExcluding(true);
    selectedItemKeys.forEach((clusterId) => {
      api
        .delete(`/u/project/${user.projectId}/cluster/${clusterId}`)
        .then((_response) => {
          enqueueSnackbar( i18n.t('messages.deleteSuccess'), {
            variant: "success",
          });
          setClusterIdDelete(null);
          getAll();
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            enqueueSnackbar( i18n.t('messages.deleteErrorInUse'), {
                variant: "error",
              }
            );
          } else {
            enqueueSnackbar( i18n.t('messages.deleteErrorInUse'), {
              variant: "error",
            });
          }
        });
    });
    setExcluding(false);
    setSelectedItemKeys([]);
    setSelectedDeleteConfirm(false);
  }, [api, enqueueSnackbar, selectedItemKeys, user.projectId]); // eslint-disable-line

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <Title>
          <TitleContainer>
            <Typography color="textPrimary">
            { i18n.t('pages.participantgroup.title') }
            </Typography>
          </TitleContainer>
          <div className="buttons">
            <Drawer id={id} handleSubmit={handleSubmit} />
            <Button
              // onClick={handleConfirmSelectedDelete}
              startIcon={<AddCircleIcon />}
            >
              { i18n.t('buttons.import') }
            </Button>
          </div>
        </Title>
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          position={{ to: ".dx-datagrid" }}
          onHiding={() => setLoadPanelVisible(false)}
          visible={loadPanelVisible}
          showIndicator={true}
          shading={true}
          showPane={true}
          closeOnOutsideClick={false}
        />
        <Grid item xs={12} sm={12} style={{"margin-top": "10px"}}>
          <TreeList
          id="cluster"
          dataSource={data}
          autoExpandAll={expanded}
          expandedRowKeys={expandedRowKeys}          
          onOptionChanged={onOptionChanged}
          showRowLines={true}
          showBorders={true}
          keyExpr="id"
          parentIdExpr="parentid"
        >
          <Toolbar>
            <Item location="after">
              <ButtonDE
                text = {expanded ? i18n.t('buttons.hideAll') : i18n.t('buttons.showAll')}
                onClick={() => {
                  setExpanded(prevExpanded => !prevExpanded)
                  setExpandedRowKeys([]);
                }}
              />
            </Item>
          </Toolbar>
          <Paging
            enabled={true}
            defaultPageSize={10} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[10, 20]}
            showInfo={true} /> 
          <Column dataField="name"  caption="Nome" />
          <Column dataField="id" caption="ID" width={100}/>
          <Column cellRender={actionButtons} width={150} alignment="right" />
        </TreeList>
        </Grid>
      </Container>

      <Dialog
        open={selectedDeleteConfirm}
        onClose={() => setSelectedDeleteConfirm(false)}
      >
        <DialogTitle>{ i18n.t('messages.confirmation') }</DialogTitle>
        <DialogContent>
          <DialogContentText>
            { i18n.t('messages.deleteConfirm') }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteSelected} disabled={excluding}>
            {!excluding ? i18n.t('buttons.yes') : i18n.t('messages.excluding')}
          </Button>
          <Button onClick={() => setSelectedDeleteConfirm(false)}>{i18n.t('buttons.no')}</Button>
        </DialogActions>
      </Dialog>

      <Dialog 
        open={!!clusterIdDelete} 
        onClose={() => setClusterIdDelete(null)}
      >
        <DialogTitle>{ i18n.t('messages.confirmation') }</DialogTitle>
        <DialogContent>
          <DialogContentText>
            { i18n.t('messages.deleteConfirm') }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDelete} disabled={excluding}>
            {!excluding ? i18n.t('buttons.yes') : i18n.t('messages.excluding')}
          </Button>
          <Button onClick={() => setClusterIdDelete(null)}>{i18n.t('buttons.no')}</Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}
