import React, { useEffect, useState, useCallback } from "react"; // eslint-disable-line

// DevExtreme
import DataGrid, { Column, Selection } from "devextreme-react/data-grid";
import { ButtonGroup } from "devextreme-react";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";

// Material ui core
import { Grid, Typography } from "@material-ui/core";

// Material ui style
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

// Material ui icons
import { Delete as DeleteIcon } from "@material-ui/icons";

// API
import useFetch from "../../../services/useFetch";

// Components
import { Button } from "../../../components";
import NewProject from "./Drawer";
import ShowUser from "./ShowUser";

import { Container, TitleContainer, Title } from "../../style";
import { useAuth } from "../../../contexts/auth";

const theme = createTheme({
  overrides: {
    MuiDrawer: {
      paper: {
        height: "calc(100% - 64px)",
        top: "initial",
        bottom: "0",
      },
    },
  },
});

export default function Project() {
  const { user } = useAuth(); // eslint-disable-line
  const api = useFetch();
  const [data, setData] = useState([]);
  const [selectedItemKeys, setSelectedItemKeys] = useState([]);

  // const getAll = useCallback(async () => {
  //   const { data } = await api.get("/u/project");
  //   setData(data, {
  //     id: 0,
  //     description: "description",
  //     name: "name",
  //   });
  // }, [api]);

  async function getAll() {
    // const { data } = await api.get("/u/term");
    setData([
      {
        id: 1,
        name: "Nome 1",
        description: "descrição 1",
      },
      {
        id: 2,
        name: "Nome 2",
        description: "descrição 2",
      },
      {
        id: 3,
        name: "Nome 3",
        description: "descrição 3",
      },
    ]);
  }

  useEffect(() => {
    getAll();
  }, []); // eslint-disable-line

  function handleNovo() {
    api
      .post(`/u/project/${user.projectId}`, {
        nome: this.nome,
        descricao: this.descricao,
      })
      .then((response) => getAll())
      .catch((error) => console.log(error));
  }

  function actionButtons(data) {
    const actionButtons = [
      {
        icon: "edit",
        type: "edit",
        hint: "Alterar",
      },
      {
        icon: "trash",
        type: "trash",
        hint: "Excluir",
      },
      {
        icon: "activefolder",
        type: "view",
        hint: "Visualizar",
      },
    ];

    return (
      <ButtonGroup
        items={actionButtons}
        keyExpr="type"
        onItemClick={(e) => {
          switch (e.itemIndex) {
            case 0:
              console.log("Alterar");
              break;
            case 1:
              console.log("Excluir");
              break;
            case 2:
              console.log("Visualizar");
              break;

            default:
              break;
          }
        }}
        stylingMode="contained"
      />
    );
  }

  function deleteRecords() {
    selectedItemKeys.forEach((key) => {
      console.log(`Apagando ${key}`);
      api
        .delete(`/u/category/${user.projectId}/${key}`)
        .then((_response) => getAll())
        .catch((error) => console.log(error));
    });
    setSelectedItemKeys([]);
  }

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <Title>
          <TitleContainer>
            <Typography color="textPrimary">Projeto</Typography>
          </TitleContainer>
          <div className="buttons">
            <NewProject handleNovo={handleNovo} />
            <Button
              disabled={!selectedItemKeys.length}
              onClick={deleteRecords}
              startIcon={<DeleteIcon />}
              color="secondary"
            >
              Excluir
            </Button>
          </div>
        </Title>
        <Grid item xs={12} sm={12}>
          <DataGrid
            dataSource={data}
            allowColumnResizing={true}
            noDataText="Nenhum registro encontrado"
            columnResizingMode="widget"
            onSelectedRowKeysChange={(selected) =>
              setSelectedItemKeys(selected)
            }
            keyExpr="id"
            selectedRowKeys={selectedItemKeys}
            wordWrapEnabled={true}
            columnAutoWidth={true}
            showBorders={true}
            showRowLines={true}
            showColumnLines={false}
          >
            <Selection mode="multiple" />
            <Column dataField="id" caption="Id" />
            <Column dataField="name" caption="Nome" />
            <Column dataField="description" caption="Descrição" />
            <Column
              cellRender={() => <ShowUser handleNovo={handleNovo} />}
              caption="Usuarios"
            />
            <Column cellRender={actionButtons} width={280} alignment="right" />
          </DataGrid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
}
