import React, {
  memo,
  useEffect,
  useCallback,
  useContext,
  useState,
} from "react";
import { Grid, Typography } from "@material-ui/core";
import Option from "./Option";
import SurveyContext from "../SurveyContext";
import { useSnackbar } from "notistack";

// Internacionalização
import { i18n } from '../../../../translate/i18n';

function OptionList() {
  const { questionData, setQuestionData } = useContext(SurveyContext);
  const { enqueueSnackbar } = useSnackbar();
  const [flagAddOption, setFlagAddOption] = useState(null);

  useEffect(() => {
    if (!!flagAddOption && questionData) {
      document.getElementById(`question-option-${flagAddOption}`).focus();
      setFlagAddOption(null);
    }
  }, [flagAddOption, questionData]);

  const reorderIndex = useCallback(
    (options) => {
      const newOptions = options.map((option, index) => ({
        ...option,
        index: index,
      }));
      setQuestionData({ ...questionData, options: newOptions });
    },
    [questionData, setQuestionData]
  );

  const addOption = useCallback(
    (option) => {
      const newOptions = questionData.options;
      const index = option.index + 1;
      newOptions.splice(index, 0, {
        questionId: questionData.id,
        description: "",
        score: "",
      });

      setFlagAddOption(index);
      reorderIndex(newOptions);
    },
    [questionData.id, questionData.options, reorderIndex]
  );

  const deleteOption = useCallback(
    (option) => {
      if (questionData.options.length === 2) {
        enqueueSnackbar( i18n.t("pages.survey.optionList.twoOptionsMinimum") , {
          variant: "warning",
        });
      } else {
        const newOptions = questionData.options;
        newOptions.splice(option.index, 1);

        reorderIndex(newOptions);
      }
    },
    [enqueueSnackbar, questionData?.options, reorderIndex]
  );

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          style={{
            borderBottom: "1px solid rgba(0,0,0,.1)",
            marginBottom: "10px",
          }}
        >
          { i18n.t("pages.survey.optionList.answerOption") }
        </Typography>
      </Grid>
      {questionData?.options?.map((option, index) => {
        return (
          <Grid item xs={12} key={index}>
            <Option
              data={{ ...option, index }}
              addOption={addOption}
              deleteOption={deleteOption}
            />
          </Grid>
        );
      })}
    </Grid>
  );
}

export default memo(OptionList);
