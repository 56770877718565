import React, { useState, forwardRef } from "react";

import { Button, TextField } from "../../../components";

import { Form } from "../../style";

import {
  Grid,
  Slide,
  Dialog,
  AppBar,
  Toolbar,
  Checkbox,
  IconButton,
  Typography,
  FormControlLabel,
} from "@material-ui/core";

import {
  Close as CloseIcon,
  Cancel as CancelIcon,
  Backspace as BackspaceIcon,
  AddCircle as AddCircleIcon,
} from "@material-ui/icons";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  dialog: {
    top: "64px !important",
    bottom: "0",
    //left: "70px !important",
    right: "0",
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function FullScreenDialog({ handleNovo }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [nome, setNome] = useState("");
  const [account, setAccount] = useState("");
  const [password, setPassword] = useState("");
  const [limitSession, setLimitSession] = useState(false);
  const [masterPassword, setMasterPassword] = useState("");
  const [accountNonExpired, setAccountNonExpired] = useState(false);
  const [accountNonBlocked, setAccountNonBlocked] = useState(false);
  const [credentialsNonExpired, setCredentialsNonExpired] = useState(false);

  function clean() {
    setNome("");
    setAccount("");
    setPassword("");
    setMasterPassword("");
    setLimitSession(false);
    setAccountNonExpired(false);
    setAccountNonBlocked(false);
    setCredentialsNonExpired(false);
  }

  return (
    <div>
      <Button startIcon={<AddCircleIcon />} onClick={() => setOpen(true)}>
        Novo
      </Button>
      <Dialog
        fullScreen
        className={classes.dialog}
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setOpen(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Novo Usuario
            </Typography>
            <Button
              onClick={() => {
                handleNovo.bind({
                  nome,
                  account,
                  password,
                  limitSession,
                  masterPassword,
                  accountNonExpired,
                  accountNonBlocked,
                  credentialsNonExpired,
                })();
                setOpen(false);
              }}
            >
              Salvar
            </Button>
          </Toolbar>
        </AppBar>
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <TextField
                onChange={(e) => setNome(e.target.value)}
                value={nome}
                label="Nome"
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                onChange={(e) => setAccount(e.target.value)}
                value={account}
                label="Conta"
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <TextField
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                label="Senha"
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <TextField
                type="password"
                onChange={(e) => setMasterPassword(e.target.value)}
                value={masterPassword}
                label="Senha master"
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={accountNonExpired}
                    color="primary"
                    onChange={(e) => setAccountNonExpired(e.target.checked)}
                  />
                }
                label="Conta nunca expira"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={accountNonBlocked}
                    color="primary"
                    onChange={(e) => setAccountNonBlocked(e.target.checked)}
                  />
                }
                label="Conta não está bloqueada"
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={credentialsNonExpired}
                    color="primary"
                    onChange={(e) => setCredentialsNonExpired(e.target.checked)}
                  />
                }
                label="Credenciais não expiram"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={limitSession}
                    color="primary"
                    onChange={(e) => setLimitSession(e.target.checked)}
                  />
                }
                label="Limite de sessões"
              />
            </Grid>
          </Grid>
          <div className="buttons-container">
            <div className="buttons">
              <Button
                color="secondary"
                onClick={() => setOpen(false)}
                startIcon={<CancelIcon />}
              >
                Cancelar
              </Button>
              <Button onClick={clean} startIcon={<BackspaceIcon />}>
                Limpar
              </Button>
            </div>
          </div>
        </Form>
      </Dialog>
    </div>
  );
}
