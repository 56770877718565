import React, {useEffect, useState} from "react";
import i18n from "i18next";

import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import Button from "@components/Button";
import CronList from "@components/CronGenerator/CronList";
import {AddAlarm} from "@material-ui/icons";
import CrunInput from "@components/CronGenerator/CrunInput";
import {translateCrun} from "@components/CronGenerator/CronTranslate";
import CronForm from "@components/CronGenerator/CronForm";
import {LoadPanel} from "devextreme-react";

const CrunGenerator = ({label, value, values, valueHandled, disabled, required, error}) => {
    const [open, setOpen] = useState(false);
    const [cronList, setCronList] = useState(false);
    const [cronForm, setCronForm] = useState(false);
    const [selected, setSelected] = useState(value);
    const [cronValues, setCronValues] = useState([]);

    useEffect(() => {
        setCronValues(values);
    }, [values]);

    const openHandle = () => {
        setSelected(value);
        setOpen(true);
        setCronList(true);
    }

    const newHandle = () => {
        setCronList(old => !old);
        setCronForm(old => !old);
    }

    const cancelHandle = () => {
        const isList = cronList;
        setCronForm(false);
        setCronList(!isList);
        setOpen(!isList);
    }

    const addHandle = () => {
        let exist = cronValues.find(({expression}) => expression === selected);
        if ( cronForm ) {
            if ( !exist ) {
                exist = {
                    id: -cronValues.length,
                    expression: selected,
                    description: translateCrun(selected)
                };
                setCronValues(old => [ ...old, exist]);
            }
        } else {
            valueHandled && valueHandled({
                ...exist,
                description: translateCrun(exist.expression, "pt_BR")
            })
        }
        setSelected(exist.expression);
        cancelHandle();
    }

    return <>
        <CrunInput label={label}
                   value={value}
                   handleOpen={openHandle}
                   disabled={disabled}
                   required={required}
                   error={error} />

        <Dialog open={open} maxWidth="xl" onClose={cancelHandle}>
            <DialogTitle style={{textAlign: "center"}}>{ label }</DialogTitle>
            <DialogContent style={{height: "400px", width: "700px"}}>
                {
                    !cronForm && !cronList && <LoadPanel visible={true}
                                                         shadingColor="rgba(0,0,0,0.4)"
                                                         message={i18n.t("messages.loading")}
                                                         position={{of: ".MuiDialog-container"}} />
                }
                {
                    cronList &&
                    <CronList values={cronValues}
                              selected={selected}
                              handleSelected={(data) => setSelected(data.expression)} />
                }
                {
                    cronForm &&
                    <CronForm cron={selected} cronHandle={setSelected} />
                }
            </DialogContent>

            { ( cronForm || cronList ) &&
                <DialogActions>
                    { cronList && <Button variant="outlined"
                                          color="primary"
                                          endIcon={<AddAlarm />}
                                          onClick={newHandle}>
                        {i18n.t("buttons.new")}
                    </Button> }

                    <Button color="inherit" onClick={cancelHandle}>{i18n.t("buttons.cancel")}</Button>

                    { cronForm && <Button onClick={addHandle}>{i18n.t("buttons.add")}</Button> }

                    {
                        cronList && <Button disabled={!selected} onClick={addHandle}>
                            {i18n.t("buttons.select")}
                        </Button>
                    }
                </DialogActions>
            }
        </Dialog>
    </>
}

export default CrunGenerator;