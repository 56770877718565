import React, { useContext } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { Button } from "../../../../components";
import AnswerContext from "../../AnswerContext";

export default function Term({ open, close }) {
  const { survey } = useContext(AnswerContext);

  return (
    <Dialog
      open={open}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">Termo</DialogTitle>
      <DialogContent dividers={true}>
        <DialogContentText
          id="scroll-dialog-description"
          tabIndex={-1}
          dangerouslySetInnerHTML={{ __html: survey.termDescription }}
        ></DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary">
          Li e aceito
        </Button>
      </DialogActions>
    </Dialog>
  );
}
