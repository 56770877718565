import React, { useState, useCallback, useEffect, forwardRef } from "react";

import { Button, TextField } from "../../../components";

import { Form } from "./style";

// DevExtreme
import DataGrid, { Column, FilterRow } from "devextreme-react/data-grid";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";

import {
  Grid,
  Slide,
  Select,
  Dialog,
  AppBar,
  Toolbar,
  Checkbox,
  MenuItem,
  InputLabel,
  IconButton,
  Typography,
  FormControl,
  FormControlLabel,
} from "@material-ui/core";

import { Autocomplete } from "@material-ui/lab";

import {
  Close as CloseIcon,
  Cancel as CancelIcon,
  AddCircle as AddCircleIcon,
  AccountCircle as AccountCircleIcon,
} from "@material-ui/icons";

import { makeStyles } from "@material-ui/core/styles";

// API
import useFetch from "../../../services/useFetch";
import { useAuth } from "../../../contexts/auth";

const useStyles = makeStyles((theme) => ({
  dialog: {
    top: "64px !important",
    bottom: "0",
    //left: "70px !important",
    right: "0",
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  formControl: {
    // margin: 12,
    minWidth: 350,
  },
}));

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function FullScreenDialog({ handleNovo }) {
  const { user } = useAuth();
  const api = useFetch();
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [name, setName] = useState("");
  const [profile, setProfile] = useState(0);
  const [defaultProject, setDefaultProject] = useState(false);

  const getClients = useCallback(async () => {
    const { data } = await api.get(`/u/user/${user.projectId}`);
    setUsers(data);
  }, [api, user.projectId]);

  const getAllClients = useCallback(async () => {
    const { data } = await api.get(`/u/user/${user.projectId}`);
    setAllUsers(data);
  }, [api, user.projectId]);

  useEffect(() => {
    getClients();
    getAllClients();
  }, [getAllClients, getClients]);

  return (
    <div>
      <IconButton onClick={() => setOpen(true)}>
        <AccountCircleIcon color="primary" />
      </IconButton>
      <Dialog
        fullScreen
        className={classes.dialog}
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setOpen(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Novo Projeto
            </Typography>
            <Button
              onClick={() => {
                // handleNovo.bind();
                setOpen(false);
              }}
            >
              Salvar
            </Button>
          </Toolbar>
        </AppBar>
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <DataGrid
                dataSource={users}
                allowColumnResizing={true}
                noDataText="Nenhum registro encontrado"
                columnResizingMode="widget"
                wordWrapEnabled={true}
                columnAutoWidth={true}
                showBorders={true}
                showRowLines={true}
                showColumnLines={false}
                keyExpr="id"
              >
                <FilterRow visible={true} applyFilter="onClick" />
                <Column dataField="name" caption="Nome" />
                <Column dataField="profile" caption="Perfil" />
                <Column dataField="default" caption="Padrão" />
              </DataGrid>
            </Grid>
          </Grid>
          <div className="spacing" />
          <Grid container>
            <Grid style={{ paddingRight: "10px" }} item xs={8} sm={8}>
              <Autocomplete
                options={allUsers}
                className="autocomplete"
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    label="Nome"
                  />
                )}
              />
            </Grid>
            <FormControl className={classes.formControl} variant="outlined">
              <InputLabel id="select-label">Perfil</InputLabel>
              <Select
                value={profile}
                onChange={(e) => setProfile(e.target.value)}
                labelId="select-label"
                variant="outlined"
                label="Perfil"
              >
                <MenuItem value={0} disabled>
                  Selecione o perfil
                </MenuItem>
                <MenuItem value={1}>Master</MenuItem>
                <MenuItem value={2}>Administrador</MenuItem>
                <MenuItem value={3}>Explorador</MenuItem>
              </Select>
            </FormControl>
            <Grid
              style={{
                marginLeft: 15,
              }}
              item
            >
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={defaultProject}
                    onChange={(e) => setDefaultProject(e.target.checked)}
                  />
                }
                label="Padrão"
              />
              <IconButton color="primary">
                <AddCircleIcon />
              </IconButton>
            </Grid>
          </Grid>
          <div className="buttons-container">
            <div className="buttons">
              <Button
                onClick={() => setOpen(false)}
                color="secondary"
                startIcon={<CancelIcon />}
              >
                Cancelar
              </Button>
            </div>
          </div>
        </Form>
      </Dialog>
    </div>
  );
}
