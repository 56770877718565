import styled from "styled-components";

export const Container = styled.div`
  width: 100vw;
  height: calc(100vh - 78px);

  padding-top: 74px;
`;

export const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: 0;
`;
