import React, { useContext, useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import SurveyContext from "../SurveyContext";
import OptionGridColumns from "./OptionGridColumns";
import OptionGridLines from "./OptionGridLines";
import OptionGridContext from "./OptionGridContext";

// Internacionalização
import { i18n } from '../../../../translate/i18n';

export default function OptionGrid() {
  const { questionData } = useContext(SurveyContext);
  const [columnsDefault, setColumnsDefault] = useState([]);
  const [flagAdd, setFlagAdd] = useState(null);

  useEffect(() => {
    if (!!flagAdd && questionData) {
      document
        .getElementById(`question-${flagAdd.type}-${flagAdd.index}`)
        .focus();
      setFlagAdd(null);
    }
  }, [flagAdd, questionData]);

  return (
    <OptionGridContext.Provider value={{ columnsDefault, setColumnsDefault }}>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            style={{
              borderBottom: "1px solid rgba(0,0,0,.1)",
              marginBottom: "10px",
            }}
          >
            { i18n.t("pages.survey.optionGrid.answerOption") }
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <OptionGridColumns />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <OptionGridLines />
          </Grid>
        </Grid>
      </Grid>
    </OptionGridContext.Provider>
  );
}
