import React, { memo, useState } from "react";
import {
  Hidden,
  List,
  ListItem,
  ListItemText,
  Paper,
} from "@material-ui/core";
import MatrizCheckboxIcon from "../../../../img/matriz_check_box.png";
import MatrizRadioIcon from "../../../../img/matriz_radio_button.png";

// Internacionalização
import { i18n } from '../../../../translate/i18n';

import QuestionType from "./QuestionType";

const QuestionTypeList = React.forwardRef((props, ref) => {

  const [questionTypes] = useState([
    {
      id: 1,
      name: i18n.t("pages.survey.questionTypeList.singleChoice"),
      description: i18n.t("pages.survey.questionTypeList.singleChoice"),
      icon: "radio_button_checked",
      code: "radio",
      hasOptions: true,
    },
    {
      id: 2,
      name: i18n.t("pages.survey.questionTypeList.multipleChoice"),
      description: i18n.t("pages.survey.questionTypeList.multipleChoice"),
      icon: "check_box",
      code: "checkbox",
      hasOptions: true,
    },
    {
      id: 3,
      name: i18n.t("pages.survey.questionTypeList.shortAnswer"),
      description: i18n.t("pages.survey.questionTypeList.shortAnswer"),
      icon: "input",
      code: "input",
      hasOptions: false,
    },
    {
      id: 4,
      name: i18n.t("pages.survey.questionTypeList.longAnswer"),
      description: i18n.t("pages.survey.questionTypeList.longAnswer"),
      icon: "system_update_alt",
      code: "textarea",
      hasOptions: false,
    },
    {
      id: 5,
      name: i18n.t("pages.survey.questionTypeList.label"),
      description: i18n.t("pages.survey.questionTypeList.label"),
      icon: "text_fields",
      code: "text",
      hasOptions: false,
    },
    {
      id: 6,
      name: i18n.t("pages.survey.questionTypeList.singleChoiceMatrix"),
      description: i18n.t("pages.survey.questionTypeList.singleChoiceMatrix"),
      icon: <img src={MatrizRadioIcon} alt="" />,
      code: "grid_radio",
      hasOptions: true,
    },
    {
      id: 7,
      name: i18n.t("pages.survey.questionTypeList.multipleChoiceMatrix"),
      description: i18n.t("pages.survey.questionTypeList.multipleChoiceMatrix"),
      icon: <img src={MatrizCheckboxIcon} alt="" />,
      code: "grid_checkbox",
      hasOptions: true,
    },
  ]);

  return (
    <Paper ref={ref} className="QuestionType-Paper">
      <Hidden xsDown>
        <List className="QuestionType-List">
          <ListItem>
            <ListItemText primary={i18n.t("pages.survey.questionTypeList.components")} />
          </ListItem>
        </List>
      </Hidden>
      {questionTypes.map((item, index) => (
        <QuestionType item={item} key={index} />
      ))}
    </Paper>
  );
});

export default memo(QuestionTypeList);
