import styled, { createGlobalStyle } from "styled-components";
import { Box, Container as ContainerUI } from "@material-ui/core";

export const GlobalStyle = createGlobalStyle`
.invalid-question {
  background-color: #ffe9e9;
  position: relative;
}
.invalid-question:after {
  content: "Esta pergunta é obrigatória.";
  color: #a80000;
  position: absolute;
  bottom: 10px;
  left: 36px;
}
.invalid-question[qtype=grid_radio]:after,
.invalid-question[qtype=grid_checkbox]:after {
  content: "Esta pergunta exige uma resposta por linha.";
}
.invalid-question .grid_child {
  background-color: #ffdddd;
  border-bottom-color: #ffe9e9;
}
`;
export const Container = styled(ContainerUI)`
  background-color: #fff;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  @media (min-width: 960px) {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  @media (max-width: 599px) {
    width: 100%;
  }
`;
const paddingHeight = 48;
export const Header = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: ${({ background }) => background};
  color: #fff;
  margin-left: -24px;
  margin-right: -24px;
  padding-left: 36px;
  padding-right: 24px;
  padding-top: ${({ compact }) => paddingHeight / (compact ? 4 : 1)}px;
  padding-bottom: ${({ compact }) => paddingHeight / (compact ? 4 : 1)}px;
  font-size: ${({ compact }) => (compact ? 24 : 32)}px;

  & > img {
    margin-right: 24px;
    max-width: 100px;
    max-height: 100px;
    object-fit: contain;
    object-position: center center;
  }

  & > div {
    p {
      margin: 0;
    }
  }
`;
