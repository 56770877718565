import { Icon, ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';
import React, { memo, useContext } from 'react';
import HeaderContext from './HeaderContext';
import clsx from "clsx";

function HaderMenuItem({ item }) {
  const { handleNavigate, menuItemSelected } = useContext(HeaderContext);
  const classes = useStyles();

  return (
    <ListItem
      button
      divider={item.divider}
      onClick={handleNavigate.bind(item)}
      // eslint-disable-next-line eqeqeq
      className={clsx(classes.menuItem, { [classes.menuItemSelected]: item.id == menuItemSelected?.id })}
    >
      <ListItemIcon className={classes.menuIcon}>
        <Icon>{item.icon}</Icon>
      </ListItemIcon>
      <ListItemText primary={item.name} />
    </ListItem>
  );
}

const useStyles = makeStyles((theme) => ({
  menuItem: {
    borderLeftWidth: '4px',
    borderLeftStyle: 'solid',
    borderLeftColor: 'transparent',
  },
  menuItemSelected: {
    borderLeftColor: theme.palette.primary[theme.palette.type],
    color: theme.palette.primary[theme.palette.type],
    '& .MuiListItemIcon-root .MuiIcon-root': {
      color: theme.palette.primary[theme.palette.type],
    }
  },
  menuIcon: {
    minWidth: '35px',
  },
}));

export default memo(HaderMenuItem);