import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Box,
  Typography,
  useMediaQuery,
} from "@material-ui/core";

// Internacionalização
import { i18n } from '../../translate/i18n';

import { Iframe, Container } from "./styles";
import useFetch from "@services/useFetch";
import Loading from "@components/Loading";

export default function Results() {
  const api = useFetch();
  const params = useParams();
  const isMobile = useMediaQuery("(max-width:599px)");

  // States
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getUrl = async () => {      
      const url = await handleMakeUrl(api, params);
      setUrl(url);
      
      setTimeout(function(){
        setLoading(false);
      },3000)
    };
    
    setLoading(true);
    
    setTimeout(function(){        
      getUrl();
    },10000)
  }, []); // eslint-disable-line

  return (
    <>
      {/* header */}
      <AppBar id="AppBarHeader">
        <Toolbar>
          <Box>
            <Typography variant={isMobile ? "caption" : "h6"} noWrap>
              Q4BA
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>

      <Container>
        <Iframe
          sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
          title="ba2edu"
          frameborder="0"
          src={url}
        />

        <Loading title="Q4BA" open={loading}>
          { i18n.t("pages.results.wait") }
        </Loading>
      </Container>
    </>
  );
}

async function handleMakeUrl(api, { surveyKey, participantKey }) {
  return await api
    .post(`/answer/integration/${surveyKey}/${participantKey}`)
    .then(
      async ({
        data: {
          participantId,
          participantKey,
          surveyId,
          surveyKey,
          response: { success, data, iddash },
        },
      }) => {

        //console.log(data);

        if (success && typeof data == "object") {
          //const iddash = 339;

          //const params = `&p_hashresp:=${participantKey}&p_hashquest:=${surveyKey}`;
          const params = `&p_surveyparticipantid:=${participantId}&p_surveyid:=${surveyId}`;

          const url = `https://ba2edu.tnttec.com.br/ba2edu/login/embed.jsp?iddash=${iddash}&authkey=${data.chave}&header=false${params}`;

          // await new Promise((resolve) => setTimeout(resolve, 6000));

          return url;
        } else {
          alert( i18n.t("pages.results") );
        }
      }
    )
    .catch(() => {
      alert( i18n.t("pages.results") );
    });
  // .finally(() => new Promise((resolve) => setTimeout(resolve, 6000)));
}
