import { useEffect } from "react";
import { useAuth } from "../../contexts/auth";
import { useParams } from "react-router-dom";

export default function Sso(){  
  
  const { access_token, refresh_token, expires_in, token_type, userNameAccount, userId, userName, userProject, userProjectName } = useParams();
  const { sso } = useAuth();

  useEffect( () => {

    sso({
      "access_token" : access_token,
      "refresh_token" : refresh_token,
      "expires_in" : expires_in,
      "token_type" : token_type,
      "user" : {
        "account" : userNameAccount,
        "entityId" : userId,
        "name" : userName,
        "projectId" : userProject,
        "projectName" : userProjectName
      }
    });
    
    window.location.replace("https://q4ba.ceta.ceo/quest/questionario");
  })

  return null;
}