import React from "react";
import {ComponentRadio, ComponentRadioGroup, MobileOption} from "../style.js";

const Radio = ({ question, answer, handleChanged, justSee }) => {
  const getValue = () => {
    const option = answer.options.find(op => op.checked);
    return option ? option.index : null;
  }

  const handleChange = (event) => {
    const number = parseInt(event.target.value);
    handleChanged({
      ...question,
      options: question.options.map(opt => ({...opt, checked: opt.index === number}))
    });
  };

  return (
    <ComponentRadioGroup
      name={`${question.id}`}
      value={getValue()}
      onChange={handleChange}
      onKeyDown={(event) => event.key === "Enter" && event.preventDefault()}>
      {
        question.options.map(option => (
          <MobileOption key={option.index}>
            <ComponentRadio value={option.index} label={option.description} disabled={justSee} />
          </MobileOption>
        ))
      }
    </ComponentRadioGroup>
  );
};

export default Radio;
