import React, { createContext, useContext, useEffect, useState } from "react";
import * as auth from "../services/auth";
import Cookies from "js-cookie";
import api from "../services/api";
import { useHistory } from "react-router-dom";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import Button from "../components/Button";

// Internacionalização
import { i18n } from '../translate/i18n';

const AuthContext = createContext({
  signed: false,
  token: null,
  tokenType: null,
  user: {
    name: null,
    account: null,
    projectId: null,
    entityId: null,
  },
  loading: true,
  signIn: function ({ username, password }) {},
  sso: function ({ access_token, refresh_token, expires_in, token_type, user }) {},
  signOut: function () {},
  sessionExpired: function () {},
  changeProject: () => {},
});

export function AuthProvider({ children }) {
  const [token, setToken] = useState(null);
  const [tokenType, setTokenType] = useState(null);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const storageToken = Cookies.get("Q4Auth_access_token");
    const storageType = Cookies.get("Q4Auth_token_type");
    const storageUser = Cookies.get("Q4Auth_user");

    if (storageToken && storageUser) {
      api.defaults.headers.Authorization = `${
        storageType || "Bearer"
      } ${storageToken}`;
      setToken(storageToken);
      setTokenType(storageType || "Bearer");
      setUser(JSON.parse(storageUser));
    }

    setLoading(false);
  }, []);

  function sso(data){
    return new Promise((resolve) => {
      setData(data,resolve);
    });    
  }

  function signIn({ username, password }) {
    return new Promise((resolve) => {
      auth
        .signIn({ username, password })
        .then(({ data }) => {
          setData(data,resolve);
        })
        .catch((error) => {
          resolve({ signed: false, message: "Usuário ou senha inválido" });
        });
    });
  }

  function setData({ access_token, refresh_token, expires_in, token_type, user }, resolve){
    const expires_date = new Date(
      new Date().getTime() + expires_in * 1000
    );

    Cookies.set("Q4Auth_access_token", access_token, {
      expires: expires_date,
      //secure: true,
    });

    Cookies.set("Q4Auth_refresh_token", refresh_token, {
      expires: expires_date,
      //secure: true,
    });

    Cookies.set("Q4Auth_token_type", token_type, {
      expires: expires_date,
      //secure: true,
    });

    Cookies.set("Q4Auth_user", JSON.stringify(user), {
      expires: expires_date,
      //secure: true,
    });

    api.defaults.headers.Authorization = `${token_type} ${access_token}`;

    resolve({ signed: !!user, message: "" });

    setToken(access_token);
    setTokenType(token_type);
    setUser(user);
  }

  function signOut() {
    auth.signOut();

    cleanStorage();

    setOpenDialog(false);
    setToken(null);
    setTokenType(null);
    setUser(null);

    history.push("/");
  }

  function changeProject(user) {   

    const expires_date = new Date(
      new Date().getTime() + 1800 * 1000
    );

    //console.log(JSON.stringify(user))

    Cookies.set("Q4Auth_user", JSON.stringify(user), {
      expires: expires_date,
      //secure: true,
    });
    
    setUser(user);    
  }

  function sessionExpired() {
    setOpenDialog(true);
    cleanStorage();
  }

  function cleanStorage() {
    Cookies.remove("Q4Auth_access_token");
    Cookies.remove("Q4Auth_refresh_token");
    Cookies.remove("Q4Auth_token_type");
    Cookies.remove("Q4Auth_user");

    delete api.defaults.headers.Authorization;
  }

  return (
    <AuthContext.Provider
      value={{
        signed: !!user,
        token,
        tokenType,
        user,
        loading,
        signIn,
        sso,
        signOut,
        changeProject,
        sessionExpired,
      }}
    >
      {children}
      <Dialog open={openDialog}>
        <DialogTitle>{i18n.t("pages.signin.sessionExpired")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {i18n.t("pages.signin.loginAgain")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={signOut}>
            Login
          </Button>
        </DialogActions>
      </Dialog>
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  return context;
}
