import React, {useEffect, useState} from "react";

import {Box, Checkbox, CircularProgress, FormControlLabel, Grid} from "@material-ui/core";
import {i18n} from "../../../../translate/i18n";
import {Column} from "devextreme-react/data-grid";
import {SearchPanel, Selection, TreeList} from "devextreme-react/tree-list";
import {Button, TextField} from "@components/index";
import {Cancel as CancelIcon, Save as SaveIcon,} from "@material-ui/icons";
import {LoadPanel} from "devextreme-react";
import CronGenerator from "@components/CronGenerator";
const CampaignForm = ({ recurrences, participants, campaign, handleClosed, handleSubmit }) => {
  const [load, setLoad] = useState(false);
  const [form, setForm] = useState({...campaign, manual: campaign.id && !campaign?.schtriggerjob?.idschtrigger});
  const [changed, setChanged] = useState({});
  const [submit, setSubmit] = useState(false);
  const [sender, setSender] = useState(false);
  const [cronValues, setCronValues] = useState([]);

  useEffect(()=> {
    if ( !!participants ) {
      setForm(old => ({
        ...old,
        participants: participants
          .filter(item => item.selected)
          .map(item => item.id)
      }));
      setLoad(true);
    }
  }, [participants]);

  useEffect(()=> {
    setCronValues( !recurrences ? [] : recurrences.map(item => {
        return {
          ...item,
          id: item.idschtrigger,
          expression: item.expressschtrigger
        };
      }));
  }, [recurrences])

  const onSubmit = async () => {
    if (sender)
      return;

    setSubmit(true);
    const {name, startdate, stopdate, manual, schtriggerjob} = form;
    if (!!name && !!startdate && !!stopdate && ( manual || schtriggerjob ) ) {
      setSender(true);
      handleSubmit({
        name,
        startdate: toDateJson(startdate),
        stopdate: toDateJson(stopdate),
        manual: !!manual,
        idschtrigger: schtriggerjob?.idschtrigger,
        expressschtrigger: schtriggerjob?.expressschtrigger,
        descschtrigger: schtriggerjob?.descschtrigger,
        participants: form.participants
      },
      () => setSender(false));
    }
  };

  const toDateJson = (date) => {
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toJSON();
  };

  const selectedHandle = (data) => {
    const idschtrigger = data.idschtrigger;

    setForm(old => ({
      ...old,
      schtriggerjob: {
        idschtrigger: ( !!idschtrigger && idschtrigger > 0 ) ? idschtrigger : null,
        expressschtrigger: data.expression,
        descschtrigger: data.description
      }
    }));
    setChanged(old => ({...old, schtriggerjob: true}));
  }

  return <>
    {
      !load &&
      <LoadPanel visible={true}
                 shadingColor="rgba(0,0,0,0.4)"
                 message={i18n.t("messages.loading")}
                 position={{of: ".MuiDialog-container"}} />
    }
    <br/>
    <Grid container spacing={3}>
      <Grid item sm={6} xl={3}>
        <TextField
          value={form.name}
          onChange={(e) => {
            setForm(old => ({...old, name: e.target.value}))
            setChanged(old => ({...old, name: true}))
          }}
          label={ i18n.t("pages.campaign.form.name") }
          error={!form.name && (changed.name || submit)}
          required
        />
      </Grid>
      <Grid item sm={6} lg={3} xl={2}>
        <TextField
          value={form.startdate}
          onChange={(date) => {
            setForm(old => ({...old, startdate: date}));
            setChanged(old => ({...old, startdate: true}));
          }}
          label={ i18n.t("pages.campaign.form.startdate")}
          error={!form.startdate && (changed.startdate || submit)}
          required
          dateTime />
      </Grid>
      <Grid item sm={6} lg={3} xl={2}>
        <TextField
          dateTime
          value={form.stopdate}
          label={ i18n.t("pages.campaign.form.stopdate") }
          onChange={(date) => {
            setForm(old => ({...old, stopdate: date}));
            setChanged(old => ({...old, stopdate: true}));
          }}
          error={!form.stopdate && (changed.stopdate || submit)}
          required={true}
        />
      </Grid>
      <Grid item sm={6} lg={3} xl={2}>
        <FormControlLabel label={i18n.t("pages.campaign.form.manual")}
                          control={
          <Checkbox checked={form.manual}
                    onChange={() => {
                      setForm(old => ({...old, manual: !old.manual, schtriggerjob: null}));
                    }} />
        } />
      </Grid>
      <Grid item sm={12} lg={9} xl={3}>
        <CronGenerator label={i18n.t("pages.campaign.form.recurrence")}
                       values={cronValues}
                       value={form?.schtriggerjob?.expressschtrigger}
                       disabled={!!form.manual}
                       valueHandled={selectedHandle}
                       required={!form.manual}
                       error={!form.manual && !form.schtriggerjob && (changed.schtriggerjob || submit)} />
      </Grid>
    </Grid>
    <br/>
    <Grid container>
      <TreeList
        dataSource={participants}
        style={{
          maxHeight: "calc(60vh)",
        }}
        showBorders={true}
        selectedRowKeys={ load ? form.participants : null }
        keyExpr="id"
        parentIdExpr="parentid"
        onSelectedRowKeysChange={(data) => setForm(old => ({...old, participants: data}))}
      >
        <SearchPanel visible={true} width={250} />
        <Selection mode="multiple" recursive={true} />
        <Column dataField="name" caption={i18n.t('pages.campaign.form.listSelected')} />
      </TreeList>
    </Grid>
  <br/>
    <Grid container spacing={3}>
      <Grid item sm={6}>
        <Box display="flex" justifyContent="flex-end">
          <Button onClick={ () => handleClosed() } color="secondary" startIcon={<CancelIcon />} disabled={sender} >
            { i18n.t("buttons.cancel") }
          </Button>
        </Box>
      </Grid>
      <Grid item sm={6}>
        <Box display="flex">
          <Button
            onClick={ onSubmit }
            startIcon={ sender ? <CircularProgress color="inherit" size={16} /> : <SaveIcon /> }
            disabled={sender}>
            { i18n.t("buttons.save") }
          </Button>
        </Box>
      </Grid>
    </Grid>
  </>
};

export default CampaignForm;