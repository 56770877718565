import React from "react";
import SimplePopover from "./SimplePopover/SimplePopover";

import {ComponentContent, ComponentText} from "./style.js";

import Radio from "./Radio";
import Checkbox from "./Checkbox";
import Input from "./Input";
import TextArea from "./TextArea";
import Grid from "./Grid";

const Component = ({
  question,
  answers,
  handleQuestion,
  justSee,
  showError,
}) => {
  const answer = answers.find(({questionId}) => questionId === question.id);
  const propsQuestion = { question, answer, justSee, handleChanged: (questionChanged) => handleQuestion(questionChanged) };

  const inputs = {
    "input": <Input {...propsQuestion} />,
    "textarea": <TextArea {...propsQuestion} />,
    "radio": <Radio {...propsQuestion} />,
    "checkbox": <Checkbox {...propsQuestion} />,
    "grid_radio": <Grid {...propsQuestion} />,
    "grid_checkbox": <Grid {...propsQuestion} />,
  }

  return (
    <ComponentContent
      className={showError && "invalid-question"}
      required={question.required}
      qtype={question.type.code}
      number={question.number}
    >
      <ComponentText number={question.number} required={question.required}>
        <div>
          <div dangerouslySetInnerHTML={{ __html: question.description }}></div>
          {question.comment && (
            <SimplePopover content={question.comment} />
          )}
        </div>
      </ComponentText>
      {
        inputs[question.type.code]
      }
    </ComponentContent>
  );
};

export default Component;
