const Q4baLogo  = props => {

  const escala = "scale(" + props.escala + ")";

  return <svg width={ 307 * props.escala } height={ 130 * props.escala } xmlns="http://www.w3.org/2000/svg">
        <g transform={escala}>
          <title>Q4BA</title>
          <text fill={props.qFill} fontStyle="normal" fontWeight="bold" strokeWidth="0" textAnchor="start" fontFamily="sans-serif" fontSize="100" id="svg_1" y="107.27133" x="4.5" stroke="#000">Q</text>
          <text fill={props.numeroFill} stroke={props.numeroFill} transform="matrix(1 0 0 1.08225 0 -3.08252)" fontStyle="normal" fontWeight="bold" textAnchor="start" fontFamily="monospace" fontSize="120" id="svg_2" y="101.8" x="82.5" strokeWidth="2" className="logoNumber">4</text>
          <text fill={props.baFill} fontStyle="normal" fontWeight="bold" textAnchor="start" fontFamily="sans-serif" fontSize="100" id="svg_3" y="107.27133" x="152.5" strokeWidth="0" stroke="#003f7f">BA</text>
        </g>
      </svg> 
}
 
export default Q4baLogo;