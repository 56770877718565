import React from "react";
import {ComponentCheckbox} from "../style.js";

export default function GridCheckbox({ option, values, justSee, handleChanged }) {
  const handleChange = (event) => {
    handleChanged({index: parseInt(event.target.value), checked: event.target.checked});
  };

  return <ComponentCheckbox
    name={option.id}
    value={option.index}
    checked={values.includes(option.index)}
    disabled={justSee}
    onChange={handleChange} />
}
