import React, { useEffect, useState } from "react";
import { useAuth } from "@contexts/auth";
import { Button } from "@components";
import {
  Box,
  List,
  Menu,
  Dialog,
  AppBar,
  Drawer,
  Toolbar,
  IconButton,
  makeStyles,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  useMediaQuery,
  DialogContentText,
  MenuItem as MenuOption,
} from "@material-ui/core";
import { Menu as MenuIcon, AccountCircle, Language } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import {useSnackbar} from "notistack";
import { ContainerPages } from "./style";
import clsx from "clsx";

import HeaderMenuItem from "./HeaderMenuItem";
import HeaderContext from "./HeaderContext";
import useFetch from "../../services/useFetch";
import { TextField, FormControl, FormHelperText  } from "@material-ui/core";

import Q4baLogo from '../../img/q4ba-logo-svg.jsx';

// Internacionalização
import { i18n } from '../../translate/i18n';
import HeaderMenu from "@components/Header/HeaderMenu";

const drawerWidth = () => {
  if (window.innerWidth >= 600) return 280;
  else return window.innerWidth - 50;
};
const useStyles = makeStyles((theme) => ({
  main: {
    paddingTop: 70,
  },
  title: {
    flexGrow: 1,
    paddingLeft: "10px",
  },
  drawer: {
    width: drawerWidth(),
    "@media (min-width: 600px)": {},
  },
  drawerPaper: {
    width: drawerWidth(),
    "@media (min-width: 600px)": {
      top: 64,
      height: `calc(100vh - 64px)`,
    },
  },
  content: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth(),
  },
  logo: {
    margin: "0px",
    lineHeight: "0.5rem",
  }
}));

export default function Header({ children }) {
  const api = useFetch();
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();
  const { signOut, user, changeProject: changeProjectContext } = useAuth();
  const mobile = useMediaQuery("(max-width:599px)");
  const [openDrawer, setOpenDrawer] = useState(
    localStorage.getItem("headerDrawerState") === "true" && !mobile
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [menuData, setMenuData] = useState([]);
  const [menuItemSelected, setMenuItemSelected] = useState({});
  const history = useHistory();
  const [historyListening, setHistoryListening] = useState(false);
  const [showProjects, setShowProjects] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [project, setProject] = useState([]);

  const [projectIdChange, setProjectIdChange] = useState(null);
  const [changing, setChanging] = useState(false);

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const I18N_STORAGE_KEY = "i18nextLng";

  useEffect(() => {
    getMenuData();
    getProjects();
  }, []);

  useEffect(() => {
    if (
      !historyListening &&
      typeof menuData == "object" &&
      menuData.length > 0
    ) {
      setHistoryListening(true);
      history.listen((location, action) => {
        const menuItem = menuData?.find((item) => {
          return location.pathname.startsWith(item.url);
        });
        setMenuItemSelected(menuItem);
      });
    }
  }, [history, historyListening, menuData]);

  useEffect(() => {
    if (JSON.stringify(menuItemSelected) !== JSON.stringify({})) {
      const title = menuItemSelected.name;
      const titleDocument = document.title;
      document.title =
        (title && title + " | ") +
        (titleDocument.split(" | ")[1] || titleDocument);
    }
  }, [menuItemSelected]);

  async function getProjects() {
    api.get(`/u/user/${user.entityId}/project`).then(({ data }) => {
      setProject(data);
    });
  }

  async function changeProject() {
    setChanging(true);
    await api.put(`/u/projectuser/change/${user.entityId}/${projectIdChange}`);

    const [newProject] = project.filter(
      (project) => project.id === projectIdChange
    );

    changeProjectContext({
      ...user,
      projectId: projectIdChange,
      projectName: newProject.name,
    });

    setChanging(false);
    history.push("/");
  }

  async function getMenuData() {
    //const { data } = await api.get("/u/crud");

    const data = [
      // {
      //   id: 1,
      //   name: "Dashboard",
      //   icon: "dashboard",
      //   url: "/quest/dashboard",
      //   divider: true,
      // },
/*       { name: i18n.t("menu.baseParticipants"), separator: true },
      {
        id: 3,
        name: i18n.t('pages.participantgroup.title'),
        icon: "group_work",
        url: "/quest/grupoparticipante",
      },
      {
        id: 8,
        name: i18n.t('pages.participant.title'),
        icon: "people",
        url: "/quest/participante",
      }, */
      { name: i18n.t("menu.questions"), separator: true },
      {
        id: 4,
        name: i18n.t('pages.difficulty.title'),
        icon: "notes",
        url: "/quest/dificuldade",
      },
      {
        id: 12,
        name: i18n.t('pages.group.title'),
        icon: "notes",
        url: "/quest/grupo",
      },
      { name: i18n.t("menu.surveys"), separator: true },
      {
        id: 6,
        name: i18n.t('pages.form.title'),
        icon: "description",
        url: "/quest/formulario",
      },
      {
        id: 7,
        name: i18n.t('pages.term.title'),
        icon: "check_circle",
        url: "/quest/termo",
      },
      {
        id: 2,
        name: i18n.t('pages.category.title'),
        icon: "sort",
        url: "/quest/categoria",
      },
      {
        id: 5,
        name: i18n.t('pages.survey.title'),
        icon: "fact_check",
        url: "/quest/questionario",
      },
      // {
      //  id: 8,
      //  name: "Visualizar",
      //  icon: "visibility",
      //  divider: true,
      // },
      // {
      //  id: 9,
      //  name: "Cliente",
      //  icon: "business",
      //  url: "/setup/cliente",
      // },
      // {
      //   id: 10,
      //   name: "Usuario",
      //   icon: "account_box",
      //   url: "/setup/usuario",
      // },
      // {
      //   id: 11,
      //   name: "Projeto",
      //   icon: "assignment_turned_in",
      //   url: "/setup/projeto",
      // },
    ];

    setMenuData(data);
  }

  function handleSignOut() {
    signOut();
  }

  function handleDrawerToggle() {
    if (!mobile) localStorage.setItem("headerDrawerState", !openDrawer);

    setOpenDrawer(!openDrawer);
  }

  function handleOpenUserMenu(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleCloseUserMenu() {
    setAnchorEl(null);
  }

  function handleOpenChangeLanguage(event) {
    setAnchorEl2(event.currentTarget);
  }
    
  function handleCloseChangeLanguage() {
    setAnchorEl2(null);
  }

  function handleChangeLanguage(lang){

    window.localStorage.setItem(I18N_STORAGE_KEY,lang);

    handleCloseChangeLanguage();

    window.location.reload();
  }

  function handleNavigate() {
    history.push(this.url);
  }

  const handlePasswordChangeSubmit = () => {
    if (newPassword !== confirmNewPassword) {
      setPasswordError("As senhas não coincidem. Tente novamente.");
      return;
    }
    const body = {
      "newpassword" : newPassword,
      "currentpassword": currentPassword
    };
    console.log(user);
    api.put(`/u/user/${user.entityId}/password`, body)
      .then(() => {        
        setShowChangePassword(false);
        enqueueSnackbar(i18n.t("messages.updateSuccess"), {variant: "success"});
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          enqueueSnackbar("Alteração de senha não autorizada. Verifique a senha atual informada.", {variant: "error"});
        } else {
          enqueueSnackbar(i18n.t("messages.updateError"), {variant: "error"});
        }
      })
  };

  return (
    <React.Fragment>
      <AppBar id="AppBarHeader">
        <Toolbar>
          <IconButton color="inherit" onClick={handleDrawerToggle}>
            <MenuIcon />
          </IconButton>
          <Box className={classes.title}>
            <div display={ mobile ? "none" : "flex"} 
                 className={classes.logo} >
             <Q4baLogo qFill="#ffffff" 
                  numeroFill="#ff3381"
                      baFill="#ffffff"
                      escala="0.25" /> 
            </div>
            <Typography variant="caption" component="div" noWrap>
              {user.projectName}
            </Typography>
          </Box>

          
          <Button onClick={handleOpenChangeLanguage} >
            <Language/>
          </Button>
          {
            !!anchorEl2 &&
            <Menu
              id="menu-language"
              anchorEl={anchorEl2}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              open={true}
              onClose={() => setAnchorEl2(false)}
            >
              <MenuOption key={200001} onClick={() => handleChangeLanguage("pt-BR")}>
                pt-BR
              </MenuOption>
              <MenuOption key={200002} onClick={() => handleChangeLanguage("en-US")}>
                en-US
              </MenuOption>
              <MenuOption key={200003} onClick={() => handleChangeLanguage("es-ES")}>
                es-ES
              </MenuOption>
            </Menu>
          }
          <Button onClick={handleOpenUserMenu} startIcon={<AccountCircle />}>
            {mobile ? "" : user.name}
          </Button>
          {
            !!anchorEl &&
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              open={!!anchorEl}
              onClose={handleCloseUserMenu}
            >
              <MenuOption onClick={handleSignOut}>Sair</MenuOption>
              <MenuOption onClick={() => setShowProjects(true)}>
                Trocar de projeto
              </MenuOption>
              <MenuOption onClick={() => setShowChangePassword(true)}>
                Alterar senha
              </MenuOption>
            </Menu>
          }
          {
            !!showProjects &&
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              open={showProjects}
              onClose={() => setShowProjects(false)}
            >
              {project.length - 1 ? (
                project.map(({id, name}) => (
                  <MenuOption key={id} onClick={() => setProjectIdChange(id)}>
                    {name}
                  </MenuOption>
                ))
              ) : (
                <MenuOption disabled>
                  Nenhum Projeto encontrado além do atual
                </MenuOption>
              )}
            </Menu>
          }
        </Toolbar>
      </AppBar>
      <Drawer
        open={openDrawer}
        variant={mobile ? "temporary" : "persistent"}
        onClose={() => {
          if (mobile) setOpenDrawer(false);
        }}
        anchor="left"
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <HeaderContext.Provider value={{ handleNavigate, menuItemSelected }}>
          <List disablePadding={true}>
            {
              menuData.map((item, key) => (
                item.separator ?
                  <HeaderMenu key={key}>{item.name}</HeaderMenu> :
                  <HeaderMenuItem item={item} key={key}/>
              ))
            }
          </List>
        </HeaderContext.Provider>
      </Drawer>
      <ContainerPages
        className={clsx(classes.content, {
          [classes.contentShift]: openDrawer && !mobile,
        })}
      >
        {children}
      </ContainerPages>

      <Dialog open={!!projectIdChange} onClose={() => setProjectIdChange(null)}>
        <DialogTitle>Confirmação</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tem certeza que deseja altera de projeto?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={changeProject} disabled={changing}>
            {!changing ? "Sim" : "Alterando projeto..."}
          </Button>
          <Button color="secondary" onClick={() => setProjectIdChange(null)}>
            Não
          </Button>
        </DialogActions>
      </Dialog>
      
      <Dialog open={showChangePassword} onClose={() => setShowChangePassword(false)}>
        <DialogTitle>Alterar senha</DialogTitle>
        <DialogContent>
          <FormControl fullWidth error={!!passwordError}>
            <TextField
              label="Senha Atual"
              type="password"
              variant="outlined"
              margin="normal"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
            <TextField
              label="Nova Senha"
              type="password"
              variant="outlined"
              margin="normal"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <TextField
              label="Confirmar Nova Senha"
              type="password"
              variant="outlined"
              margin="normal"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              error={!!passwordError}
              helperText={passwordError}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePasswordChangeSubmit}>{i18n.t("buttons.save")}</Button>
          <Button onClick={() => setShowChangePassword(false)}>{i18n.t("buttons.close")}</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
