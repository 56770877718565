import { ButtonGroup } from "@components/Button/Button";
import TextField from "@components/TextField/TextField";
import { Grid, Icon, IconButton, Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useState, useEffect, useCallback, useContext } from "react";
import NumberFormat from "react-number-format";
import SurveyContext from "../SurveyContext";
import OptionGridContext from "./OptionGridContext";

// Internacionalização
import { i18n } from '../../../../translate/i18n';

export default function OptionGridColumns() {
  const { questionData, setQuestionData } = useContext(SurveyContext);
  const { columnsDefault, setColumnsDefault } = useContext(OptionGridContext);
  const [flagAdd, setFlagAdd] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (questionData && columnsDefault.length === 0)
      setColumnsDefault(questionData.children[0].options);
  }, [columnsDefault, questionData, setColumnsDefault]);

  useEffect(() => {
    if (!!flagAdd && columnsDefault) {
      document.getElementById(`column_grid-${flagAdd}`).focus();
      setFlagAdd(null);
    }
  }, [flagAdd, columnsDefault]);

  const reorderIndex = useCallback((array) => {
    return array.map((data, index) => ({
      ...data,
      index: index,
    }));
  }, []);

  const handleAdd = useCallback(
    (column) => {
      const index = column.index + 1;
      const newColumns = columnsDefault.slice();
      newColumns.splice(index, 0, {
        id: "",
        description: "",
        score: "",
      });

      const newChildren = questionData.children.map(function (question) {
        const newOptions = question.options.slice();
        newOptions.splice(this, 0, {
          id: "",
          description: "",
          score: "",
        });
        return {
          ...question,
          options: reorderIndex(newOptions),
        };
      }, index);

      setFlagAdd(index);
      setColumnsDefault(reorderIndex(newColumns));
      setQuestionData({ ...questionData, children: newChildren });
    },
    [
      columnsDefault,
      questionData,
      reorderIndex,
      setColumnsDefault,
      setQuestionData,
    ]
  );

  const handleDelete = useCallback(
    (column) => {
      if (columnsDefault.length === 2) {
        enqueueSnackbar( i18n.t("pages.survey.optionGridColumns.twoColumnsMinimum") , {
          variant: "warning",
        });
      } else {
        const newColumns = columnsDefault.slice();
        newColumns.splice(column.index, 1);

        const newChildren = questionData.children.map(function (question) {
          const newOptions = question.options.slice();
          newOptions.splice(this, 1);
          return {
            ...question,
            options: reorderIndex(newOptions),
          };
        }, column.index);

        setColumnsDefault(reorderIndex(newColumns));
        setQuestionData({ ...questionData, children: newChildren });
      }
    },
    [
      columnsDefault,
      enqueueSnackbar,
      questionData,
      reorderIndex,
      setColumnsDefault,
      setQuestionData,
    ]
  );

  const updateQuestionData = useCallback(
    (event, column) => {
      const newChildren = questionData.children.map(
        function (question) {
          const newOptions = question.options.slice();
          const option = newOptions[this.index];
          newOptions.splice(this.index, 1, {
            ...option,
            [this.name]: this.value,
          });
          return {
            ...question,
            options: reorderIndex(newOptions),
          };
        },
        {
          index: column.index,
          name: event.target.name,
          value: event.target.value,
        }
      );

      setQuestionData({ ...questionData, children: newChildren });
    },
    [questionData, reorderIndex, setQuestionData]
  );

  const handleBlur = useCallback(
    (event, column) => {
      if (event.target.name !== "score") {
        updateQuestionData(event, column);
      }
    },
    [updateQuestionData]
  );

  const handleChange = useCallback(
    (event, column) => {
      const newColumns = columnsDefault.slice();
      newColumns.splice(column.index, 1, {
        ...column,
        [event.target.name]: event.target.value,
      });
      setColumnsDefault(newColumns);

      if (event.target.name === "score") {
        updateQuestionData(event, column);
      }
    },
    [columnsDefault, setColumnsDefault, updateQuestionData]
  );

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography
          variant="body1"
          style={{
            borderBottom: "1px solid rgba(0,0,0,.1)",
            marginTop: "15px",
            marginBottom: "10px",
          }}
        >
          { i18n.t("pages.survey.optionGridColumns.column") }
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {columnsDefault.map((column) => {
          return (
            <Grid container spacing={1}>
              <Grid item xs={12} sm={5}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <Typography variant="body1">{`#${
                      column.index + 1
                    }`}</Typography>
                  </Grid>
                  <Grid item xs>
                    <TextField
                      id={`column_grid-${column.index}`}
                      label={ i18n.t("pages.survey.optionGridColumns.columnText") }
                      name="description"
                      value={column.description}
                      onBlur={(event) => handleBlur(event, column)}
                      onChange={(event) => handleChange(event, column)}
                      required={true}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={8} sm={2}>
                <TextField
                  label={ i18n.t("pages.survey.optionGridColumns.punctuation") }
                  name="score"
                  value={column.score}
                  //onBlur={() => handleBlur(option, type)}
                  onChange={(event) => handleChange(event, column)}
                  inputProps={{
                    maxLength: "15",
                    style: { textAlign: "right" },
                  }}
                  InputProps={{
                    inputComponent: NumberFormatScore,
                  }}
                  placeholder="1,00"
                />
              </Grid>
              <Grid item xs={4} sm={1} style={{ paddingTop: "7px" }}>
                <ButtonGroup size="small">
                  <IconButton
                    onClick={() => handleAdd(column)}
                    title={ i18n.t("pages.survey.optionGridColumns.addColumnText") }
                  >
                    <Icon>add</Icon>
                  </IconButton>
                  <IconButton
                    onClick={() => handleDelete(column)}
                    title={ i18n.t("pages.survey.optionGridColumns.deleteColumnText") }
                  >
                    <Icon>delete</Icon>
                  </IconButton>
                </ButtonGroup>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
}

function NumberFormatScore(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString={true}
      decimalSeparator=","
      decimalScale={2}
    />
  );
}
