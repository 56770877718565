import {i18n} from "../../../../translate/i18n";
import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing,
    Export,
    FilterRow,
    Toolbar,
    Item,
    Selection,
    Sorting
} from "devextreme-react/data-grid";
import {Button} from "devextreme-react/button";
import React, {useState} from "react";

const CampaignOccurrencesParticipants = ({participants, handleNotifyParticipants, submit}) => {

  const [participantsKey, setParticipantsKey] = useState([]);

  const sentOption = {
    yes: i18n.t("pages.campaign.occurrences.participants.sent.yes"),
    no: i18n.t("pages.campaign.occurrences.participants.sent.no")
  }

  const getData = () => (participants || []).map(participant => {
    return {
      ...participant,
      phone: !participant.phone ? "" : participant.phone.replaceAll(";", "\n"),
      surveySent: participant.surveySent ? sentOption.yes : sentOption.no
    }
  });

  const handleNotify = () => {
    handleNotifyParticipants(participantsKey);
  }

  return <>
    <DataGrid
      dataSource={getData()}
      allowColumnResizing={true}
      noDataText={ i18n.t('messages.noRecordsFound') }
      columnResizingMode="widget"
      keyExpr="id"
      style={{
        maxHeight: "calc(80vh)",
        marginTop: "14px"
      }}
      wordWrapEnabled={true}
      columnAutoWidth={true}
      showBorders={true}
      showRowLines={true}
      showColumnLines={true}
      selectedRowKeys={participantsKey}
      onSelectionChanged={({selectedRowKeys}) => setParticipantsKey(selectedRowKeys)}>

      <Selection mode="multiple" selectAllMode="allPages" showCheckBoxesMode="onClick" />
      <Export enabled={true} />
      <ColumnChooser enabled={true} mode="select" styleClass={{background: 'red'}} />
      <Sorting mode="multiple" />
      <FilterRow visible={true}  />
      <ColumnFixing enabled={true} />

      <Toolbar visible={true}>
        <Item name="groupPanel">1</Item>
        <Item location="after">
          <Button icon="clearformat"
                  type="normal"
                  text={i18n.t("buttons.cancel")}
                  onClick={() => setParticipantsKey([])}
                  disabled={submit || participantsKey.length === 0} />
          <Button icon="email"
                  type="success"
                  text={i18n.t(
                    "pages.campaign.occurrences.participants.notify.participant"
                      .concat(participantsKey.length <= 1 ? "" : "s"),
                    {count: participantsKey.length})}
                  onClick={handleNotify}
                  style={{margin: "0px 14px"}}
                  disabled={submit || participantsKey.length === 0} />
        </Item>
        <Item name="addRowButton" showText="always" />
        <Item name="exportButton" />
        <Item name="columnChooserButton" />
        <Item name="searchPanel" />
      </Toolbar>

      <Column dataField="identify"
              alignment="right"
              width={80}
              allowHiding={false}
              caption={ i18n.t('pages.campaign.occurrences.participants.id') } />

      <Column dataField="name"
              allowHiding={false}
              caption={ i18n.t('pages.campaign.occurrences.participants.name') } />

      <Column dataField="email"
              allowHiding={false}
              caption={ i18n.t('pages.campaign.occurrences.participants.email') } />

      <Column dataField="phone"
              caption={ i18n.t('pages.campaign.occurrences.participants.phone') }
              width={140}
              visible={false}
              cellRender={({data: {phone}}) => {
                  return phone.split("\n").map((item, key) => <div key={key}>{item}</div>)
              }}
      />

      <Column dataField="cpf"
              width={140}
              visible={false}
              caption={ i18n.t('pages.campaign.occurrences.participants.cpf') } />

      <Column dataField="surveySent"
              caption={ i18n.t('pages.campaign.occurrences.participants.response') }
              width={100}
              allowHiding={false} />
    </DataGrid>
  </>
};

export default CampaignOccurrencesParticipants;