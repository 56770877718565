import React from "react";
const {
  createTheme,
  ThemeProvider,
} = require("@material-ui/core");

export default function AppTheme({ children }) {

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          //type: prefersDarkMode ? 'dark' : 'light',
          primary: {
            light: '#7986cb',
            main: '#3f51b5',
            dark: '#303f9f',
            contrastText: '#fff',
            
            opacity1: 'rgba(63, 81, 181, .1)',
            opacity2: 'rgba(63, 81, 181, .2)',
            opacity3: 'rgba(63, 81, 181, .3)',
            opacity4: 'rgba(63, 81, 181, .4)',
            opacity5: 'rgba(63, 81, 181, .5)',
            opacity6: 'rgba(63, 81, 181, .6)',
            opacity7: 'rgba(63, 81, 181, .7)',
            opacity8: 'rgba(63, 81, 181, .8)',
            opacity9: 'rgba(63, 81, 181, .9)',
          }
        },
      }),
    []
  );

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
