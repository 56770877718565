import React from "react";
import { ComponentRadio } from "../style.js";

export default function GridRadio({option, values, justSee, handleChanged}) {

  const handleChange = (event) => {
    handleChanged({index: parseInt(event.target.value), checked: event.target.checked});
  };

  return <ComponentRadio
      name={option.id}
      value={option.index}
      checked={option.index === values[0]}
      disabled={justSee}
      onChange={handleChange}/>
}
