import styled from "styled-components";

export const QuestionPaper = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 10px;
  margin-bottom: 15px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  transition: border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  &:hover {
    border-color: #3f51b5;
  }
  &:hover .QuestionActions {
    opacity: 1;
  }
`;
export const QuestionContent = styled.div`
  flex-grow: 1;
  max-width: 100%;
  flex-basis: 0;
  padding-left: 10px;
  &:hover {
    cursor: pointer;
  }
`;
export const QuestionActions = styled.div`
  flex-grow: 0;
  max-width: 30px;
  flex-basis: 30px;
  text-align: right;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  opacity: 0;
`;
export const QuestionText = styled.div`
  margin-bottom: 20px;
  position: relative;
  ${(props) => {
    if (props.required)
      return `&:before {
      content: '* ';
      color: red;
      position: absolute;
      left: -10px;
    }`;
  }}
  & > * {
    margin: 0;
  }
`;
export const QuestionOptions = styled.div``;
export const Option = styled.div`
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
`;
export const OptionIcon = styled.div`
  flex-grow: 0;
  max-width: 30px;
  flex-basis: 30px;
  text-align: left;
`;
export const OptionText = styled.div`
  flex-grow: 1;
  max-width: 100%;
  flex-basis: 0;
  margin-bottom: 10px;
`;
export const AnswerText = styled.div`
  ${(props) => {
    if (props.type === "textarea") {
      return "height: 90px;";
    } else return "height: 30px;";
  }}

  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
`;
