import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Grid, Icon, IconButton } from "@material-ui/core";
import { TextField } from "../../../../components";
import { ButtonGroup } from "../../../../components/Button";
import NumberFormat from "react-number-format";
import SurveyContext from "../SurveyContext";
import { i18n } from "src/translate/i18n";

function Option({ data, addOption, deleteOption }) {
  const { questionData, setQuestionData } = useContext(SurveyContext);
  const [option, setOption] = useState({});

  useEffect(() => {
    setOption(data);
  }, [data]);

  const handleChange = useCallback((event, option) => {
    setOption({ ...option, [event.target.name]: event.target.value });
  }, []);

  const handleBlur = useCallback(
    (option) => {
      const newOptions = questionData.options;
      newOptions.splice(option.index, 1, option);
      const newQuestion = { ...questionData, options: newOptions };
      setQuestionData(newQuestion);
    },
    [questionData, setQuestionData]
  );

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={8}>
        <TextField
          id={`question-option-${data.index}`}
          label={ i18n.t("pages.survey.option.answer") }
          name="description"
          value={option.description}
          onBlur={() => handleBlur(option)}
          onChange={(event) => handleChange(event, option)}
          required={true}
        />
      </Grid>
      <Grid item xs={8} sm={1}>
        <TextField
          label={ i18n.t("pages.survey.option.punctuation") }
          name="score"
          value={option.score}
          onBlur={() => handleBlur(option)}
          onChange={(event) => handleChange(event, option)}
          inputProps={{ maxLength: "15", style: { textAlign: "right" } }}
          InputProps={{
            inputComponent: NumberFormatScore,
          }}
          placeholder="1,00"
        />
      </Grid>
      <Grid item xs={4} sm={1} style={{ paddingTop: "7px" }}>
        <ButtonGroup size="small">
          <IconButton
            onClick={() => addOption(option)}
            title={i18n.t("pages.survey.option.addOption")}
          >
            <Icon>add</Icon>
          </IconButton>
          <IconButton
            onClick={() => deleteOption(option)}
            title={i18n.t("pages.survey.option.deleteOption")}
          >
            <Icon>delete</Icon>
          </IconButton>
        </ButtonGroup>
      </Grid>
    </Grid>
  );
}

function NumberFormatScore(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString={true}
      decimalSeparator=","
      decimalScale={2}
    />
  );
}

export default memo(Option);
