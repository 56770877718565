import React from "react";
import ButtonUI from "@material-ui/core/Button";
import ButtonGroupUI from "@material-ui/core/ButtonGroup";
import IconButtonUI from "@material-ui/core/IconButton";

export default function Button(props) {
  if (props.icon) {
    return <IconButtonUI {...props} />;
  } else {
    return <ButtonUI color="primary" variant="contained" {...props} />;
  }
}

export function ButtonGroup(props) {
  return <ButtonGroupUI {...props} />;
}
