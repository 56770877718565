import React from "react";

import {ComponentTextArea} from "../style.js";

const TextArea = ({ question, answer, handleChanged, justSee }) => {
  const getValue = () => answer.response;

  const handleChange = (event) => {
    handleChanged({...question, response: event.target.value});
  };

  return (
    <ComponentTextArea
      name={question.id}
      value={getValue()}
      disabled={justSee}
      onChange={handleChange}
    />
  );
};

export default TextArea;
