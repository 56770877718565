import cronstrue from "cronstrue/i18n";
import i18n from "i18next";

const localesComponent = {
    "pt-BR": "pt_BR",
    "es-ES": "es",
    "en-US": "en"
}

const i18nCron = {
    "pt_BR": {
        "Every": "Todos",
        "day(s)": "dia(s)",
        "Every week day": "Todos os dias da semana",
        "Start time": "Hora de início",
        "hour": "hora",
        "At": "Em",
        "minute(s)": "minuto(s)",
        "Day": "Dia",
        "of every month(s)": "de cada mês(es)",
        "Last day of every month": "Último dia de cada mês",
        "On the last weekday of every month": "No último dia útil de cada mês",
        "day(s) before the end of the month": "dia(s) antes do final do mês",
        "Monday": "Segunda-feira",
        "Tuesday": "Terça-feira",
        "Wednesday": "Quarta-feira",
        "Thursday": "Quinta-feira",
        "Friday": "Sexta-feira",
        "Saturday": "Sábado",
        "Sunday": "Domingo",
        "Minutes": "Minutos",
        "Hourly": "De hora em hora",
        "Daily": "Diariamente",
        "Weekly": "Semanalmente",
        "Monthly": "Mensalmente",
        "Custom": "Personalizado",
        "Expression": "Expressão",
        "columns": {
            "description": "Descrição",
            "expression": "Expressão"
        }
    },
    "es": {
        "Every": "Todos",
        "day(s)": "día(s)",
        "Every week day": "Todos los días de la semana",
        "Start time": "Hora de inicio",
        "hour": "hora",
        "At": "En",
        "minute(s)": "minuto(s)",
        "Day": "Día",
        "of every month(s)": "de cada mes(es)",
        "Last day of every month": "Último día de cada mes",
        "On the last weekday of every month": "El último día laborable de cada mes",
        "day(s) before the end of the month": "día(s) antes de fin de mes",
        "Monday": "Lunes",
        "Tuesday":  "Martes",
        "Wednesday": "Miércoles",
        "Thursday": "Jueves",
        "Friday": "Viernes",
        "Saturday": "Sábado",
        "Sunday": "Domingo",
        "Minutes": "Minutos",
        "Hourly": "Cada hora",
        "Daily": "Diario",
        "Weekly": "Semanal",
        "Monthly": "Mensual",
        "Custom": "Personalizado",
        "Expression": "Expresión",
        "columns": {
            "description": "descripción",
            "expression": "Expresión"
        }
    },
    "en": {
        "columns": {
            "description": "Description",
            "expression": "Expression"
        }
    }
};

export const localeApp = localesComponent[i18n.language];

export const translateCrun = (expression, locale) => {
    return cronstrue.toString(
        expression,
        {locale: locale || localeApp});
}

export const i18nComponent = i18nCron[localeApp];