const messages = {
  pt : {
    translations : {
      titles : {
        app : "Q4BA",
        appDescription : "Questionários para Business Analytics"
      },
      buttons : {
        add: "Adicionar",
        save: "Gravar",
        delete: "Excluir",
        cancel: "Cancelar",
        close: "Fechar",
        new: "Novo",
        yes: "Sim",
        no: "Não",
        file: "Arquivo",
        changeProject : "Trocar de projeto",
        logout : "Sair",
        preview : "Preview",
        import : "Importar",
        continue : "Continuar",
        viewResult : "Visualizar Resultado",
        view : "Visualizar",
        sendAnswer : "Enviar Respostas",
        send : "Enviar",
        next : "Avançar",
        change : "Alterar",
        visualize : "Visualizar",
        published : "Publicado",
        publish : "Publicar",
        campaigns : "Campanhas",
        draft : "Rascunho",
        copy : "Copiar",
        update : "Atualizar",
        sendMails : "Enviar E-mails",
        hideAll : "Ocultar Todos",
        showAll : "Mostrar Todos",
        back: "Voltar",
        savePartial: "Salvar Parcial",
        select: "Selecione",
        startOccurrence: "Iniciar Ocorrência"
      },
      messages : {
        onloadError : "Não foi possível carregar os dados",
        saveSuccess : "Gravado com sucesso",
        saveError : "Não foi possível gravar",
        saveErrorTryAgain : "Não foi possível gravar, tente novamente",
        saveAnswersError : "Não foi possível salvar as respostas. Tente novamente, por favor.",
        deleteSuccess : "Excluído com sucesso",
        deleteError : "Não foi possível excluir",
        deleteErrorTryAgain : "Não foi possível excluir, tente novamente",
        deleteErrorInUse : "Não foi possível excluir, o item está em uso",
        selectedDeleteConfirm : "Confirma a exclusão dos itens selecionados?",
        deleteConfirm : "Confirma a exclusão?",
        excluding : "Excluindo...",
        loading : "Carregando...",
        processing : "Processando...",
        publishing : "Publicando...",
        updatingPublish : "Alterando Publicação",
        saving : "Salvando...",
        wait : "Aguarde...",
        changingProject : "Alterando projeto...",
        sending : "Enviando",
        waitRedirect : "Aguarde e será redirecionado.",
        updateSuccess : "Atualizado com sucesso",
        updateError : "Não foi possível atualizar",
        updateErrorTryAgain : "Não foi possível atualizar, tente novamente",
        confirmation : "Confirmação",
        changeProjectConfirmation : "Tem certeza que deseja altera de projeto?",
        noRecordsFound : "Nenhum registro encontrado",
        noMoreProjectsFound : "Nenhum projeto encontrado alem do atual",
        emailSended : "E-mail enviado.",
        emailError : "Não foi possível enviar o e-mail",
        actionNotCompleted : "Não foi possível concluir essa ação. Tente novamente, por favor.",
        greaterThanZero : "Informe um valor maior que zero.",
        participantNotAdded : "Não foi possível adicionar o participante",
        participantDataNotUpdated : "Não foi possível atualizar os dados do participante",
        participantExcluded : "Participante excluído com sucesso",
        participantNotExcluded : "Não foi possível excluir o participante",
        campaignNotAdded : "Não foi possível adicionar a campanha",
        campaignDataNotUpdated : "Não foi possível atualizar os dados da campanha",
        campaignExcluded : "Campanha excluída com sucesso",
        campaignNotExcluded : "Não foi possível excluir a campanha",
        copied : "Copiado!",
        confirmSendSelectedEmails : "Confirma o envio dos e-mails selecionados?",
        surveyConfigEmailNotFound: "Preencha todos os campos dos e-mails de convite e conclusão",
        initOccurrence: "Deseja realmente iniciar uma nova ocorrência?"
      },
      menu : {
        projectAdministration : "Administração do projeto",
        baseParticipants: "Base de Participantes",
        questions: "Questões",
        surveys: "Questionários",
      },
      pages : {
        campaign: {
          dialog: {
            list: "Campanhas",
            create: "Cadastrar campanhas",
            edit: "Editar campanha",
            occurrences: {
              list: "Ocorrências",
              participants: "Participantes",
            }
          },
          form: {
            name: "Nome da Campanha",
            startdate: "Início",
            stopdate: "Término",
            recurrence: "Recorrência",
            schtriggerjobSelected: "Selecione",
            listSelected: "Selecionar Todos",
            manual: "Gerada manualmente"
          },
          list: {
            campaign: "Campanha",
            participants: "Participantes",
            recurrence: "Recorrência",
            startDate: "Início",
            stopDate: "Termino",
            edit: "Editar campanha",
            occurrence: "Ocorrências da campanha",
            delete: "Excluir campanha",
            copyUrl: "Copiar link de acesso ao questionário",
            qrcode : "QR Code",
          },
          occurrences: {
            list: {
              occurrence: "Ocorrência",
              startDate: "Início",
              stopDate: "Termino",
              status: "Status",
              active: "Ativa",
              inactive: "Inativa",
              response: "Respostas",
              participants: "Participantes",
              occurrenceCreated: "A ocorrência foi criada. Aguarde o processamento da lista de participantes.",
            },
            participants: {
              id: "ID",
              name: "Nome",
              email: "E-mail",
              response: "Respondido",
              phone: "Telefone",
              cpf: "CPF",
              sent: {
                yes: "Sim",
                no: "Não"
              },
              notify: {
                participant: "Notificar participante selecionado",
                participants: "Notificar os {{count}} participantes selecionados",
              }
            }
          },
        },
        signin : {
          user: "Usuário",
          password: "Senha",
          enter: "Entrar",
          sessionExpired : "A sessão do usuário expirou",
          loginAgain : "Essa sessão não é mais válida. Faça login novamente."
        },
        answer : {
          alreadyAnswered : "Você já começou a responder esse questionário",
          surveySent : "Você já enviou as respostas desse questionário",
          justSeeAnswers : ", você pode apenas ver suas respostas",
          surveyUnavailableCurrently  : "Questionário indisponível",
          surveyUnavailable  : "Questionário indisponível",
          wantViewDashboard : "Deseja acessar o dashboard com o resultado de suas respostas?",
          completed : {
            surveyCompleted : "Questionário Concluído",
            answerSended : "Sua resposta foi enviada. Obrigado.",
            clickAccessResult : "Para acessar o dashboard com o resultado de suas respostas, clique no link a seguir.",
            accessResult : "Acessar o resultado"
          },
          form : {
            identification : "Identificação",
            termReaded : "Li e aceito os",
            term : "Termos",
            linkSended : "Um link de acesso ao questionário foi enviado para o e-mail cadastrado.",
            checkSpam : "Caso não encontre o e-mail em sua caixa de entrada, verifique também sua caixa de spam.",
            anonymous: "Resposta anônima"
          }
        },
        category : {
          title : "Categorias",
          grid : {
            name : "Nome",
            description : "Descrição",
          }
        },
        group : {
          title : "Grupos de Questões",
          grid : {
            name : "Nome",
            description : "Descrição",
          }
        },
        participantgroup : {
          title : "Grupos de Participantes",
          grid : {
            name : "Nome",
            description : "Descrição",
          }
        },
        participant : {
          title : "Participantes",
          grid : {
            name : "Nome",
            phone : "Telefone",
            email : "E-mail"
          }
        },
        difficulty : {
          title : "Dificuldades das Questões",
          grid : {
            name : "Nome",
            description : "Descrição",
          }
        },
        habilityGroup : {
          title : "Grupos de Habilidades",
          grid : {
            name : "Nome",
            description : "Descrição",
          }
        },
        form : {
          title : "Formulários de Identificação",
          grid : {
            name : "Nome",
          },
          form : {
            identity : "Identificador",
            attribute : "Atributo",
            phone : "Telefone",
            name : "Nome",
            required : "Obrigatório"
          }
        },
        term : {
          title : "Termos de Consentimento",
          grid : {
            name : "Nome",
          },
        },
        survey : {
          title : "Questionários",
          titleSingular : "Questionário",
          surveyNotFound : "Questionário inexistente ou não pertence a esse projeto",
          surveyLoadError : "Não foi possível carregar o questionário",
          categorysLoadError : "Não foi possível carregar as categorias",
          termsLoadError : "Não foi possível carregar os termos",
          tab : {
            editor : "Editor",
            config : "Configurações"
          },
          form : {
            newCampaign: "Cadastro de Campanha",
            title : "Título do Questionário",
            name : "Nome da Campanha",
            category : "Categoria",
            startDate : "Vigência de",
            endDate : "Vigência até",
            recurrence: "Recorrência",
            term : "Termo de Aceite",
            description : "Descrição do Questionário",
            backgroundColor : "Cor de Fundo do Cabeçalho",
            titleColor : "Cor do Título do Cabeçalho",
            logo : "Logo",
            upload : "Fazer Upload",
            formSelect : {
              title : "Formulário",
              select : "Selecione"
            },
            recurrenceSelect : {
              title : "Recorrência",
              select : "Selecione"
            },
          },
          option : {
            answer : "Resposta",
            punctuation : "Pontuação",
            addOption : "Adicionar opção de resposta",
            deleteOption : "Excluir opção de resposta",
          },
          optionGrid : {
            answerOption : "Opções de Resposta",
          },
          optionGridColumns : {
            column : "Coluna",
            twoColumnsMinimum : "É necessário ter no mínimo duas colunas",
            punctuation : "Pontuação",
            columnText : "Texto da coluna",
            addColumnText : "Adicionar texto de coluna",
            deleteColumnText : "Excluir texto da coluna",
          },
          optionGridLines : {
            twoLinesMinimum : "É necessário ter no mínimo duas linhas",
            line : "Linha",
            columnPunctuation : "Pontuação das Colunas",
            lineText : "Texto da linha",
            addTextLine : "Adicionar texto de linha",
            deleteTextLine : "Excluir texto da linha",
          },
          optionList : {
            twoOptionsMinimum : "É necessário ter no mínimo duas opções de respostas",
            answerOption : "Opções de Resposta",
          },
          pageSurvey : {
            noTitle : "Sem título",
            page : "Página",
            deleteConfirmation : "Confirma a exclusão da Página e seu conteúdo?",
            dragging : "Arraste e solte um tipo de componente aqui",
          },
          pageSurveyList : {
            noTitle : "Sem título",
            unableToSave: "Não foi possível salvar a Página ",
            unableToDelete: "Não foi possível excluir a Página ",
            pageExcluded : "Página excluída",
            ondePageMinimum : "É necessário ter no mínimo uma página",
          },
          question : {
            fillDescription : "Preencha o campo Descrição",
            grid : {
              difficulty : "Dificuldade",
              group : "Grupo",
              weight : "Peso",
            },
            requireAnswerEachLine : "Exigir uma resposta em cada linha",
            requiredQuestion : "Questão Obrigatória",
            calculateResult : "Calcular resultado",
            questionDescription : "Descrição da Questão (opcional)"
          },
          questionSample : {
            updateComponent : "Clique para alterar o componente",
          },
          questionTypeList : {
            components : "Componentes",
            singleChoice : "Escolha Única",
            multipleChoice : "Escolha Multipla",
            shortAnswer : "Resposta Curta",
            longAnswer : "Resposta Longa",
            label : "Rótulo",
            singleChoiceMatrix : "Matriz de Escolha Única",
            multipleChoiceMatrix : "Matriz de Múltipla Escolha",
          },
          surveyConfig : {
            fillInviteEmail : "Preencha os campos de email, nome, assunto e mensagem para o email de convite",
            fillConclusionEmail : "Preencha os campos de email, nome, assunto e mensagem para o email de conclusão",
            tab : {
              survey : "Questionário",
              integration : "Integrações",
              email : "E-mail"
            },
            showNumbering : "Exibir numeração das questões",
            restartPageNumbering : "Reiniciar numeração a cada página",
            participantsCan : "Os participantes podem:",
            editAfterSending : "Editar após o envio",
            viewResultAfterSending : "Visualizar o resultado geral",
            viewResultGroupAfterSending : "Visualizar o resultado por grupo",
            accessOnlyWithLink : "Acessar o questionário somente via e-mail",
            anonymousParticipant: "Permitir participantes anônimos",
            emailMacro : {
              description : "Utilize as macros abaixo para personalizar o email",
              participante : " - será substituído pelo nome do participante (destinatário do email)",
              titulo : " - será substituído pelo título do questionário",
              descricao : " - será substituído pela descrição do questionário",
              link : " - será substituído pelo link de acesso para o questionário",
              linkResultado : " - será substituído pelo link de acesso para o resultado do questionário já respondido",
            },
            inviteEmail : "Email de convite",
            inviteEmailPopover : "Este é o email que será enviado aos participantes dando acesso ao questionário",
            inviteEmailFrom : "Email do remetente",
            inviteEmailFromName : "Nome do remetente",
            inviteEmailSubject : "Assunto",
            conclusionEmail : "Email de conclusão",
            conclusionEmailPopover : "Este é o email que será enviado ao participante após ele responder o questionário",
          },
          surveyList : {
            created: "Criado em: ",
            modified : "Modificado em: ",
            surveyEdit : "Editar Questionário",
            surveyDelete : "Excluir Questionário",
            surveyAccess : "Acessar Questionário",
            surveyView : "Visualizar Questionário",
          }
        },
        results : {
          wait : "Aguarde enquanto calculamos seu resultado.",
          unavailableResult : "Acessar o questionário somente via e-mail",
        }
      }
    }
  }
}

export { messages }
