import api from "./api";

export function signIn({ username, password }) {
  delete api.defaults.headers.Authorization;
  return api.post("/auth", {
    username,
    password,
  });
}

export function signOut() {
  api.delete("/logout");
}
