import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import PageSurveyContext from "../PageSurvey/PageSurveyContext";
import SurveyContext from "../SurveyContext";
import QuestionSample from "./QuestionSample";

function QuestionList() {
  const { surveyData, setSurveyData } = useContext(SurveyContext);
  const { pageSurveyData } = useContext(PageSurveyContext);
  const [questionReverse, setQuestionReverse] = useState(null);

  const reverseDelete = useCallback(
    (question) => {
      const newPageSurveys = surveyData.pageSurveys.map((pageSurvey) => {
        if (question.pageSurvey.id === pageSurvey.id) {
          const newQuestions = pageSurvey.questions;
          newQuestions.splice(question.index, 0, question);
          return { ...pageSurvey, questions: newQuestions };
        } else return pageSurvey;
      });

      setSurveyData({ ...surveyData, pageSurveys: newPageSurveys });
      setQuestionReverse(null);
    },
    [setSurveyData, surveyData]
  );

  useEffect(() => {
    if (surveyData && questionReverse) {
      reverseDelete(questionReverse);
    }
  }, [questionReverse, reverseDelete, surveyData]);

  return pageSurveyData?.questions?.map((question, index) => (
    <QuestionSample
      question={question}
      key={index}
      reverseDelete={setQuestionReverse}
    />
  ));
}

export default memo(QuestionList);
