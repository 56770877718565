import React from "react";
import { Route, Switch } from "react-router-dom";
import SignIn from "../pages/SignIn";

export default function AuthRoutes() {
  return (
    <Switch>
      <Route exact component={SignIn} />
    </Switch>
  );
}
