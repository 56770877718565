import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Icon,
  IconButton,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { memo, useCallback, useContext, useState } from "react";
import Button, { ButtonGroup } from "../../../../components/Button";
import { useAuth } from "../../../../contexts/auth";
import useFetch from "../../../../services/useFetch";
import PageSurveyContext from "../PageSurvey/PageSurveyContext";
import SurveyContext from "../SurveyContext";
import OptionsSample from "./OptionsSample";
import {
  QuestionPaper,
  QuestionContent,
  QuestionActions,
  QuestionText,
} from "./style.js";

// Internacionalização
import { i18n } from '../../../../translate/i18n';

function QuestionSample({ question, reverseDelete }) {
  const { surveyData, setSurveyData, setQuestionData } = useContext(
    SurveyContext
  );
  const { pageSurveyData } = useContext(PageSurveyContext);
  const [questionDelete, setQuestionDelete] = useState(null);

  const { user } = useAuth();
  const api = useFetch();
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = useCallback(() => {
    setQuestionData(question);
  }, [question, setQuestionData]);

  const deleteQuestion = useCallback(
    (question) => {
      api
        .delete(
          `/u/project/${user.projectId}/survey/${surveyData.id}/page/${pageSurveyData.id}/question/${question.id}`
        )
        .then(() => {
          enqueueSnackbar( i18n.t("messages.deleteSucces") , { variant: "success" });
        })
        .catch(() => {
          reverseDelete(question);
          enqueueSnackbar( i18n.t("messages.deleteError") , {
            variant: "error",
          });
        });
    },
    [
      api,
      enqueueSnackbar,
      pageSurveyData.id,
      reverseDelete,
      surveyData.id,
      user.projectId,
    ]
  );

  const handleDelete = useCallback(() => {
    setQuestionDelete(null);
    deleteQuestion(question);
    const newPageSurveys = surveyData.pageSurveys.map((pageSurvey) => {
      if (question.pageSurvey.id === pageSurvey.id) {
        const newQuestions = pageSurvey.questions.filter((q) => {
          return q.id !== question.id;
        });
        return { ...pageSurvey, questions: newQuestions };
      } else return pageSurvey;
    });

    const newSurveyData = { ...surveyData, pageSurveys: newPageSurveys };
    setSurveyData(newSurveyData);
  }, [deleteQuestion, question, setSurveyData, surveyData]);

  const handleConfirmDelete = useCallback((question) => {
    setQuestionDelete(question);
  }, []);

  return (
    <React.Fragment>
      <QuestionPaper>
        <QuestionContent
          onClick={handleClick}
          title={ i18n.t("pages.survey.questionSample.updateComponent") }
        >
          <QuestionText
            dangerouslySetInnerHTML={{ __html: question.description }}
            required={question.required}
          />
          <OptionsSample question={question} />
        </QuestionContent>
        <QuestionActions className="QuestionActions">
          <ButtonGroup size="small" orientation="vertical">
            <IconButton onClick={handleConfirmDelete}>
              <Icon fontSize="small">delete</Icon>
            </IconButton>
            <IconButton disabled={true}>
              <Icon fontSize="small">content_copy</Icon>
            </IconButton>
          </ButtonGroup>
        </QuestionActions>
      </QuestionPaper>
      <Dialog open={!!questionDelete} onClose={() => setQuestionDelete(null)}>
        <DialogTitle>{ i18n.t("messages.confirmation") }</DialogTitle>
        <DialogContent>
          <DialogContentText>
            { i18n.t("messages.deleteConfirm") }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDelete}>{ i18n.t("buttons.yes") }</Button>
          <Button onClick={() => setQuestionDelete(null)}>{ i18n.t("buttons.no") }</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default memo(QuestionSample);
