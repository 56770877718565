import React from "react";
import { Switch, Route } from "react-router-dom";

import Sso from "../pages/Sso/Sso";


export default function QuestRoutes() {

  return (
    <Switch>
      <Route
        exact
        path="/sso/:access_token/:refresh_token/:expires_in/:token_type/:userNameAccount/:userId/:userName/:userProject/:userProjectName"
        component={() => (
          <Sso />
        )}
      />
    </Switch>
  );
} 
