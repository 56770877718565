import React, { useRef } from "react";
import { useAuth } from "../contexts/auth";

import { SnackbarProvider } from "notistack";
import AppRoutes from "../routes/app.routes";
import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";
import AnswerRoutes from "./answer.routes";

const getRoutes = (loading) => {
  if (loading) return <React.Fragment />;

  return (
    <>
      <AppRoutes />
      <AnswerRoutes />
    </>
  );
};

export default function Routes() {
  const { loading } = useAuth();
  const classes = useStyles();
  const backRef = useRef();

  return (
    <SnackbarProvider
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Backdrop ref={backRef} className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {getRoutes(loading)}
    </SnackbarProvider>
  );
}

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
