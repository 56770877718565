import React, { useState, useCallback, forwardRef, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

// Internacionalização
import { i18n } from '../../../translate/i18n';

// API
import useFetch from "../../../services/useFetch";

import { useSnackbar } from "notistack";

import { Button, TextField } from "../../../components";

import { useAuth } from "../../../contexts/auth";

import { Form } from "../../style";

import {
  Grid,
  Slide,
  Dialog,
  AppBar,
  Toolbar,
  Checkbox,
  FormLabel,
  FormGroup,
  IconButton,
  Typography,
  FormControl,
  FormControlLabel,
} from "@material-ui/core";

import {
  Close as CloseIcon,
  Cancel as CancelIcon,
  AddCircle as AddCircleIcon,
} from "@material-ui/icons";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  dialog: {
    top: "64px !important",
    bottom: "0",
    //left: "70px !important",
    right: "0",
  },
  formControl: {
    margin: theme.spacing(1),
  },
  buttons: {
    "& button": {
      marginLeft: theme.spacing(2),
    },
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function FullScreenDialog({ handleSubmit, id }) {
  const classes = useStyles();
  const { user } = useAuth();
  const api = useFetch();
  const history = useHistory();
  let location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const [formId, setFormId] = useState(id);
  const [open, setOpen] = useState(!!formId);
  const [name, setName] = useState("");

  const [required, setRequired] = useState({
    cpfRequired: false,
    identifyRequired: false,
    nameIdentifyRequired: false,
    emailRequired: false,
    phoneRequired: false,
  });

  const [attribute, setAttribute] = useState({
    cpf: false,
    identify: false,
    nameIdentify: false,
    email: false,
    phone: false,
  });

  const { cpf, identify, nameIdentify, email, phone } = attribute;
  const {
    cpfRequired,
    identifyRequired,
    nameIdentifyRequired,
    emailRequired,
    phoneRequired,
  } = required;

  const handleChangeAttribute = (e) => {
    setAttribute({ ...attribute, [e.target.name]: e.target.checked });
    if (!e.target.checked) {
      setRequired({
        ...required,
        [e.target.name + "Required"]: false,
      });
    }
  };

  const handleChangeRequired = (e) => {
    setRequired({ ...required, [e.target.name]: e.target.checked });
  };

  const getForm = useCallback(async () => {
    if (formId) {
      await api
        .get(`/u/project/${user.projectId}/form/${formId}`)
        .then(({ data }) => fillForm(data))
        .catch((error) => {
          if (formId) {
            history.push(location.pathname.replace(`/${formId}`, ""));
            setFormId(null);
          }
          setOpen(false);
          if (error.response && error.response.status === 404) {
            enqueueSnackbar( i18n.t("messages.noRecordsFound") , {
              variant: "error",
            });
          }
        });
      // ;
    } // eslint-disable-next-line
  }, [
    api,
    formId,
    enqueueSnackbar,
    history,
    location.pathname,
    user.projectId,
  ]);

  const fillForm = useCallback((data) => {
    setName(data.name);
    setRequired({
      cpfRequired: data.cpfRequired,
      emailRequired: data.emailRequired,
      phoneRequired: data.phoneRequired,
      identifyRequired: data.identifyRequired,
      nameIdentifyRequired: data.nameIdentifyRequired,
    });
    setAttribute({
      cpf: data.cpf,
      email: data.email,
      phone: data.phone,
      identify: data.identify,
      nameIdentify: data.nameIdentify,
    });
  }, []);

  useEffect(() => {
    getForm();
  }, []); // eslint-disable-line

  function clean() {
    setName("");
    setRequired({
      cpfRequired: false,
      emailRequired: false,
      phoneRequired: false,
      identifyRequired: false,
      nameIdentifyRequired: false,
    });
    setAttribute({
      cpf: false,
      email: false,
      phone: false,
      identify: false,
      nameIdentify: false,
    });
  }

  return (
    <div>
      <Button startIcon={<AddCircleIcon />} onClick={() => setOpen(true)}>
        {i18n.t("buttons.new")}
      </Button>
      <Dialog
        fullScreen
        className={classes.dialog}
        open={open}
        onClose={() => {
          setOpen(false);
          if (formId) {
            history.push(location.pathname.replace(`/${formId}`, ""));
            setFormId(null);
          }
          clean();
        }}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setOpen(false);
                if (formId) {
                  history.push(location.pathname.replace(`/${formId}`, ""));
                  setFormId(null);
                }
                clean();
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              { i18n.t("pages.form.title") }
            </Typography>
            <div className={classes.buttons}>
              <Button
                onClick={() => {
                  handleSubmit
                    .bind({
                      name,
                      formId,
                      required,
                      attribute,
                    })()
                    .then((_response) => {
                      enqueueSnackbar( i18n.t("messages.saveSuccess") , {
                        variant: "success",
                      });
                      setOpen(false);
                      history.push(location.pathname.replace(`/${formId}`, ""));
                      setFormId(null);
                      clean();
                    })
                    .catch((_error) => {
                      enqueueSnackbar( i18n.t("messages.saveError") , {
                        variant: "error",
                      });
                    });
                }}
              >
                { i18n.t("buttons.save") }
              </Button>
              <Button
                onClick={() => {
                  setOpen(false);
                  if (formId) {
                    history.push(location.pathname.replace(`/${formId}`, ""));
                    setFormId(null);
                  }
                  clean();
                }}
                color="secondary"
                startIcon={<CancelIcon />}
              >
                { i18n.t("buttons.cancel") }
              </Button>
            </div>
          </Toolbar>
        </AppBar>
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                onChange={(e) => setName(e.target.value)}
                value={name}
                label={ i18n.t("pages.form.grid.name") }
              />
            </Grid>
          </Grid>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">{ i18n.t("pages.form.form.attribute") }</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={cpf}
                    onChange={handleChangeAttribute}
                    name="cpf"
                  />
                }
                label="CPF"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={identify}
                    onChange={handleChangeAttribute}
                    name="identify"
                    />
                  }
                  label={ i18n.t("pages.form.form.identity") }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={email}
                    onChange={handleChangeAttribute}
                    name="email"
                  />
                }
                label="E-Mail"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={phone}
                    onChange={handleChangeAttribute}
                    name="phone"
                  />
                }
                label={ i18n.t("pages.form.form.phone") }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={nameIdentify}
                    onChange={handleChangeAttribute}
                    name="nameIdentify"
                  />
                }
                label={ i18n.t("pages.form.form.name") }
              />
            </FormGroup>
          </FormControl>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">{ i18n.t("pages.form.form.required") }</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!cpf}
                    checked={cpfRequired}
                    onChange={handleChangeRequired}
                    name="cpfRequired"
                  />
                }
                // label="Obrigatorio"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!identify}
                    checked={identifyRequired}
                    onChange={handleChangeRequired}
                    name="identifyRequired"
                  />
                }
                // label="Obrigatorio"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!email}
                    checked={emailRequired}
                    onChange={handleChangeRequired}
                    name="emailRequired"
                  />
                }
                // label="Obrigatorio"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!phone}
                    checked={phoneRequired}
                    onChange={handleChangeRequired}
                    name="phoneRequired"
                  />
                }
                // label="Obrigatorio"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!nameIdentify}
                    checked={nameIdentifyRequired}
                    onChange={handleChangeRequired}
                    name="nameIdentifyRequired"
                  />
                }
                // label="Obrigatorio"
              />
            </FormGroup>
          </FormControl>
        </Form>
      </Dialog>
    </div>
  );
}
