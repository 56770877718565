import React from "react";
import {
  AppBar, Breadcrumbs,
  Dialog,
  DialogContent,
  Icon,
  IconButton, Link,
  makeStyles,
  Toolbar,
  Typography,
} from "@material-ui/core";

import {i18n} from '../../../../translate/i18n';

import {Button} from "@components/index";
import {AddCircle as AddCircleIcon, NavigateNext} from "@material-ui/icons";

const CampaignDialog = ({ handleClosed, headerAction, pageList, pageActive, setActivePage, children}) => {
  const classes = useStyles();

  const breadcrumbs = (list, index=0, menus=[], parent="") => {
    if ( !list || !pageActive )
      return null;

    const path = pageActive.split(".");
    parent += ( !parent ? "" : "." ).concat(path[index]);
    const lastPath = path.length <= index + 1;
    const current = list.filter(item => item.key === path[index]);
    const menu = current.map(item => lastPath ?
        <Typography key={item.key} color="initial">{i18n.t(item.description)}</Typography> :
        <Link key={item.key}
              underline="hover"
              style={{cursor: "pointer"}}
              color="inherit"
              onClick={(e) => {
                setActivePage(parent);
              }}>{i18n.t(item.description)}</Link>);

    menus.push(menu);

    if ( !lastPath )
      breadcrumbs(current[0].pages, ++index, menus, parent);

    return menus;

  }

  return <Dialog
    fullScreen
    open={true}
    className={classes.dialog}>
      <form>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClosed}>
              <Icon>close</Icon>
            </IconButton>
            <Breadcrumbs separator={<NavigateNext fontSize="small" />}
                         aria-label="breadcrumb"
                         className={classes.title}>
              {
                breadcrumbs(pageList)
              }
            </Breadcrumbs>
            {
              headerAction &&
              <div className="buttons">
                <Button startIcon={<AddCircleIcon/>} onClick={headerAction}>
                  {i18n.t(pageActive === "campaigns.occurrences" ? "buttons.startOccurrence" : "buttons.add")}
                </Button>
              </div>
            }
          </Toolbar>
        </AppBar>

        <DialogContent className={classes.dialogContent}>
          {
            children
          }
        </DialogContent>
      </form>
    </Dialog>
}

const useStyles = makeStyles((theme) => ({
  dialog: {
    top: "64px !important",
    bottom: "0",
    //left: "70px !important",
    right: "0",
  },
  buttons: {
    "& button": {
      marginLeft: theme.spacing(2),
    },
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: "white",
    fontSize: "18px",
  },
  dialogContent: {
    paddingBottom: `${window.innerWidth < 600 ? "24px" : "50px"}`,
  },
}));

export default CampaignDialog;