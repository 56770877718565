import { Hidden, Icon } from "@material-ui/core";
import React, { memo, useContext } from "react";

import {
  ListQuestionType,
  ListItemQuestionType,
  ListItemIconQuestionType,
  ListItemTextQuestionType,
} from "./style.js";

import { useDrag } from "react-dnd";
import SurveyContext from "../SurveyContext.js";

function QuestionType({ item }) {
  const { addQuestion } = useContext(SurveyContext);

  const [{ isDragging }, drag] = useDrag({
    item: { ...item, type: "questionType" },
    end: (questionType, monitor) => {
      const dropResult = monitor.getDropResult();
      if (questionType && dropResult) {
        const { pageSurveyData } = dropResult;
        delete questionType.icon;
        addQuestion(pageSurveyData, questionType);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <ListQuestionType>
      <ListItemQuestionType ref={drag}>
        <ListItemIconQuestionType>
          <Icon>{item.icon}</Icon>
        </ListItemIconQuestionType>
        <Hidden xsDown>
          <ListItemTextQuestionType title={item.description}>
            {item.name}
          </ListItemTextQuestionType>
        </Hidden>
      </ListItemQuestionType>
    </ListQuestionType>
  );
}

export default memo(QuestionType);
