import { createContext } from "react";

const SurveyContext = createContext({
  surveyData: {},
  setSurveyData: () => {},

  questionData: null,
  setQuestionData: () => {},

  addQuestion: () => {},

  surveyId: null,
  setSurveyId: () => {},

  surveyIdCampaigns: null,
  setSurveyIdCampaigns: () => {},
  afterPublish: () => {},
});

export default SurveyContext;
