import React, { useEffect, useState, useCallback } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";

// Internacionalização
import { i18n } from '../../../translate/i18n';

//DevExtreme
import DataGrid, {
  Column,
  Scrolling,
  Sorting,
  Pager,
  Paging
} from "devextreme-react/data-grid";
import { LoadPanel } from "devextreme-react/load-panel";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";

// Material ui core
import {
  Icon,
  Grid,
  Dialog,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";

// Material ui style
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

// Material ui icons
import { 
  //  Delete as DeleteIcon,
  AddCircle as AddCircleIcon 
} from "@material-ui/icons";

// API
import useFetch from "@services/useFetch";

// Components
import Button, { ButtonGroup } from "@components/Button";
import Drawer from "./Drawer";

import { Container, TitleContainer, Title } from "../../style";
import { useAuth } from "@contexts/auth";

const theme = createTheme({
  overrides: {
    MuiDrawer: {
      paper: {
        height: "calc(100% - 64px)",
        top: "initial",
        bottom: "0",
      },
    },
  },
});

export default function Participant() {
  const { user } = useAuth();
  const api = useFetch();
  const history = useHistory();
  let location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const { id } = useParams();

  const [data, setData] = useState([]);
  const [excluding, setExcluding] = useState(false);
  const [participantIdDelete, setParticipantIdDelete] = useState(null);
  const [selectedItemKeys, setSelectedItemKeys] = useState([]);
  const [selectedDeleteConfirm, setSelectedDeleteConfirm] = useState(false);

  const [loadPanelVisible, setLoadPanelVisible] = useState(false);

  const getAll = useCallback(async () => {
    setLoadPanelVisible(true);
    await api
      .get(`/u/project/${user.projectId}/participant`)
      .then(({ data }) => {
        setData(data);
      })
      .catch(() => {
        enqueueSnackbar( i18n.t('messages.onloadError'), {
          variant: "error",
        });
      });
    setLoadPanelVisible(false);
  }, [api, enqueueSnackbar, user.projectId]);

  useEffect(() => {
    getAll();
  }, []); // eslint-disable-line

  async function handleSubmit() {
    const url = `/u/project/${user.projectId}/participant/${this.participantId || ""}`;

    api[this.participantId ? "put" : "post"](url, {
      name: this.name,
      phone: this.phone,
      email: this.email
    });
  }

  const handleEdit = useCallback(
    (id) => {
      history.push(`${location.pathname}/${id}`);
    },
    [history, location.pathname]
  );

  const handleConfirmDelete = useCallback((id) => {
    setParticipantIdDelete(id);
  }, []);

//  const handleConfirmSelectedDelete = useCallback(() => {
//    setSelectedDeleteConfirm(true);
//  }, []);

  const actionButtons = useCallback(
    ({ data }) => {
      return (
        <ButtonGroup color="default" size="small">
          <Button>
            <Icon fontSize="small" onClick={() => handleEdit(data.id)}>
              edit
            </Icon>
          </Button>
          <Button>
            <Icon fontSize="small" onClick={() => handleConfirmDelete(data.id)}>
              delete
            </Icon>
          </Button>
        </ButtonGroup>
      );
    },
    [handleConfirmDelete, handleEdit]
  );

  const handleDelete = useCallback(async () => {
    setExcluding(true);
    api
      .delete(`/u/project/${user.projectId}/participant/${participantIdDelete}`)
      .then((_response) => {
        enqueueSnackbar( i18n.t('messages.deleteSuccess'), {
          variant: "success",
        });
        setParticipantIdDelete(null);
        getAll();
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          enqueueSnackbar( i18n.t('messages.deleteErrorInUse'), {
            variant: "error",
          });
        } else {
          enqueueSnackbar( i18n.t('messages.deleteErrorInUse'), {
            variant: "error",
          });
        }
      })
      .then(() => {
        setExcluding(false);
      });
  }, [api, user.projectId, participantIdDelete, enqueueSnackbar, getAll]);

  const handleDeleteSelected = useCallback(async () => {
    setExcluding(true);
    selectedItemKeys.forEach((participantId) => {
      api
        .delete(`/u/project/${user.projectId}/participant/${participantId}`)
        .then((_response) => {
          enqueueSnackbar( i18n.t('messages.deleteSuccess'), {
            variant: "success",
          });
          setParticipantIdDelete(null);
          getAll();
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            enqueueSnackbar( i18n.t('messages.deleteErrorInUse'), {
                variant: "error",
              }
            );
          } else {
            enqueueSnackbar( i18n.t('messages.deleteErrorInUse'), {
              variant: "error",
            });
          }
        });
    });
    setExcluding(false);
    setSelectedItemKeys([]);
    setSelectedDeleteConfirm(false);
  }, [api, enqueueSnackbar, selectedItemKeys, user.projectId]); // eslint-disable-line

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <Title>
          <TitleContainer>
            <Typography color="textPrimary">
            { i18n.t('pages.participant.title') }
            </Typography>
          </TitleContainer>
          <div className="buttons">
            <Drawer id={id} handleSubmit={handleSubmit} />
            <Button
              // onClick={handleConfirmSelectedDelete}
              startIcon={<AddCircleIcon />}
            >
              { i18n.t('buttons.import') }
            </Button>
          </div>
        </Title>
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          position={{ to: ".dx-datagrid" }}
          onHiding={() => setLoadPanelVisible(false)}
          visible={loadPanelVisible}
          showIndicator={true}
          shading={true}
          showPane={true}
          closeOnOutsideClick={false}
        />
        <Grid item xs={12} sm={12}>
          <DataGrid
            dataSource={data}
            allowColumnResizing={true}
            noDataText={ i18n.t('messages.noRecordsFound') }
            columnResizingMode="widget"
            onSelectedRowKeysChange={(selected) =>
              setSelectedItemKeys(selected)
            }
            keyExpr="id"
            style={{
              maxHeight: "calc(100vh - 150px)",
            }}
            selectedRowKeys={selectedItemKeys}
            wordWrapEnabled={true}
            columnAutoWidth={true}
            showBorders={true}
            showRowLines={true}
            showColumnLines={false}
          >
            <Scrolling rowRenderingMode="virtual" />
            <Paging defaultPageSize={10} /> 
            <Pager
              visible={true}
              allowedPageSizes={[10, 20]}
              displayMode={'full'}
              showPageSizeSelector={true}
              showInfo={true}
              showNavigationButtons={true} />
            <Sorting mode="none" />
            <Column dataField="name" caption={ i18n.t('pages.participant.grid.name') } />
            <Column dataField="phone" caption={ i18n.t('pages.participant.grid.phone') } />
            <Column dataField="email" caption={ i18n.t('pages.participant.grid.email') } />
            <Column cellRender={actionButtons} width={280} alignment="right" />
          </DataGrid>
        </Grid>
      </Container>

      <Dialog
        open={selectedDeleteConfirm}
        onClose={() => setSelectedDeleteConfirm(false)}
      >
        <DialogTitle>{ i18n.t('messages.confirmation') }</DialogTitle>
        <DialogContent>
          <DialogContentText>
            { i18n.t('messages.deleteConfirm') }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteSelected}>
            {!excluding ? i18n.t('buttons.yes') : i18n.t('messages.excluding')}
          </Button>
          <Button onClick={() => setSelectedDeleteConfirm(false)}>{i18n.t('buttons.no')}</Button>
        </DialogActions>
      </Dialog>

      <Dialog 
        open={!!participantIdDelete} 
        onClose={() => setParticipantIdDelete(null)}
      >
        <DialogTitle>{ i18n.t('messages.confirmation') }</DialogTitle>
        <DialogContent>
          <DialogContentText>
            { i18n.t('messages.deleteConfirm') }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDelete}>
            {!excluding ? i18n.t('buttons.yes') : i18n.t('messages.excluding')}
          </Button>
          <Button onClick={() => setParticipantIdDelete(null)}>{i18n.t('buttons.no')}</Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}
