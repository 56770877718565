import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useFetch from "../../services/useFetch";
import Button from "@components/Button";
import Completed from "./components/Completed";
import { handleIntegration } from "./components/Completed";

import { i18n } from '../../translate/i18n';
import { GlobalStyle, Container, Header } from "./style.js";
import Page from "./components/Page";

import AnswerContext from "./AnswerContext";
import Form from "./components/Form";
import {
  Backdrop,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Typography,
  LinearProgress,
  Box
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import Loading from "@components/Loading";

export default function Answer({ preview, reviewonly }) {
  const { surveyKey, participantKey } = useParams();
  const api = useFetch({ isPublic: true });
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [survey, setSurvey] = useState(null);
  const [participant, setParticipant] = useState(null);
  const [unavailable, setUnavailable] = useState(false);
  const [loadingRepaint, setLoadingRepaint] = useState(false);

  const [answers, setAnswers] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageRead, setPageRead] = useState(0);
  const [savingResponses, setSavingResponses] = useState(false);
  const [completedAnswer, setCompletedAnswer] = useState(false);
  const [viewResultOpen, setViewResultOpen] = useState(false);

  const [resultLoading, setResultLoading] = useState(false);
  const [viewResult, setViewResult] = useState(false);

  const [currentProgress, setCurrentProgress] = useState(0);

  const getAnswersOfSurvey = (data) => {
    const answersTemp = [];
    data.pages.forEach(page => {
      page.questions.forEach(question => {
        if ( question.type.hasOptions ) {
          answersTemp.push({
            questionId: question.id,
            options: question.options.map(({index, checked}) => ({ index, checked })),
            children: question.children.map(({id, index, options}) => ({
              id,
              index,
              options: options.map(option => ({ index: option.index, checked: option.checked }))
            }))
          });
        } else {
          answersTemp.push({ questionId: question.id, response: question.response });
        }
      });
    });
    setAnswers(answersTemp);
    setSurvey(data);
    setViewResult( (data.viewResultGroupAfterSending || data.viewResultAfterSending) && data.alreadyAnswered );
    setViewResultOpen(( (data.viewResultGroupAfterSending || data.viewResultAfterSending) && data.alreadyAnswered ) && !reviewonly );
    setLoadingRepaint(false);
  };

  const getAnswerSurvey = useCallback(async(participant) => {
    try {
      const url = `/answer/survey/${preview ? "preview/" : ""}${surveyKey}/${ participant || participantKey || "" }`;
      const { data } = await api.get(url);
      getAnswersOfSurvey(data);

      if (data.surveySent) {
        enqueueSnackbar(
          `${i18n.t("pages.answer.surveySent")}${data.justSeeResponse ? i18n.t("pages.answer.justSeeAnswers") : ""}.`,
          { variant: "info" }
        );
      } else if (data.alreadyAnswered) {
        enqueueSnackbar(
          `${i18n.t("pages.answer.alreadyAnswered")}${data.justSeeResponse && data.surveySent ? i18n.t("pages.answer.justSeeAnswers") : ""}.`,
          { variant: "info" }
        );
      }
      setCurrentProgress(pageIndex * 100 / data.pages.length);
    } catch (error) {
      setUnavailable(true);
    }
  }, [preview, surveyKey, participantKey, api, enqueueSnackbar, getAnswersOfSurvey, pageIndex]);

  useEffect(() => {
    getAnswerSurvey();
  }, []);

  useEffect(() => {
    setParticipant(participantKey);
  }, [participantKey]);

  const reloadParticipant = useCallback((participant) => {
    setLoadingRepaint(true);
    getAnswerSurvey(participant);
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0 });
    setPageRead(old => old < pageIndex ? pageIndex : old);
  }, [pageIndex, setPageRead]);

  const handleSubmit = useCallback((saveFinish) => {
    if ( survey.surveySent && survey.justSeeResponse )
      return;

    let promise = new Promise((resolve) => resolve({ data: {} }));

    if (!preview)
      promise = api.post(`/answer/survey/${surveyKey}/${participant}/${saveFinish}`, { answers });

     if ( saveFinish ) {
      setCurrentProgress(100);
      setSavingResponses(true);
    }

    promise
      .then(() => {
        if ( saveFinish )
          setCompletedAnswer(true);
      })
      .catch(() => enqueueSnackbar(i18n.t("messages.saveAnswersError"), { variant: "warning" }))
      .then(() => setSavingResponses(false));
  }, [
    preview,
    api,
    surveyKey,
    participant,
    answers,
    enqueueSnackbar,
  ]);

  const handlePagination = useCallback((operationPage) => {
    const number = pageIndex + operationPage;
    setPageIndex(number);
    setCurrentProgress((number+1) * 100 / survey.pages.length);

    if ( operationPage > 0 )
      handleSubmit(false);
  }, [pageIndex, setPageIndex, setCurrentProgress, survey, handleSubmit]);

  if (unavailable) {
    return (
      <Dialog open={true}>
        <DialogTitle>{i18n.t("pages.answer.surveyUnavailable")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {i18n.t("pages.answer.surveyUnavailableCurrently")}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  }

  if (!survey) return <div>{i18n.t("messages.loading")}</div>;

  return (
    <AnswerContext.Provider
      value={{
        survey,
        setSurvey,
        preview,
        participant,
        setParticipant,
        savingResponses,
        reloadParticipant,
      }}
    >
      <GlobalStyle />
      <Backdrop className={classes.backdrop} open={loadingRepaint}>
        <CircularProgress color="inherit" />
      </Backdrop>

      { ( !participant || !survey.acceptedTerm ) &&
        <Form preview={preview} />
      }

      <Container maxWidth="md">
        <Header
          background={survey.surveyColorHeaderBackground}
          compact={pageIndex > 0 && pageIndex < survey?.pages.length - 1}>
          {survey.surveyLogo && <img src={survey.surveyLogo} alt="Logo" />}
          <div style={{ color: survey.surveyColorHeaderTitle }} dangerouslySetInnerHTML={{ __html: survey.name }} />
        </Header>
        <Box display="flex" alignItems="center" mt="24px">
          <Box width="100%" mr={1}>
            <LinearProgress variant="determinate" value={currentProgress} />
          </Box>
          <Box minWidth={35}>
            <Typography variant="body2" color="textSecondary">{`${Math.round(
              currentProgress,
            )}%`}</Typography>
          </Box>
        </Box>
        {!completedAnswer ? (
          <Page
            survey={survey}
            answers={answers}
            setAnswers={setAnswers}
            pageIndex={pageIndex}
            pageRead={pageRead}
            justSee={survey.justSeeResponse}
            pagination={handlePagination}
            submit={handleSubmit}
            alreadyAnswered={survey.alreadyAnswered}
            viewResult={viewResult}
            currentProgress={currentProgress}
          />
        ) : (
          <Completed
            viewResult={
              survey.viewResultGroupAfterSending ||
              survey.viewResultAfterSending
            }
            preview={preview}
          />
        )}
      </Container>
      <Loading open={resultLoading}>
        {i18n.t("messages.processing")}
        <br />
        {i18n.t("messages.waitRedirect")}
      </Loading>

      <Dialog open={viewResultOpen}>
        <DialogTitle>{i18n.t("pages.answer.alreadyAnswered")}</DialogTitle>
        <DialogContent>
          <DialogContentText>{i18n.t("pages.answer.wantViewDashboard")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setViewResultOpen(false)}>{i18n.t("buttons.no")}</Button>
          <Button
            disabled={resultLoading}
            onClick={async (e) => {
              setResultLoading(true);

              await handleIntegration(e, api, {
                surveyKey,
                participantKey,
              });
              setResultLoading(false);
            }}
          >
            {!resultLoading ? i18n.t("buttons.yes") : i18n.t("buttons.loading")}
          </Button>
        </DialogActions>
      </Dialog>
    </AnswerContext.Provider>
  );
}

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
