import React, {
  useState,
  useCallback,
  forwardRef,
  useEffect,
  useRef,
} from "react";
import { useHistory, useLocation } from "react-router-dom";

// Internacionalização
import { i18n } from '../../../translate/i18n';

// API
import useFetch from "../../../services/useFetch";

import { useSnackbar } from "notistack";

import { Button, TextField, HtmlEditor } from "../../../components";

import { useAuth } from "../../../contexts/auth";

import { Form } from "../../style";

import {
  Grid,
  Slide,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
} from "@material-ui/core";

import {
  Close as CloseIcon,
  Cancel as CancelIcon,
  AddCircle as AddCircleIcon,
} from "@material-ui/icons";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  dialog: {
    top: "64px !important",
    bottom: "0",
    //left: "70px !important",
    right: "0",
  },
  buttons: {
    "& button": {
      marginLeft: theme.spacing(2),
    },
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function FullScreenDialog({ handleSubmit, id }) {
  const classes = useStyles();
  const { user } = useAuth();
  const api = useFetch();
  const history = useHistory();
  let location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const descriptionRef = useRef(null);

  const [termId, setTermId] = useState(id);
  const [open, setOpen] = useState(!!termId);
  const [name, setName] = useState("");

  const getTerm = useCallback(async () => {
    if (termId) {
      await api
        .get(`/u/project/${user.projectId}/term/${termId}`)
        .then(({ data }) => fillTerm(data))
        .catch((error) => {
          if (termId) {
            history.push(location.pathname.replace(`/${termId}`, ""));
            setTermId(null);
          }
          setOpen(false);
          if (error.response && error.response.status === 404) {
            enqueueSnackbar( i18n.t("messages.noRecordsFound"), {
              variant: "error",
            });
          }
        });
      // ;
    } // eslint-disable-next-line
  }, [
    api,
    termId,
    enqueueSnackbar,
    history,
    location.pathname,
    user.projectId,
  ]);

  const fillTerm = useCallback((data) => {
    setName(data.name);
    descriptionRef.current.instance.option("value", data.description);
  }, []);

  useEffect(() => {
    getTerm();
  }, []); // eslint-disable-line

  function clean() {
    setName("");
    descriptionRef.current?.instance.option("value", "");
  }

  return (
    <div>
      <Button startIcon={<AddCircleIcon />} onClick={() => setOpen(true)}>
        { i18n.t("buttons.new") }
      </Button>
      <Dialog
        fullScreen
        className={classes.dialog}
        open={open}
        onClose={() => {
          setOpen(false);
          if (termId) {
            history.push(location.pathname.replace(`/${termId}`, ""));
            setTermId(null);
          }
          clean();
        }}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setOpen(false);
                if (termId) {
                  history.push(location.pathname.replace(`/${termId}`, ""));
                  setTermId(null);
                }
                clean();
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              { i18n.t("pages.term.title") }
            </Typography>
            <div className={classes.buttons}>
              <Button
                onClick={() => {
                  const description = descriptionRef.current.instance.option()
                    .value;
                  handleSubmit
                    .bind({
                      termId,
                      name,
                      description,
                    })()
                    .then((_response) => {
                      enqueueSnackbar( i18n.t("messages.saveSuccess") , {
                        variant: "success",
                      });
                      setOpen(false);
                      history.push(location.pathname.replace(`/${termId}`, ""));
                      setTermId(null);
                      clean();
                    })
                    .catch((_error) => {
                      console.log(_error);
                      enqueueSnackbar( i18n.t("messages.saveError") , {
                        variant: "error",
                      });
                    });
                }}
              >
                {  i18n.t("buttons.save")  }
              </Button>
              <Button
                onClick={() => {
                  setOpen(false);
                  if (termId) {
                    history.push(location.pathname.replace(`/${termId}`, ""));
                    setTermId(null);
                  }
                  clean();
                }}
                color="secondary"
                startIcon={<CancelIcon />}
              >
                {  i18n.t("buttons.cancel") }
              </Button>
            </div>
          </Toolbar>
        </AppBar>
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                onChange={(e) => setName(e.target.value)}
                value={name}
                label={ i18n.t("pages.form.grid.name") }
              />
            </Grid>
            <Grid item xs={12}>
              <HtmlEditor ref={descriptionRef} height={300} />
            </Grid>
          </Grid>
        </Form>
      </Dialog>
    </div>
  );
}
