import {
  Backdrop,
  Box,
  Breadcrumbs,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  makeStyles,
  MenuItem,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { ChromePicker } from 'react-color';
import FileBase64 from 'react-file-base64';
import PropTypes from "prop-types";
import React, { useState, useEffect, useCallback, useRef } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { TextField, Button, HtmlEditor } from "../../../components";
import {
  BreadcrumbsContainer,
  Panel,
  Form,
  FileUpload,
  UploadedFile,
  ColorPicker,
  FileUploadContainer,
  SurveyDescriptionContainer
} from "./style.js";
import { useAuth } from "../../../contexts/auth";
import useFetch from "../../../services/useFetch";
import SaveIcon from "@material-ui/icons/Save";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import PublishIcon from "@material-ui/icons/Publish";
import ImageIcon from '@material-ui/icons/Image';
import { useSnackbar } from "notistack";
import PageSurveyList from "./PageSurvey/PageSurveyList";

// Internacionalização
import { i18n } from '../../../translate/i18n';

import SurveyContext from "./SurveyContext";

import "./style.css";
import { GlobalStyle } from "./PageSurvey/style";
import SurveyConfig from "./SurveyConfig";
import Campaign from "./Campaigns";

export default function Survey() {
  const { id } = useParams();
  const [surveyId, setSurveyId] = useState(id);
  const [surveyData, setSurveyData] = useState({});
  const [questionData, setQuestionData] = useState(null);
  const [openCampaign, setOpenCampaign] = useState(false);
  const [surveyIdDelete, setSurveyIdDelete] = useState(null);
  const [excluding, setExcluding] = useState(false);
  const [notEmailCreate, setNotEmailCreate] = useState(false);

  const { user } = useAuth();
  const api = useFetch();

  const classes = useStyles();
  const [saving, setSaving] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const currentDate = new Date();
  const [startDate, setStartDate] = useState(
    new Date(currentDate.setDate(currentDate.getDate() + 3))
  );
  const [stopDate, setStopDate] = useState(
    new Date(currentDate.setDate(currentDate.getDate() + 15))
  );

  const [name, setName] = useState("");
  const [categories, setCategories] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [terms, setTerms] = useState([]);
  const [termId, setTermId] = useState("");
  const [formId, setFormId] = useState("");
  const descriptionRef = useRef(null);
  const history = useHistory();
  let location = useLocation();

  const [tabActive, setTabActive] = useState(0);
  const [tabConfigEmailActive, setTabConfigEmailActive] = useState(false);
  const [errorLoading, setErrorLoading] = useState(false);
  const [surveyColorHeaderBackground, setSurveyColorHeaderBackground] = useState('');
  const [surveyColorHeaderTitle, setSurveyColorHeaderTitle] = useState('');
  const [surveyLogo, setSurveyLogo] = useState('');  
  const [forms, setForms] = useState([]);

  const fillSurvey = useCallback((data) => {
    setSurveyData(data);
    setName(data.name);
    setCategoryId(data.categoryId);
    setStartDate(new Date(Date.parse(data.startDate)));
    setStopDate(new Date(Date.parse(data.stopDate)));
    setTermId(data.termId);
    setFormId(data.formId);
    setSurveyColorHeaderBackground(data.surveyColorHeaderBackground ?? '#9998FF');
    setSurveyColorHeaderTitle(data.surveyColorHeaderTitle ?? '#ffffff');
    setSurveyLogo(data.surveyLogo ?? '');
    descriptionRef?.current?.instance?.option("value", data.description);
  }, [descriptionRef]);

  const getForms = useCallback(async () => {
    const { data } = await api.get(`/u/project/${user.projectId}/form`);
    setForms(data);
  }, [api, user.projectId]);

  const getSurvey = useCallback(() => {
    if (surveyId) {
      api
        .get(`/u/project/${user.projectId}/survey/${surveyId}`)
        .then(({ data }) => {
          fillSurvey(data);
        })
        .catch((error) => {
          const status = error?.response?.status;
          if (status === 404) {
            enqueueSnackbar(
              i18n.t("pages.survey.surveyNotFound"),
              {
                variant: "warning",
              }
            );
          } else
            enqueueSnackbar( i18n.t("pages.survey.surveyLoadError"), {
              variant: "error",
            });

          setErrorLoading(true);
        });
    }
  }, [surveyId, api, user.projectId, fillSurvey, enqueueSnackbar]);

  const getCategories = useCallback(() => {
    api
      .get(`/u/project/${user.projectId}/category`)
      .then(({ data }) => {
        setCategories(data);
      })
      .catch(() => {
        enqueueSnackbar( i18n.t("pages.survey.categorysLoadError"), {
          variant: "error",
        });
      });
  }, [api, enqueueSnackbar, user.projectId]);

  const getTerms = useCallback(() => {
    api
      .get(`/u/project/${user.projectId}/term`)
      .then(({ data }) => {
        setTerms(data);
      })
      .catch(() => {
        enqueueSnackbar( i18n.t("pages.survey.termsLoadError"), {
          variant: "error",
        });
      });
  }, [api, enqueueSnackbar, user.projectId]);

  useEffect(() => {
    getSurvey();
    getCategories();
    getTerms();
    getForms();
  }, []);

  const handleVisibility = useCallback(() => {
    preview(surveyData);
  }, [surveyData]);

  const handleConfirmDelete = useCallback(() => {
    setSurveyIdDelete(surveyId);
  }, [surveyId]);

  const handleDelete = useCallback(() => {
    setExcluding(true);
    api
      .delete(`/u/project/${user.projectId}/survey/${surveyIdDelete}`)
      .then((response) => {
        enqueueSnackbar( i18n.t("messages.deleteSuccess"), {
          variant: "success",
        });
        history.push(location.pathname.replace(`/${surveyIdDelete}`, ""));
      })
      .catch((error) => {
        enqueueSnackbar( i18n.t("messages.deleteError"), {
          variant: "error",
        });
      })
      .then(() => {
        setExcluding(false);
      });
  }, [
    api,
    enqueueSnackbar,
    history,
    location.pathname,
    surveyIdDelete,
    user.projectId,
  ]);

  const handleSubmit = useCallback((event) => {
    const description = descriptionRef.current.instance.option().value;
    const url = `/u/project/${user.projectId}/survey/${surveyId || ""}`;

    event.preventDefault();
    setSaving(true);

    api[surveyId ? "put" : "post"](url, {
      name,
      description,
      categoryId,
      termId,
      formId,
      surveyEmailConclusion: surveyData?.surveyEmailConclusion,
      surveyEmailInvite: surveyData?.surveyEmailInvite,
      surveyColorHeaderBackground,
      surveyColorHeaderTitle,
      surveyLogo,
      startDate: toDateJson(startDate),
      stopDate: toDateJson(stopDate)
    })
      .then(({ data }) => {
        enqueueSnackbar( i18n.t("messages.saveSuccess"), { variant: "success" });

        if (!surveyId) {
          history.replace(location.pathname.replace("novo", data.id));
          fillSurvey(data);
          setSurveyId(data.id);
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar( i18n.t("messages.saveError"), {
          variant: "error",
        });
      })
      .then(() => setSaving(false));
  },
  [
    user.projectId,
    surveyId,
    api,
    name,
    categoryId,
    termId,
    startDate,
    stopDate,
    enqueueSnackbar,
    history,
    location.pathname,
    fillSurvey,
    surveyColorHeaderBackground,
    surveyColorHeaderTitle,
    surveyLogo,
    surveyData?.surveyEmailConclusion,
    surveyData?.surveyEmailInvite,
    formId
  ]);

  const addQuestion = useCallback((pageSurvey, questionType) => {
    const question = {
      pageSurvey,
      questionType,
      description: "",
      difficultyId: "",
      groupId: "",
      weight: "1.00",
      required: false,
      options: [],
      children: [], // questões
    };
    if (questionType.hasOptions) {
      if (["radio", "checkbox"].includes(questionType.code)) {
        question.options.push({ id: "", description: "", score: "" });
        question.options.push({ id: "", description: "", score: "" });
      } else if (["grid_radio", "grid_checkbox"].includes(questionType.code)) {
        question.children.push({
          id: "",
          description: "",
          index: 0,
          options: [
            {
              id: "",
              description: "",
              score: "",
              index: 0,
            },
            {
              id: "",
              description: "",
              score: "",
              index: 1,
            },
          ],
        });

        question.children.push({
          id: "",
          description: "",
          index: 1,
          options: [
            {
              id: "",
              description: "",
              score: "",
              index: 0,
            },
            {
              id: "",
              description: "",
              score: "",
              index: 1,
            },
          ],
        });
      }
    }
    setQuestionData(question);
  }, []);

  const afterPublish = useCallback(
    ({ published }) => {
      setSurveyData({ ...surveyData, published });
    },
    [surveyData]
  );

  const handleCampaings = () => {
    if ( !surveyData.surveyEmailInvite || !surveyData.surveyEmailConclusion ) {
      setNotEmailCreate(true);
      setTabConfigEmailActive(true);
    } else {
      setOpenCampaign(true);
    }
  }

  const handleNotEmailCreate = () => {
    setNotEmailCreate(false);
    setTabActive(1);
  }

  const handleChangeTab = (event, newValue) => {
    setTabActive(newValue);
  };

  function handleColorBackgroundChange(color, event) {
    setSurveyColorHeaderBackground(color.hex);
  }

  function handleColorTitleChange(color, event) {
    setSurveyColorHeaderTitle(color.hex);
  }

  function handleGetFile(file) {
    setSurveyLogo(file.base64);
  }

  if (errorLoading) return <React.Fragment />;

  if (surveyId && JSON.stringify(surveyData) === JSON.stringify({})) {
    return (
      <Backdrop className={classes.backdrop} open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <>
      <SurveyContext.Provider
        value={{
          surveyData,
          setSurveyData,
          questionData,
          setQuestionData,
          addQuestion,
          afterPublish,
        }}
      >
        <GlobalStyle />
        <BreadcrumbsContainer>
          <Breadcrumbs className="breadcrumb">
            <Link to="../questionario" color="inherit">
              { i18n.t("pages.survey.titleSingular") }
            </Link>
            <Typography color="textPrimary">
              {surveyId ? i18n.t("buttons.change") : i18n.t("buttons.new") }
            </Typography>
          </Breadcrumbs>
        </BreadcrumbsContainer>
        <Panel>
          <Form onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  label={ i18n.t("pages.survey.form.title") }
                  value={name}
                  onChange={(event) => {
                    setName(event.target.value);
                  }}
                  required={true}
                  inputProps={{ maxLength: "120" }}
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <TextField
                  label={ i18n.t("pages.survey.form.category") }
                  select
                  value={categoryId}
                  onChange={(event) => {
                    setCategoryId(event.target.value);
                  }}
                  required={true}
                >
                  {categories.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <TextField
                  label={ i18n.t("pages.survey.form.term") }
                  select
                  value={termId}
                  onChange={(event) => {
                    setTermId(event.target.value);
                  }}
                  required={true}
                >
                  {terms.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <SurveyDescriptionContainer item xs={12} sm={8} md={8}>
                <Typography color="textSecondary">
                  { i18n.t("pages.survey.form.description") }
                </Typography>
                <HtmlEditor ref={descriptionRef} height={150} name="Nome" />
              </SurveyDescriptionContainer>
              <Grid item xs={12} sm={4} style={{ marginTop: "48px" }}>
                <TextField
                  label={i18n.t("pages.survey.form.formSelect.title")}
                  select
                  value={formId}
                  onChange={(event) => {
                    setFormId(event.target.value);
                  }}
                >
                  <MenuItem>{i18n.t("pages.survey.form.formSelect.select")}</MenuItem>
                  {forms?.map((option, index) => (
                    <MenuItem key={index} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <ColorPicker item xs={12} md={3}>
                <Typography color="textSecondary">
                  { i18n.t("pages.survey.form.backgroundColor") }
                </Typography>
                <ChromePicker
                  color={surveyColorHeaderBackground}
                  onChange={handleColorBackgroundChange}
                  disableAlpha
                />
              </ColorPicker>
              <ColorPicker item xs={12} md={3}>
                <Typography color="textSecondary">
                { i18n.t("pages.survey.form.titleColor") }
                </Typography>
                <ChromePicker
                  color={surveyColorHeaderTitle}
                  onChange={handleColorTitleChange}
                  disableAlpha
                />
              </ColorPicker>
              <FileUploadContainer item xs={12} md={6}>
                <Typography color="textSecondary">
                { i18n.t("pages.survey.form.logo") }
                </Typography>
                <FileUpload>
                  <ImageIcon /><span>{ i18n.t("pages.survey.form.upload") }</span>
                  <FileBase64
                    multiple={false}
                    onDone={handleGetFile}
                  />
                </FileUpload>
                {surveyLogo && <UploadedFile src={surveyLogo} alt={ i18n.t("pages.survey.form.logo") } />}
              </FileUploadContainer>
              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item xs={6} sm={3} md={2}>
                    <Button
                      type="submit"
                      startIcon={<SaveIcon />}
                      disabled={saving}
                      fullWidth
                    >
                      {!saving ? i18n.t("buttons.save") : i18n.t("messages.saving")}
                    </Button>
                  </Grid>
                  {surveyId && (
                    <React.Fragment>
                      <Grid item xs={6} sm={3} md={2}>
                        <Button
                          startIcon={<VisibilityIcon />}
                          onClick={handleVisibility}
                          fullWidth
                        >
                          { i18n.t("buttons.visualize") }
                        </Button>
                      </Grid>
                      <Grid item xs={6} sm={3} md={2}>
                        <Button
                          startIcon={<PublishIcon />}
                          onClick={() => handleCampaings()}
                          fullWidth
                        >
                          { i18n.t("buttons.campaigns") }
                        </Button>
                      </Grid>
                      <Grid item xs={6} sm={3} md={2}>
                        <Button
                          startIcon={<DeleteIcon />}
                          onClick={handleConfirmDelete}
                          color="secondary"
                          fullWidth
                        >
                          { i18n.t("buttons.delete") }
                        </Button>
                      </Grid>
                    </React.Fragment>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </Panel>
        <Dialog open={!!surveyIdDelete} onClose={() => setSurveyIdDelete(null)}>
          <DialogTitle>{ i18n.t("messages.confirmation") }</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Confirma a exclusão do <b>Questionário</b>?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDelete} disabled={excluding}>
              {!excluding ?  i18n.t("buttons.yes")  : i18n.t("messages.excluding") }
            </Button>
            <Button onClick={() => setSurveyIdDelete(null)}>{i18n.t("buttons.no") }</Button>
          </DialogActions>
        </Dialog>

        <Dialog open={notEmailCreate}
                onClose={() => handleNotEmailCreate()}>
          <DialogTitle>{ i18n.t("messages.noRecordsFound") }</DialogTitle>
          <DialogContent>
            <DialogContentText>
              { i18n.t("messages.surveyConfigEmailNotFound")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleNotEmailCreate()}>{i18n.t("buttons.add") }</Button>
          </DialogActions>
        </Dialog>

        {surveyId && (
          <>
            <Paper square>
              <Tabs
                value={tabActive}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleChangeTab}
              >
                <Tab label={ i18n.t("pages.survey.tab.editor") } />
                <Tab label={ i18n.t("pages.survey.tab.config") } />
              </Tabs>
            </Paper>
            <TabPanel value={tabActive} index={0}>
              <PageSurveyList />
            </TabPanel>
            <TabPanel value={tabActive} index={1}>
              <SurveyConfig startTab={tabConfigEmailActive ? 2 : 0} />
            </TabPanel>
            { openCampaign && <Campaign surveyId={surveyId} handleClosed={() => setOpenCampaign(false)} /> }
          </>
        )}
      </SurveyContext.Provider>
    </>
  );
}

export const preview = (survey) => {
  window.open(`/answer/survey/preview/${survey.hash}`);
};

export const access = (survey) => {
  window.open(`/answer/survey/${survey.hash}`);
};

export const getUrl = (survey) => {
  return `${window.origin}/answer/survey/${survey.hash}`;
};

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const toDateJson = (date) => {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toJSON();
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-survey-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box style={{ paddingTop: "24px" }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};