const messages = {
  es : {
    translations : {
      titles : {
        app : "Q4BA",
        appDescription : "Cuestionarios para Business Analytics"
      },
      buttons : {
        add: "Agregar",
        save: "Registrar",
        delete: "Eliminar",
        cancel: "Cancelar",
        close: "Cerrar",
        new: "Nuevo",
        yes: "Sí",
        no: "No",
        file: "Archivo",
        changeProject : "Cambiar proyecto",
        logout : "Salir",
        preview : "Preview",
        import : "Importar",
        continue : "Continuar",
        viewResult : "Ver resultado",
        view : "Visualizar",
        sendAnswer : "Enviar respuestas",
        send : "Mandar",
        next : "Avance",
        change : "Cambiar",
        visualize : "Ver",
        published : "Publicado",
        publish : "Publicar",
        campaigns : "Campañas",
        draft : "Borrador",
        copy : "Copiar",
        update : "Actualizar",
        sendMails : "Enviar Emails",
        hideAll : "Ocultar Todo",
        showAll : "Mostrar Todo",
        select: "Seleccionar",
        startOccurrence: "Iniciar incidente"
      },
      messages : {
        onloadError : "No se pueden cargar los datos",
        saveSuccess : "Guardado con éxito",
        saveError : "No se pudo grabar",
        saveErrorTryAgain : "No se pudo grabar, inténtalo de nuevo",
        saveAnswersError : "No se pudieron guardar las respuestas. Vuelva a intentarlo",
        deleteSuccess : "Eliminado correctamente",
        deleteError : "No se pudo borrar",
        deleteErrorTryAgain : "No se pudo borrar, inténtalo de nuevo",
        deleteErrorInUse : "No se pudo eliminar, el elemento está en uso",
        selectedDeleteConfirm : "¿Confirmar la eliminación de los elementos seleccionados?",
        deleteConfirm : "¿Confirmar la eliminación?",
        excluding : "Excluyendo...",
        loading : "Cargando...",
        processing : "Procesando...",
        publishing : "Publicación...",
        updatingPublish : "Cambio de publicación",
        saving : "Registrando...",
        wait : "Espere...",
        changingProject : "Cambio de proyecto...",
        sending : "Enviando",
        waitRedirect : "Espere y será redirigido.",
        updateSuccess : "Actualizado exitosamente",
        updateError : "No se puede actualizar",
        updateErrorTryAgain : "No se pudo actualizar, inténtalo de nuevo",
        confirmation : "Confirmación",
        changeProjectConfirmation : "¿Estás seguro de que quieres cambiar el proyecto?",
        noRecordsFound : "No se encontraron registros",
        noMoreProjectsFound : "No se ha encontrado ningún proyecto que no sea el actual",
        emailSended : "Email enviado.",
        emailError : "No fue posible enviar el correo electrónico",
        actionNotCompleted : "No se pudo completar esta acción. Vuelve a intentarlo.",
        greaterThanZero : "Ingrese un valor mayor que cero.",
        participantNotAdded : "No se pudo agregar al participante",
        participantDataNotUpdated : "No fue posible actualizar los datos del participante",
        participantExcluded : "Participante eliminado correctamente",
        participantNotExcluded : "No fue posible eliminar al participante",
        campaignNotAdded : "No se pudo agregar la campaña",
        campaignDataNotUpdated : "No fue posible actualizar los datos de la campaña",
        campaignExcluded : "Campaña eliminado correctamente",
        campaignNotExcluded : "No fue posible eliminar la campaña",
        copied : "¡Copiado!",
        confirmSendSelectedEmails : "¿Confirmar envío de correos electrónicos seleccionados?",
        surveyConfigEmailNotFound: "Complete todos los campos en los correos electrónicos de invitación y conclusión",
        initOccurrence: "De verdad quieres empezar un nuevo incidente?"
      },
      menu : {
        projectAdministration : "Administración de proyecto",
        baseParticipants: "Base del Participantes",
        questions: "Pregunta",
        surveys: "Cuestionarios",
      },
      pages : {
        campaign: {
          dialog: {
            list: "Campañas",
            create: "Creado campañas",
            edit: "Editor campaña",
            occurrences: {
              list: "Ocurrencias",
              participants: "Participantes",
            }
          },
          form: {
            name: "Nome la Campaña",
            startdate: "Comenzar",
            stopdate: "Terminar",
            recurrence: "Reaparición",
            schtriggerjobSelected: "Seleccione",
            listSelected: "Seleccionar todo",
            manual: "Generado manualmente"
          },
          list: {
            campaign: "Campaña",
            participants: "Participantes",
            recurrence: "Reaparición",
            startDate: "Comenzar",
            stopDate: "Terminar",
            edit: "Edit campaign",
            occurrence: "Campaign occurrences",
            delete: "Delete campaign",
            copyUrl: "Copy URL link",
            qrcode : "QR Code",
          },
          occurrences: {
            list: {
              occurrence: "Occurrence",
              startDate: "Comenzar",
              stopDate: "Terminar",
              status: "Estado",
              active: "Activo",
              inactive: "Inactivo",
              response: "Respuestas",
              participants: "Participantes",
              occurrenceCreated: "El ocurrencia ha sido creado. Espere a que se procese la lista de participantes.",
            },
            participants: {
              id: "ID",
              name: "Nombre",
              email: "E-mail",
              response: "Contestada",
              phone: "Teléfono",
              cpf: "CPF",
              sent: {
                yes: "Sí",
                no: "Não"
              },
              notify: {
                participant: "Notificar al participante seleccionado",
                participants: "Notificar a los {{count}} participantes seleccionados",
              }
            }
          },
        },
        signin : {
          user: "Usuario",
          password: "Contraseña",
          enter: "Entrar",
          sessionExpired : "La sesión de usuario ha expirado",
          loginAgain : "Esta sesión ya no es válida. Vuelva a iniciar sesión"
        },
        answer : {
          alreadyAnswered : "Ya respondiste este cuestionario",
          justSeeAnswers : ", puedes ver tus respuestas",
          surveyUnavailableCurrently  : "Cuestionario no disponible",
          wantViewDashboard : "¿Le gustaría acceder al panel con el resultado de sus respuestas?",
          completed : {
            surveyCompleted : "Cuestionario completado",
            answerSended : "Tu respuesta ha sido enviada. Gracias.",
            clickAccessResult : "Para acceder al panel de control con el resultado de sus respuestas, haga clic en el enlace de abajo.",
            accessResult : "Accede al resultado"
          },
          form : {
            identification : "Identificación",
            termReaded : "He leído y acepto los",
            term : "Condiciones",
            linkSended : "Se envió un enlace de acceso al cuestionario al correo electrónico registrado.",
            checkSpam : "Si no puede encontrar el correo electrónico en su bandeja de entrada, verifique también su bandeja de entrada de spam.",
            anonymous: "Respuesta anónima"
          }
        },
        category : {
          title : "Categorías",
          grid : {
            name : "Nombre",
            description : "Descripción",
          }
        },
        group : {
          title : "Grupos",
          grid : {
            name : "Nombre",
            description : "Descripción",
          }
        },
        participant : {
          title : "Participantes",
          grid : {
            firstName : "Nombre",
            phone : "Teléfono",
            email : "E-mail"
          }
        },
        difficulty : {
          title : "dificultades de las Preguntas",
          grid : {
            name : "Nombre",
            description : "Descripción",
          }
        },
        habilityGroup : {
          title : "Grupos de Habilidades",
          grid : {
            name : "Nombre",
            description : "Descripción",
          }
        },
        form : {
          title : "Formularios de Identificación",
          grid : {
            name : "Nombre",
          },
          form : {
            identity : "Identificador",
            attribute : "Atributo",
            phone : "Teléfono",
            name : "Nombre",
            required : "Obligatorio"
          }
        },
        term : {
          title : "Condiciones de Consentimiento",
          grid : {
            name : "Nombre",
          },
        },
        survey : {
          title : "Cuestionarios",
          titleSingular : "Cuestionario",
          surveyNotFound : "Cuestionario no existe o no pertenece a este proyecto",
          surveyLoadError : "No se pudo cargar el cuestionario",
          categorysLoadError : "No se pudieron cargar las categorías",
          termsLoadError : "No se pudieron cargar los términos",
          tab : {
            editor : "Editor",
            config : "Ajustes"
          },
          form : {
            newCampaign: "Regilstro del Campaña",
            title : "Título de lo cuestionario",
            name : "Nombre de la Campaña",
            category : "Categoría",
            startDate : "Término de",
            endDate : "Finalizar hasta",
            recurrence: "Reaparición",
            term : "Plazo de aceptación",
            description : "Descripción de lo cuestionario",
            backgroundColor : "Color de fondo del encabezado",
            titleColor : "Color del título del encabezado",
            logo : "Logo",
            upload : "Hacer Upload",
            formSelect : {
              title : "Formulario",
              select : "Seleccione"
            },
            recurrenceSelect : {
              title : "Reaparición",
              select : "Seleccione"
            },
          },
          option : {
            answer : "Respuesta",
            punctuation : "Puntuación",
            addOption : "Agregar opción de respuesta",
            deleteOption : "Eliminar opción de respuesta",
          },
          optionGrid : {
            answerOption : "Opciones de respuesta",
          },
          optionGridColumns : {
            column : "Columna",
            twoColumnsMinimum : "Debes tener al menos dos columnas",
            punctuation : "Puntuación",
            columnText : "Texto de columna",
            addColumnText : "Agregar texto de columna",
            deleteColumnText : "Eliminar texto de columna",
          },
          optionGridLines : {
            twoLinesMinimum : "Debes tener al menos dos líneas",
            line : "Línea",
            columnPunctuation : "Puntuación de columna",
            lineText : "Texto de línea",
            addTextLine : "Agregar texto de línea",
            deleteTextLine : "Eliminar texto de la línea",
          },
          optionList : {
            twoOptionsMinimum : "Debes tener al menos dos opciones de respuesta",
            answerOption : "Opciones de respuesta",
          },
          pageSurvey : {
            noTitle : "Sin titulo",
            page : "Página",
            deleteConfirmation : "¿Confirmar la eliminación de la Página y su contenido?",
            dragging : "Arrastre y suelte un tipo de componente aquí",
          },
          pageSurveyList : {
            noTitle : "Sin titulo",
            unableToSave: "No se pudo guardar la página ",
            unableToDelete: "No se pudo eliminar la página ",
            pageExcluded : "Página eliminada",
            ondePageMinimum : "Debes tener al menos una página",
          },
          question : {
            fillDescription : "Complete el campo Descripción",
            grid : {
              difficulty : "Dificultad",
              group : "Grupo",
              weight : "Peso",
            },
            requireAnswerEachLine : "Requerir una respuesta en cada línea",
            requiredQuestion : "Pregunta obligatoria",
            calculateResult : "Calcular resultado",
            questionDescription : "Pregunta Descripción (opcional)"
          },
          questionSample : {
            updateComponent : "Haga clic para cambiar el componente",
          },
          questionTypeList : {
            components : "Componentes",
            singleChoice : "Opción única",
            multipleChoice : "Opción multiple",
            shortAnswer : "Respuesta corta",
            longAnswer : "Respuesta larga",
            label : "Etiqueta",
            singleChoiceMatrix : "Matriz de opción única",
            multipleChoiceMatrix : "Matriz de opción múltiple",
          },
          surveyConfig : {
            fillInviteEmail : "Complete los campos de correo electrónico, nombre, asunto y mensaje del correo electrónico de invitación",
            fillConclusionEmail : "Complete los campos de correo electrónico, nombre, asunto y mensaje para el correo electrónico de finalización",
            tab : {
              survey : "Examen",
              integration : "Integraciones",
              email : "E-mail"
            },
            showNumbering : "Mostrar numeración de preguntas",
            restartPageNumbering : "Reiniciar la numeración de todas las páginas",
            participantsCan : "Los participantes pueden:",
            editAfterSending : "Editar después del envío",
            viewResultAfterSending : "Ver el resultado general",
            viewResultGroupAfterSending : "Ver el resultado por grupo",
            accessOnlyWithLink : "Acceda al cuestionario solo a través del e-mail",
            anonymousParticipant: "Permitir participantes anónimos",
            emailMacro : {
              description : "Utilice las macros siguientes para personalizar el correo electrónico",
              participante : " - será reemplazado por el nombre del participante (destinatario del correo electrónico)",
              titulo : " - se sustituirá por el título del cuestionario",
              descricao : " - se sustituirá por la descripción del cuestionario",
              link : " - será reemplazado por el enlace de acceso al cuestionario",
              linkResultado : " - será reemplazado por el enlace de acceso al resultado del cuestionario ya respondido",
            },
            inviteEmail : "Correo electrónico de invitación",
            inviteEmailPopover : "Este es el correo electrónico que se enviará a los participantes dando acceso al cuestionario",
            inviteEmailFrom : "Correo electrónico del remitente",
            inviteEmailFromName : "Nombre del remitente",
            inviteEmailSubject : "Tema",
            conclusionEmail : "Correo electrónico de finalización",
            conclusionEmailPopover : "Este es el correo electrónico que se enviará al participante después de que responda el cuestionario",
          },
          surveyList : {
            created: "Creado en: ",
            modified : "Modificado en: ",
            surveyEdit : "Editar cuestionario",
            surveyDelete : "Eliminar cuestionario",
            surveyAccess : "Acceder al cuestionario",
            surveyView : "Ver cuestionario",
          }
        },
        results : {
          wait : "Espere mientras calculamos su resultado.",
          unavailableResult : "Acceda al cuestionario solo a través del e-mail",
        }
      }
    }
  }
}

export { messages }
