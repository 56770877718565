import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Icon,} from "@material-ui/core";
import {i18n} from "../../../../translate/i18n";
import DataGrid, {Column, Sorting} from "devextreme-react/data-grid";
import React, {useState} from "react";
import {ButtonGroup} from "@components/Button";
import {Button} from "@components/index";
import {translateCrun} from "@components/CronGenerator/CronTranslate";
import QRCode from 'qrcode.react';

const CampaignList = ({campaigns, handleEdited, handleOccurrences, handleDeleted}) => {
  const [campaignDelete, setCampaignDelete] = useState(null);
  const [excluding, setExcluding] = useState(false);
  const [isQrModalOpen, setIsQrModalOpen] = useState(false);
  const [qrCodeUrl, setQrCodeUrl] = useState('');


  const deleteCampaign = async (isDelete) => {
    if (isDelete) {
      setExcluding(true)
      await handleDeleted(campaignDelete);
      setExcluding(false);
    }
    setCampaignDelete(null);
  }

  const handleCopyToClipboard = (value) => {    
    const origin = window.location.origin;

    // Construir a URL completa
    var url = origin + "/answer/survey/"+value.hash;
    navigator.clipboard.writeText(url).then(() => {
    }).catch(err => {
      console.error("Erro ao copiar para a área de transferência: ", err);
    });
  }

  const handleQrCode = (value) => {    
      const url = window.location.origin + "/answer/survey/" + value.hash;
      setQrCodeUrl(url);
      setIsQrModalOpen(true);
  }

  const downloadQRCode = () => {
    // Cria um elemento canvas
    const canvas = document.getElementById("qrCodeCanvas");
    const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
  
    // Cria um link para download
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "QRCode.png";
  
    // Anexa o link ao body e clica nele para baixar
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  

  const actionButtons = ({ data }) =>
    <ButtonGroup color="default" size="small">
      <Button title={ i18n.t('pages.campaign.list.edit') }>
        <Icon fontSize="small" onClick={() => handleEdited(data)}>edit</Icon>
      </Button>
      <Button title={ i18n.t('pages.campaign.list.occurrence') }>
        <Icon fontSize="small" onClick={() => handleOccurrences(data)}>event_repeat</Icon>
      </Button>
      <Button title={ i18n.t('pages.campaign.list.copyUrl') }>
        <Icon fontSize="small" onClick={() => handleCopyToClipboard(data)}>link</Icon>
      </Button>
      <Button title={ i18n.t('pages.campaign.list.qrcode') }>
        <Icon fontSize="small" onClick={() => handleQrCode(data)}>qr_code</Icon>
      </Button>
      <Button title={ i18n.t('pages.campaign.list.delete') }>
        <Icon fontSize="small" onClick={() => setCampaignDelete(data)}>delete</Icon>
      </Button>
    </ButtonGroup>;

  return <>
    <DataGrid
      dataSource={campaigns || []}
      allowColumnResizing={true}
      noDataText={ i18n.t('messages.noRecordsFound') }
      columnResizingMode="widget"
      keyExpr="id"
      style={{
        maxHeight: "calc(100vh - 150px)",
        marginTop: "14px",
      }}
      wordWrapEnabled={true}
      columnAutoWidth={true}
      showBorders={true}
      showRowLines={true}
      showColumnLines={true}
    >
      <Sorting  mode="single" />
      <Column dataField="name"
              dataType="string"
              caption={ i18n.t('pages.campaign.list.campaign') } />
      <Column dataField="startdate"
              dataType="date"
              defaultSortOrder="desc"
              allowSorting={true}
              caption={ i18n.t('pages.campaign.list.startDate') }
              width={100}
              alignment="center"/>
      <Column dataField="stopdate"
              dataType="date"
              caption={ i18n.t('pages.campaign.list.stopDate') }
              width={100}
              alignment="center" />
      <Column caption={ i18n.t('pages.campaign.list.recurrence') }
              cellRender={({data: {schtriggerjob: { expressschtrigger}}}) => {
                return expressschtrigger && translateCrun(expressschtrigger);
              }} />
      <Column dataField="participants"
              caption={ i18n.t('pages.campaign.list.participants') }
              width={100} />
      <Column cellRender={actionButtons}
              width={220}
              alignment="center" />
    </DataGrid>

    <Dialog open={!!campaignDelete} onClose={() => deleteCampaign(false)}>
      <DialogTitle>{i18n.t("messages.confirmation")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {i18n.t("messages.deleteConfirm")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => deleteCampaign(true)} disabled={excluding}>
          {!excluding ? i18n.t("buttons.yes") : i18n.t("messages.excluding")}
        </Button>
        <Button onClick={() => deleteCampaign(false)} disabled={excluding}>{i18n.t("buttons.no")}</Button>
      </DialogActions>
    </Dialog>

    <Dialog open={isQrModalOpen} onClose={() => setIsQrModalOpen(false)}>
      <DialogContent>
        <QRCode id="qrCodeCanvas"  value={qrCodeUrl} size={256}/>
      </DialogContent>
      <DialogActions>
      <Button onClick={downloadQRCode}>Download</Button>
        <Button onClick={() => setIsQrModalOpen(false)}>{i18n.t("buttons.close")}</Button>
      </DialogActions>
    </Dialog>

  </>
};

export default CampaignList;