import React from "react";
import { Switch, Route } from "react-router-dom";
import Header from "../components/Header";

import Category from "../pages/Quest/Category";
import Group from "../pages/Quest/Group";
import Participant from "../pages/Quest/Participant";
import ParticipantGroup from "../pages/Quest/ParticipantGroup";
import Difficulty from "../pages/Quest/Difficulty";
import Form from "../pages/Quest/Form";
import Term from "../pages/Quest/Term";
import SurveyList from "../pages/Quest/Survey";
import Survey from "../pages/Quest/Survey/Survey";

export default function QuestRoutes() {
  return (
    <Switch>
      <Route
        exact
        path="/quest/categoria"
        component={() => (
          <Header>
            <Category />
          </Header>
        )}
      />
      <Route
        exact
        path="/quest/categoria/:id"
        component={() => (
          <Header>
            <Category />
          </Header>
        )}
      />
      <Route
        exact
        path="/quest/grupoparticipante"
        component={() => (
          <Header>
            <ParticipantGroup />
          </Header>
        )}
      />
      <Route
        exact
        path="/quest/grupoparticipante/:id"
        component={() => (
          <Header>
            <ParticipantGroup />
          </Header>
        )}
      />
      <Route
        exact
        path="/quest/participante"
        component={() => (
          <Header>
            <Participant />
          </Header>
        )}
      />
      <Route
        exact
        path="/quest/participante/:id"
        component={() => (
          <Header>
            <Participant />
          </Header>
        )}
      />
      <Route
        exact
        path="/quest/dificuldade"
        component={() => (
          <Header>
            <Difficulty />
          </Header>
        )}
      />
      <Route
        exact
        path="/quest/dificuldade/:id"
        component={() => (
          <Header>
            <Difficulty />
          </Header>
        )}
      />
      <Route
        exact
        path="/quest/grupo"
        component={() => (
          <Header>
            <Group />
          </Header>
        )}
      />
      <Route
        exact
        path="/quest/grupo/:id"
        component={() => (
          <Header>
            <Group />
          </Header>
        )}
      />
      <Route
        exact
        path="/quest/formulario"
        component={() => (
          <Header>
            <Form />
          </Header>
        )}
      />
      <Route
        exact
        path="/quest/formulario/:id"
        component={() => (
          <Header>
            <Form />
          </Header>
        )}
      />
      <Route
        exact
        path="/quest/termo"
        component={() => (
          <Header>
            <Term />
          </Header>
        )}
      />
      <Route
        exact
        path="/quest/termo/:id"
        component={() => (
          <Header>
            <Term />
          </Header>
        )}
      />

      <Route
        exact
        path="/quest/questionario"
        component={() => (
          <Header>
            <SurveyList />
          </Header>
        )}
      />
      <Route
        exact
        path="/quest/questionario/novo"
        component={() => (
          <Header>
            <Survey />
          </Header>
        )}
      />
      <Route
        exact
        path="/quest/questionario/:id"
        component={() => (
          <Header>
            <Survey />
          </Header>
        )}
      />
    </Switch>
  );
}
