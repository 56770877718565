import styled from "styled-components";

const ContainerPages = styled.div`
  padding-top: 74px;
  padding-left: 25px;
  padding-right: 25px;
  @media (max-width: 599px) {
    padding-left: 5px;
    padding-right: 5px;
  }
`;

export { ContainerPages };
