import React from "react";

// material ui
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  LinearProgress,
} from "@material-ui/core";

export default function Loading({ children, open, title }) {
  return (
    <Dialog open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{children}</DialogContentText>
        <LinearProgress />
      </DialogContent>
    </Dialog>
  );
}
