import styled from "styled-components";

export const ListQuestionType = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
  margin: 0;
  padding: 0;
  position: relative;
  list-style: none;
`;
export const ListItemQuestionType = styled.div`
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
  display: flex;
  position: relative;
  box-sizing: border-box;
  text-align: left;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  justify-content: flex-start;
  text-decoration: none;
  color: inherit;
  border: 0;
  cursor: pointer;
  margin: 0;
  outline: 0;
  user-select: none;
  border-radius: 0;
  vertical-align: middle;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
  &:hover {
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.04);
  }
`;
export const ListItemIconQuestionType = styled.div`
  color: rgba(0, 0, 0, 0.54);
  display: inline-flex;
  flex-shrink: 0;
  @media (min-width: 600px) {
    min-width: 40px;
  }
`;
export const ListItemTextQuestionType = styled.div`
  flex: 1 1 auto;
  min-width: 0;
  margin-top: 4px;
  margin-bottom: 4px;
`;
