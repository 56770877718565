import React from "react";
import { Switch, Route } from "react-router-dom";
import Header from "../components/Header";

import Setup from "../pages/Setup";
import Client from "../pages/Setup/Client";
import User from "../pages/Setup/User";
import Project from "../pages/Setup/Project";

export default function SetupRoutes() {
  return (
    <Switch>
      <Route
        exact
        path="/"
        component={() => (
          <Header>
            <Setup />
          </Header>
        )}
      />

      <Route
        exact
        path="/setup/cliente"
        component={() => (
          <Header>
            <Client />
          </Header>
        )}
      />
      <Route
        exact
        path="/setup/usuario"
        component={() => (
          <Header>
            <User />
          </Header>
        )}
      />
      <Route
        exact
        path="/setup/projeto"
        component={() => (
          <Header>
            <Project />
          </Header>
        )}
      />
    </Switch>
  );
}
