import React, { useContext, useState } from "react";
import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  Typography,
  Link,
} from "@material-ui/core";
import { TextField, Button } from "../../../../components";
import Term from "../Term";
import useFetch from "../../../../services/useFetch";
import { useHistory, useLocation, useParams } from "react-router-dom";
import AnswerContext from "../../AnswerContext";
import { useSnackbar } from "notistack";
import { i18n } from '../../../../translate/i18n';

export default function Form() {
  const { surveyKey } = useParams();
  const { survey, setSurvey, preview, participant, setParticipant, reloadParticipant } = useContext(AnswerContext);

  const dataInitial = {
    identity: "",
    name: "",
    email: "",
    phone: "",
    cpf: "",
    anonymous: false,
    acceptedTerm: false,
  };
  const [data, setData] = useState(dataInitial);
  const [form, setForm] = useState({
    submit: false,
    emailSender: false,
    openTerm: false
  });

  const api = useFetch({ isPublic: true });
  const history = useHistory();
  let location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmitParticipant = (event) => {
    event.preventDefault();

    if ( form.submit )
      return;

    setForm((old) => ({...old, submit: true}));

    let promise;
    if ( preview ) {
      promise = Promise.resolve({ data: { hash: "1234", newParticipant: true } });

    } else {
      const dataForm = { ...data };
      delete dataForm.acceptedTerm;
      promise = api.post(`/answer/participant/${surveyKey}`, dataForm);
    }

    promise.then(({ data }) => {
      setParticipant(data.hash);

      if ( survey.accessOnlyWithLink && !!data.email && !preview ) {
        enqueueSnackbar(i18n.t("messages.emailSended"), { variant: "success" });
        setForm((old)=> ({...old, emailSender: true}));
      } else {
        history.replace(`${location.pathname}/${data.hash}`);

        if (!data.newParticipant)
            reloadParticipant(data.hash);
      }
    })
    .catch(() => {
      enqueueSnackbar(i18n.t("messages.actionNotCompleted"), { variant: "warning" });
    })
    .then(() => {
      setForm((old)=> ({...old, submit: false}));
    });
  }

  const handleSubmitTerm = (event) => {
    event.preventDefault();

    if ( form.submit )
      return;

    setForm((old) => ({...old, submit: true}));

    const urlTerm = `/answer/participant/${participant}/accept/term`;
    const promise = preview ? Promise.resolve() : api.put(urlTerm);

    promise.then(() => {
      setSurvey((old)=> ({...old, acceptedTerm: true}));
    })
    .catch(() => {
      enqueueSnackbar(i18n.t("messages.actionNotCompleted"), { variant: "warning" });
    })
    .then(() => {
      setForm((old)=> ({...old, submit: false}));
    });
  }

  const validateActions = (name, valid) => {
    let checkbox;

    if ( !!name ) {
      const isAnonymous = name === "anonymous";
      const callback = () => {
        setData((oldData) => ({ ...( isAnonymous ? dataInitial : oldData ), [name]: !oldData[name]}));
      };

      checkbox = <FormControlLabel control={<Checkbox checked={data[name]} onChange={callback}/>}
                                   label={<Typography>
          {
            i18n.t(isAnonymous ? "pages.answer.form.anonymous" : "pages.answer.form.termReaded")
          }
          {
            !isAnonymous && <Link onClick={() => setForm((old) => ({...old, openTerm: true}))}>
              { " ".concat( i18n.t("pages.answer.form.term") ) }
            </Link>
          }
        </Typography>}/>;
    }

    return <Grid container>
      <Grid item xs={12} sm={6} md={8}>
        { checkbox }
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Button type="submit" disabled={!valid} fullWidth>
          {form.submit ? i18n.t("messages.wait") : i18n.t("buttons.continue")}
        </Button>
      </Grid>
    </Grid>
  }

  const validateIdentifier = () => {
    return <Dialog open={true} aria-labelledby="form-dialog-title" fullWidth={true}>
      <DialogTitle id="form-dialog-title">{i18n.t("pages.answer.form.identification")}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmitParticipant}>
          {
            survey.formFields?.map((field, index) => <div key={index}>
              <TextField value={ data[field.code] }
                         onChange={(event) => setData((object) => (
                             {...object, [field.code]: event.target.value}
                         ))}
                         name={field.code}
                         label={field.name}
                         type={field.code === "email" ? "email" : "text"}
                         margin="dense"
                         disabled={data.anonymous}
                         required={field.required && !data.anonymous}/>
            </div>)
          }
          {
            validateActions(survey.anonymousParticipant && "anonymous", !!data.email || data.anonymous)
          }
        </form>
      </DialogContent>
    </Dialog>;
  }

  const validateTerm = () => {
    return <Dialog open={true} aria-labelledby="form-dialog-title" fullWidth={true}>
      <DialogTitle id="form-dialog-title">{i18n.t("pages.answer.form.term")}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmitTerm}>
          { validateActions("acceptedTerm", data.acceptedTerm) }
        </form>
      </DialogContent>
    </Dialog>;
  }
  
  const requiredIdentifier = !participant && ( survey.formFields?.length > 0 || survey.anonymousParticipant );
  const requiredTerm = !requiredIdentifier && !form.emailSender && !survey.acceptedTerm
  return <>
    {
      requiredIdentifier && validateIdentifier()
    }
    {
      requiredTerm && validateTerm()
    }

    <Term open={form.openTerm} close={() => {
      setForm((old) => ({...old, openTerm: false}))
    }} />

    <Dialog open={ form.emailSender } aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{i18n.t("messages.emailSended")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {i18n.t("pages.answer.form.linkSended")}
          <br/>
          {i18n.t("pages.answer.form.checkSpam")}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  </>
}
