import { createContext } from "react";

const AnswerContext = createContext({
  survey: {},
  setSurvey: () => {},
  preview: false,
  participant: null,
  setParticipant: () => {},
  savingResponses: false,
  reloadParticipant: () => {},
});

export default AnswerContext;
