import {ListSubheader, makeStyles} from '@material-ui/core';
import React from 'react';

function HaderMenuItem({children}) {
  const classes = useStyles();

  return (
      <ListSubheader className={classes.menuHeader}>{children}</ListSubheader>
  );
}

const useStyles = makeStyles((theme) => ({
  menuHeader: {
    backgroundColor: "lightgray",
    fontWeight: 900
  }
}));

export default HaderMenuItem;