import React from "react";

import {FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput} from "@material-ui/core";
import {AccessAlarms} from "@material-ui/icons";
import {translateCrun} from "@components/CronGenerator/CronTranslate";

const CrunInput = ({label, value, handleOpen, disabled, required, error}) => {

    return <>
        <FormControl variant="outlined" size="small" required={required} fullWidth >
            <InputLabel htmlFor="outlined-adornment-cron" error={error}>{label}</InputLabel>
            <OutlinedInput id="outlined-adornment-cron"
                           type="text"
                           value={!!value ? translateCrun(value) : ""}
                           label={label}
                           disabled={true}
                           error={error}
                           endAdornment={
                <InputAdornment position="end">
                    <IconButton aria-label="toggle cron visibility"
                                onClick={handleOpen}
                                disabled={disabled}
                                edge="end">
                        <AccessAlarms />
                    </IconButton>
                </InputAdornment>
                           }
            />
        </FormControl>
    </>
}

export default CrunInput;