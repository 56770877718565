import React from "react";

import {ComponentGridOption} from "../style.js";
import GridCheckbox from "./GridCheckbox.jsx";
import GridRadio from "./GridRadio.jsx";

const GridChild = ({ question, answer, handleChanged, justSee, child }) => {
  const RADIO = "grid_radio";
  const CHECKBOX = "grid_checkbox";

  const getValues = () => answer
    .children.find(children => children.id === child.id).options
      .filter(option => option.checked)
      .map(({index}) => index);

  const handleRadio = (option) => {
    question.children.map(children => {
      if (children.id === child.id)
        children.options.map(opt => {
          opt.checked = opt.index === option.index;
          return opt;
        })
      return children;
    })
    handleChanged({...question});
  };

  const handleCheckbox = (option) => {
    question.children.map(children => {
      if (children.id === child.id)
        children.options.map(opt => {
          if ( opt.index === option.index )
            opt.checked = option.checked;
          return opt;
        })
      return children;
    })
    handleChanged({...question});
  };

  const handleOption = (option) => {
    switch (question.type.code) {
      case RADIO:
        handleRadio(option)
        break;
      case CHECKBOX:
        handleCheckbox(option)
        break;
      default:
        new Error("Process not found");
    }
  };

  const propsChildren = {
    justSee,
    handleChanged: handleOption
  };

  const inputs = {
    "grid_radio": (option) => <GridRadio option={option} values={getValues()} {...propsChildren} />,
    "grid_checkbox": (option) => <GridCheckbox option={option} values={getValues()} {...propsChildren} />
  };

  return child.options.map((option, index) =>
    <ComponentGridOption key={index}>
      {
        inputs[question.type.code](option)
      }
    </ComponentGridOption>
  );
};

export default GridChild;
