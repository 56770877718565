import axios from "axios";

// Ambiente Local
//const baseURL = "http://localhost:5555/api/quest";
//const baseURL = "https://q4ba.ba2edu.com/api/quest";
//const baseURL = "https://web1.ceta.ceo/api/quest";
// Ambiente homologação / Produção
const baseURL = "/api/quest";
export { baseURL };

const api = axios.create({
  baseURL: baseURL,
  validateStatus: function (status) {
    return status >= 200 && status < 300; // default
  },
});

export default api;
