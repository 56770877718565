import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Grid,
  Icon,
  useMediaQuery,
} from "@material-ui/core";
import Button, { ButtonGroup } from "../../../../components/Button";

// Internacionalização
import { i18n } from '../../../../translate/i18n';

import PageSurvey from "./PageSurvey";
import useFetch from "../../../../services/useFetch";
import { useAuth } from "../../../../contexts/auth";
import { useSnackbar } from "notistack";

import SurveyContext from "../SurveyContext";
import QuestionTypeList from "../QuestionType/QuestionTypeList";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Question from "../Question/Question";

function PageSurveyList() {
  const { surveyData, setSurveyData } = useContext(SurveyContext);
  const [newPageSurvey, setNewPageSurvey] = useState(null);
  const [savingPage, setSavingPage] = useState(false);
  const [excludingPage, setExcludingPage] = useState(false);
  const [pageReverse, setPageReverse] = useState(null);
  const mobile = useMediaQuery("(max-width:599px)");

  const { user } = useAuth();
  const api = useFetch();
  const { enqueueSnackbar } = useSnackbar();

  const pageSurveyListRef = useRef(null);
  const buttonsPagesListRef = useRef(null);
  const questionTypeListRef = useRef(null);
  const gcolQuestionTypeRef = useRef(null);

  const savePageSurvey = useCallback(
    (page, fnComplete) => {
      setNewPageSurvey(null);

      const url = `/u/project/${user.projectId}/survey/${page.surveyId}/page/${
        page.id ? page.id : ""
      }`;
      api[page.id ? "put" : "post"](url, page)
        .then(({ data }) => {
          const newPageSurveys = surveyData.pageSurveys.map(function (p) {
            if (p.pageNumber === this.pageNumber) return this;
            else return p;
          }, data);

          setSurveyData({ ...surveyData, pageSurveys: newPageSurveys });
        })
        .catch((error) => {
          enqueueSnackbar(
            `${i18n.t("pages.survey.pageSurveyList.unableToSave")} ${page.pageNumber}`,
            { variant: "error" }
          );
        })
        .then(() => {
          setSavingPage(false);
          if (typeof fnComplete === "function") fnComplete();
        });
    },
    [api, enqueueSnackbar, setSurveyData, surveyData, user.projectId]
  );

  useEffect(() => {
    if (!!newPageSurvey) {
      savePageSurvey(newPageSurvey);
      window.scrollTo({ top: document.body.offsetHeight, behavior: "smooth" });
    }

  }, [surveyData, newPageSurvey, savePageSurvey]);

  const handleAddPage = useCallback(() => {
    setSavingPage(true);

    const pageSurvey = {
      surveyId: surveyData.id,
      pageNumber: surveyData.pageSurveys.length + 1,
      title: i18n.t("pages.survey.pageSurveyList.noTitle"),
      questions: [],
    };

    setSurveyData({
      ...surveyData,
      pageSurveys: [...surveyData.pageSurveys, pageSurvey],
    });
    setNewPageSurvey(pageSurvey);
  }, [setSurveyData, surveyData]);

  const reverseDelete = useCallback(
    (page) => {
      const newPageSurveys = surveyData.pageSurveys;
      newPageSurveys.splice(page.pageNumber - 1, 0, page);

      setSurveyData({
        ...surveyData,
        pageSurveys: newPageSurveys.map((p, index) => {
          return { ...p, pageNumber: index + 1 };
        }),
      });

      setPageReverse(null);
    },
    [setSurveyData, surveyData]
  );

  useEffect(() => {
    if (surveyData && pageReverse) {
      reverseDelete(pageReverse);
    }
  }, [pageReverse, reverseDelete, surveyData]);

  const daletePageSurvey = useCallback(
    (page) => {
      api
        .delete(
          `/u/project/${user.projectId}/survey/${page.surveyId}/page/${page.id}`,
          page
        )
        .then(() => {
          enqueueSnackbar(i18n.t("pages.survey.pageSurveyList.pageExcluded"), { variant: "success" });
        })
        .catch(() => {
          setPageReverse(page);
          enqueueSnackbar(
            `${i18n.t("pages.survey.pageSurveyList.unableToDelete")} ${page.pageNumber}`,
            { variant: "error" }
          );
        })
        .then(() => setExcludingPage(false));
    },
    [api, enqueueSnackbar, user.projectId]
  );

  const handleDeletePage = useCallback(
    (page) => {
      if (surveyData.pageSurveys.length === 1) {
        enqueueSnackbar(i18n.t("pages.survey.pageSurveyList.ondePageMinimum"), {
          variant: "warning",
        });
        setExcludingPage(false);
      } else {
        daletePageSurvey(page);

        const newPageSurveys = surveyData.pageSurveys
          .filter((p) => {
            return p.pageNumber !== page.pageNumber;
          })
          .map((p, index) => {
            return { ...p, pageNumber: index + 1 };
          });

        setSurveyData({ ...surveyData, pageSurveys: newPageSurveys });
      }
    },
    [daletePageSurvey, enqueueSnackbar, setSurveyData, surveyData]
  );

  useEffect(() => {
    const header = document.getElementById("AppBarHeader");
    const top = header.offsetHeight + 10;

    window.scrollWindow = function () {
      const offsetTop = pageSurveyListRef.current.getBoundingClientRect().top;

      if (mobile) {
        if (offsetTop < window.innerHeight - 200) {
          questionTypeListRef.current.classList.add(
            "PageSurveyList-TypeList-Sticky-Mobile"
          );
          buttonsPagesListRef.current.classList.add(
            "PageSurveyList-BtnPages-Sticky-Mobile"
          );
        } else {
          questionTypeListRef.current.classList.remove(
            "PageSurveyList-TypeList-Sticky-Mobile"
          );
          buttonsPagesListRef.current.classList.remove(
            "PageSurveyList-BtnPages-Sticky-Mobile"
          );
        }
      } else {
        if (offsetTop <= top) {
          questionTypeListRef.current.style.top = `${top}px`;
          buttonsPagesListRef.current.style.top = `${top}px`;

          const colStyle = getComputedStyle(gcolQuestionTypeRef.current);
          const colPaddingLeft = parseInt(
            colStyle.getPropertyValue("padding-left") || 0
          );
          const colPaddingRight = parseInt(
            colStyle.getPropertyValue("padding-right") || 0
          );

          const typeListStyle = getComputedStyle(questionTypeListRef.current);
          const typeListPaddingLeft = parseInt(
            typeListStyle.getPropertyValue("padding-left") || 0
          );
          const typeListPaddingRight = parseInt(
            typeListStyle.getPropertyValue("padding-right") || 0
          );

          const typeListWidth =
            gcolQuestionTypeRef.current.offsetWidth -
            (colPaddingLeft +
              colPaddingRight +
              typeListPaddingLeft +
              typeListPaddingRight);

          questionTypeListRef.current.style.width = `${typeListWidth}px`;

          questionTypeListRef.current.classList.add(
            "PageSurveyList-Element-Sticky"
          );
          buttonsPagesListRef.current.classList.add(
            "PageSurveyList-Element-Sticky"
          );
        } else {
          questionTypeListRef.current.style.width = "";
          questionTypeListRef.current.classList.remove(
            "PageSurveyList-Element-Sticky"
          );
          buttonsPagesListRef.current.classList.remove(
            "PageSurveyList-Element-Sticky"
          );
        }
      }
    };

    window.addEventListener("scroll", window.scrollWindow);

    return function cleanup() {
      window.removeEventListener("scroll", window.scrollWindow);
    };
  }, [mobile]);

  const handleNavPage = useCallback((index) => {
    const page = document.getElementById(`page${index}`);
    const header = document.getElementById("AppBarHeader");
    const diff = header.offsetHeight + 10;
    window.scrollTo({ top: page.offsetTop - diff, behavior: "smooth" });
  }, []);

  return (
    <DndProvider backend={HTML5Backend}>
      <Grid container spacing={1}>
        {mobile ? (
          <React.Fragment />
        ) : (
          <Grid item sm={3} ref={gcolQuestionTypeRef}>
            <QuestionTypeList ref={questionTypeListRef} />
          </Grid>
        )}

        <Grid item xs={12} sm ref={pageSurveyListRef}>
          {surveyData.pageSurveys?.map((page, index) => {
            return (
              <PageSurvey
                key={index}
                index={index}
                pageSurveyData={page}
                savePageSurvey={savePageSurvey}
                deletePageSurvey={handleDeletePage}
                excludingPage={excludingPage}
                setExcludingPage={setExcludingPage}
              />
            );
          })}
        </Grid>
        <Grid item sm={1}>
          {!mobile ? (
            <React.Fragment />
          ) : (
            <QuestionTypeList ref={questionTypeListRef} />
          )}
          <ButtonGroup
            orientation={mobile ? "horizontal" : "vertical"}
            color="primary"
            variant="contained"
            className="PageSurveyList-BtnPages"
            innerRef={buttonsPagesListRef}
          >
            {mobile && (
              <Button onClick={handleAddPage} disabled={savingPage}>
                <Icon>add</Icon>
              </Button>
            )}

            {surveyData.pageSurveys?.map((page, index) => {
              return (
                <Button onClick={() => handleNavPage(index)} key={index}>
                  P{page.pageNumber}
                </Button>
              );
            })}
            {!mobile && (
              <Button onClick={handleAddPage} disabled={savingPage}>
                <Icon>add</Icon>
              </Button>
            )}
          </ButtonGroup>
        </Grid>
      </Grid>
      <Question />
    </DndProvider>
  );
}

export default memo(PageSurveyList);