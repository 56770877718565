import React, { memo, useCallback, useContext, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  makeStyles,
  Tab,
  Tabs,
  Typography
} from "@material-ui/core";
import SurveyContext from "./SurveyContext";
import SaveIcon from "@material-ui/icons/Save";
import { Button } from "@components/";
import useFetch from "@services/useFetch";
import { useAuth } from "@contexts/auth";
import { useSnackbar } from "notistack";
import { HtmlEditor } from '../../../components';

// Internacionalização
import { i18n } from '../../../translate/i18n';

import { Info } from './style';
import TextField from "@components/TextField/TextField";
import SimplePopover from "src/pages/Answer/components/Component/SimplePopover/SimplePopover";

function SurveyConfig({startTab = 0}) {
  const { surveyData, setSurveyData } = useContext(SurveyContext);

  const classes = useStyles();
  const { user } = useAuth();
  const api = useFetch();
  const { enqueueSnackbar } = useSnackbar();
  const [tabActive, setTabActive] = useState(startTab);
  const [saving, setSaving] = useState(false);

  const surveyEmailInviteEmailFromRef = useRef(surveyData.surveyEmailInvite?.emailFrom);
  const surveyEmailInviteNameFromRef = useRef(surveyData.surveyEmailInvite?.nameFrom);
  const surveyEmailInviteSubjectRef = useRef(surveyData.surveyEmailInvite?.subject);
  const surveyEmailInviteBodyRef = useRef(null);

  const surveyEmailConslusionEmailFromRef = useRef(surveyData.surveyEmailConclusion?.emailFrom);
  const surveyEmailConslusionNameFromRef = useRef(surveyData.surveyEmailConclusion?.nameFrom);
  const surveyEmailConslusionSubjectRef = useRef(surveyData.surveyEmailConclusion?.subject);
  const surveyEmailConslusionBodyRef = useRef(null);

  const handleChangeTab = useCallback((e, newTab) => {
    setTabActive(newTab);
  }, []);

  const handleChange = useCallback(({ name, value }) => {
    if ( name === 'anonymousParticipant' && value === true ) {
      setSurveyData({
        ...surveyData,
        config: { ...surveyData.config, 'accessOnlyWithLink': false, [name]: value  }
      });
    } else if ( name === 'accessOnlyWithLink' && value === true ) {
      setSurveyData({
        ...surveyData,
        config: { ...surveyData.config, 'anonymousParticipant': false, [name]: value  }
      });
    } else {      
      setSurveyData({
        ...surveyData,
        config: { ...surveyData.config, [name]: value  }
      });
    }
  }, [setSurveyData, surveyData]);

  const handleTabSurveyFormSubmit = useCallback((e) => {
    e.preventDefault();

    setSaving(true);

    api.put(`/u/project/${user.projectId}/survey/${surveyData.id}/config`, surveyData.config)
      .then(({ data }) => {
        enqueueSnackbar(i18n.t("messages.saveSuccess"), { variant: "success" });
      })
      .catch(({ error }) => {
        enqueueSnackbar(i18n.t("messages.saveError"), { variant: "error" });
      })
      .then(() => setSaving(false));
  }, [api, enqueueSnackbar, surveyData.config, surveyData.id, user.projectId]);

  const getSurveyEmail = (isInvite) => {
    return {
      emailConfigId: isInvite ? 1 : 2,
      emailFrom: ( isInvite ? surveyEmailInviteEmailFromRef : surveyEmailConslusionEmailFromRef ).current,
      nameFrom: ( isInvite ? surveyEmailInviteNameFromRef : surveyEmailConslusionNameFromRef ).current,
      subject: ( isInvite ? surveyEmailInviteSubjectRef : surveyEmailConslusionSubjectRef ).current,
      body: ( isInvite ? surveyEmailInviteBodyRef : surveyEmailConslusionBodyRef ).current.instance.option().value
    };
  }

  const requiredSurveyEmail = (dataEmail) => {
    const required = Object.entries(dataEmail).filter(([key, value]) => !value).length > 0;

    if ( required )
      enqueueSnackbar(
          i18n.t(`pages.survey.surveyConfig.fill${ dataEmail.emailConfigId == 1 ? 'Invite' : 'Conclusion' }Email`),
          { variant: 'warning' }
      );

    return required;
  }

  async function handleTabEmailFormSubmit(event) {
    event.preventDefault();

    const emailInvite = getSurveyEmail(true);
    const emailConclusion = getSurveyEmail(false);

    if ( requiredSurveyEmail(emailInvite) || requiredSurveyEmail(emailConclusion) )
      return;

    const isEdit = !!surveyData.surveyEmailInvite;
    const url = `/u/project/${user.projectId}/survey/${surveyData.id}/email`;
    const httpMethod = isEdit ? 'put' : 'post';

    const surveyEmailPromises = [
      api[httpMethod](isEdit ? url.concat("/1") : url, emailInvite),
      api[httpMethod](isEdit ? url.concat("/2") : url, emailConclusion),
    ];

    setSaving(true);

    const results = await Promise.all(surveyEmailPromises);
    results.forEach(result => {
      const resultInvite = result.data.emailConfigId === 1;
      setSurveyData({ ...surveyData, [ resultInvite ? 'surveyEmailInvite' : 'surveyEmailConclusion']: result.data });
    });
    enqueueSnackbar(i18n.t("messages.saveSuccess"), { variant: "success" });
    setSaving(false);
  }

  return (
    <div>
      <div className={classes.root}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={tabActive}
          onChange={handleChangeTab}
          aria-label="Vertical tabs survey config"
          className={classes.tabs}
        >
          <Tab label={i18n.t("pages.survey.surveyConfig.tab.survey")} {...a11yProps(0)} />
          <Tab label={i18n.t("pages.survey.surveyConfig.tab.integration")} {...a11yProps(1)} />
          <Tab label={i18n.t("pages.survey.surveyConfig.tab.email")} {...a11yProps(2)} />
        </Tabs>
        <TabPanel value={tabActive} index={0}>
          <Grid>
            <form onSubmit={handleTabSurveyFormSubmit}>
              <Grid item xs={12}>
                <FormControl component="fieldset" className={classes.formControl}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={surveyData.config.showNumbering}
                          onChange={(e) =>
                            handleChange({
                              name: e.target.name,
                              value: e.target.checked,
                            })
                          }
                          name="showNumbering"
                        />
                      }
                      label={i18n.t("pages.survey.surveyConfig.showNumbering")}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={surveyData.config.restartPageNumbering}
                          disabled={!surveyData.config.showNumbering}
                          onChange={(e) =>
                            handleChange({
                              name: e.target.name,
                              value: e.target.checked,
                            })
                          }
                          name="restartPageNumbering"
                        />
                      }
                      label={i18n.t("pages.survey.surveyConfig.restartPageNumbering")}
                    />
                  </FormGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} className={classes.sepatator}>
                <FormControl component="fieldset" className={classes.formControl}>
                  <FormLabel
                    component="legend"
                    focused={false}
                    className={classes.legend}
                  >
                    {i18n.t("pages.survey.surveyConfig.participantsCan")}
                  </FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={surveyData.config.editAfterSending}
                          onChange={(e) =>
                            handleChange({
                              name: e.target.name,
                              value: e.target.checked,
                            })
                          }
                          name="editAfterSending"
                        />
                      }
                      label={i18n.t("pages.survey.surveyConfig.editAfterSending")}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={surveyData.config.viewResultAfterSending}
                          onChange={(e) =>
                            handleChange({
                              name: e.target.name,
                              value: e.target.checked,
                            })
                          }
                          name="viewResultAfterSending"
                        />
                      }
                      label={i18n.t("pages.survey.surveyConfig.viewResultAfterSending")}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={surveyData.config.viewResultGroupAfterSending}
                          onChange={(e) =>
                            handleChange({
                              name: e.target.name,
                              value: e.target.checked,
                            })
                          }
                          name="viewResultGroupAfterSending"
                        />
                      }
                      label={i18n.t("pages.survey.surveyConfig.viewResultGroupAfterSending")}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={surveyData.config.accessOnlyWithLink}
                          onChange={(e) =>
                            handleChange({
                              name: e.target.name,
                              value: e.target.checked,
                            })
                          }
                          name="accessOnlyWithLink"
                        />
                      }
                      label={i18n.t("pages.survey.surveyConfig.accessOnlyWithLink")}
                    />
                    <FormControlLabel label={i18n.t("pages.survey.surveyConfig.anonymousParticipant")}
                                      control={
                      <Checkbox checked={surveyData.config.anonymousParticipant}
                                onChange={(e) =>
                                  handleChange({ name: e.target.name, value: e.target.checked })
                                }
                                name="anonymousParticipant"
                          />
                        }
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} className={classes.sepatator}>
                <Button type="submit" startIcon={<SaveIcon />} disabled={saving}>
                {i18n.t("buttons.save")}
                </Button>
              </Grid>
            </form>
          </Grid>
        </TabPanel>
        <TabPanel value={tabActive} index={1}></TabPanel>
        <TabPanel value={tabActive} index={2}>
          <Grid>
            <Box mb="16px">
              <Info>
                {/* <p>Você pode usar os <i>placeholders</i> abaixo para a elaboração do email:</p> */}
                <p>{i18n.t("pages.survey.surveyConfig.emailMacro.description")}</p>
                <br/>
                <p className="bold"><strong>##PARTICIPANTE##</strong>{i18n.t("pages.survey.surveyConfig.emailMacro.participante")}</p>
                <p className="bold"><strong>##TITULO##</strong>{i18n.t("pages.survey.surveyConfig.emailMacro.titulo")}</p>
                <p className="bold"><strong>##DESCRICAO##</strong>{i18n.t("pages.survey.surveyConfig.emailMacro.descricao")}</p>
                <p className="bold"><strong>##LINK##</strong>{i18n.t("pages.survey.surveyConfig.emailMacro.link")}</p>
                <p className="bold"><strong>##LINK_RESULTADO##</strong>{i18n.t("pages.survey.surveyConfig.emailMacro.linkResultado")}</p>
              </Info>
            </Box>
            <Box mb="24px" className={classes.sepatator}/>
            <form onSubmit={handleTabEmailFormSubmit}>
              <Grid item xs={12}>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <Typography variant="body1">
                    {i18n.t("pages.survey.surveyConfig.inviteEmail")}
                  </Typography>
                  <SimplePopover
                    content={i18n.t("pages.survey.surveyConfig.inviteEmailPopover")}
                  />
                </Box>
                <Box mb="24px" className={classes.sepatator}/>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={12}>
                    <TextField
                      label={i18n.t("pages.survey.surveyConfig.inviteEmailFrom")}
                      name="surveyemailinviteemailfrom"
                      defaultValue={surveyEmailInviteEmailFromRef.current}
                      onChange={event => surveyEmailInviteEmailFromRef.current = event.target.value}
                      type="email"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      label={i18n.t("pages.survey.surveyConfig.inviteEmailFromName")}
                      name="surveyemailinvitenamefrom"
                      defaultValue={surveyEmailInviteNameFromRef.current}
                      onChange={event => surveyEmailInviteNameFromRef.current = event.target.value}
                      type="text"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      label={i18n.t("pages.survey.surveyConfig.inviteEmailSubject")}
                      name="surveyemailinvitesubject"
                      defaultValue={surveyEmailInviteSubjectRef.current}
                      onChange={event => surveyEmailInviteSubjectRef.current = event.target.value}
                      type="text"
                    />
                  </Grid>
                </Grid>
                <Box mt="24px">
                  <HtmlEditor
                    ref={surveyEmailInviteBodyRef}
                    defaultValue={surveyData.surveyEmailInvite?.body}
                    height={400}
                    name="surveyemailinvitebody"
                  />
                </Box>
              </Grid>
              <Box mt="56px"/>
              <Grid item xs={12}>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <Typography variant="body1">
                  {i18n.t("pages.survey.surveyConfig.conclusionEmail")}
                  </Typography>
                  <SimplePopover
                    content={i18n.t("pages.survey.surveyConfig.conclusionEmailPopover")}
                  />
                </Box>
                <Box mb="24px" className={classes.sepatator}/>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={12}>
                    <TextField
                      label={i18n.t("pages.survey.surveyConfig.inviteEmailFrom")}
                      name="surveyemailconclusionemailfrom"
                      defaultValue={surveyEmailConslusionEmailFromRef.current}
                      onChange={event => surveyEmailConslusionEmailFromRef.current = event.target.value}
                      type="email"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      label={i18n.t("pages.survey.surveyConfig.inviteEmailFromName")}
                      name="surveyemailconclusionnamefrom"
                      defaultValue={surveyEmailConslusionNameFromRef.current}
                      onChange={event => surveyEmailConslusionNameFromRef.current = event.target.value}
                      type="text"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      label={i18n.t("pages.survey.surveyConfig.inviteEmailSubject")}
                      name="surveyemailconclusionsubject"
                      defaultValue={surveyEmailConslusionSubjectRef.current}
                      onChange={event => surveyEmailConslusionSubjectRef.current = event.target.value}
                      type="text"
                    />
                  </Grid>
                </Grid>
                <Box mt="24px">
                  <HtmlEditor
                    ref={surveyEmailConslusionBodyRef}
                    defaultValue={surveyData.surveyEmailConclusion?.body}
                    height={400}
                    name="surveyemailconclusionbody"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} className={classes.sepatator}>
                <Button type="submit" startIcon={<SaveIcon />} disabled={saving}>
                  {!saving ? i18n.t("buttons.save") : i18n.t("messages.saving") }
                </Button>
              </Grid>
            </form>
          </Grid>
        </TabPanel>
      </div>
    </div>
  );
}

export default memo(SurveyConfig);

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-survey-config-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{ flexGrow: 1 }}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows["2"],
    display: "flex",
    minHeight: 450,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  formControl: {},
  legend: {
    color: theme.palette.text.primary,
    marginBottom: "20px",
    "&:focus": {
      color: theme.palette.text.primary,
    },
  },
  sepatator: {
    borderTop: `1px solid ${theme.palette.divider}`,
    paddingTop: "12px"
  },
}));
