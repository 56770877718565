import styled from "styled-components";
import { Box } from "@material-ui/core";

export const PageContent = styled(Box)`
  padding-top: 24px;
  padding-bottom: 24px;
`;
export const PageTitle = styled(Box)`
  padding-left: 12px;
  padding-bottom: 24px;
  color: #3f51b5;
  font-size: 21px;
`;
export const PageComponents = styled(Box)``;
export const PageActions = styled(Box)``;
