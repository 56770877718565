import React from "react";
import { Switch, Route } from "react-router-dom";
import Answer from "../pages/Answer";
import Results from "../pages/Results";

export default function AnswerRoutes() {
  return (
    <Switch>
      <Route
        exact
        path="/answer/survey/preview/:surveyKey/:participantKey?"
        component={() => <Answer preview={true} />}
      />
      <Route
        exact
        path="/answer/survey/:surveyKey/:participantKey/reviewonly"
        component={() => <Answer reviewonly={true} />}
      />
      <Route
        exact
        path="/answer/survey/:surveyKey/:participantKey?"
        component={() => <Answer />}
      />
      <Route
        exact
        path="/answer/result/:surveyKey/:participantKey"
        component={() => <Results />}
      />
    </Switch>
  );
}
