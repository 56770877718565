import React, { useEffect, useState, useCallback } from "react";
import DataGrid, { Column, Selection } from "devextreme-react/data-grid";
import { ButtonGroup } from "devextreme-react";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";

// Material ui core
import { Grid, Typography } from "@material-ui/core";

// Material ui style
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

// Material ui icons
import { Delete as DeleteIcon } from "@material-ui/icons";

// API
import useFetch from "../../../services/useFetch";

// Components
import { Button } from "../../../components";
import Drawer from "./Drawer";

import { Container, TitleContainer, Title } from "../../style";
import { useAuth } from "../../../contexts/auth";

const theme = createTheme({
  overrides: {
    MuiDrawer: {
      paper: {
        height: "calc(100% - 64px)",
        top: "initial",
        bottom: "0",
      },
    },
  },
});

export default function User() {
  const { user } = useAuth(); // eslint-disable-line
  const api = useFetch();
  const [data, setData] = useState([]);
  const [selectedItemKeys, setSelectedItemKeys] = useState([]);

  const getAll = useCallback(async () => {
    const { data } = await api.get(`/u/user/${user.projectId}`);
    setData(data);
  }, [api, user.projectId]);

  useEffect(() => {
    getAll();
  }, []); // eslint-disable-line

  function handleNovo() {
    api
      .post(`/u/user/${user.projectId}`, {
        nome: this.nome,
        account: this.account,
        password: this.password,
        limitSession: this.limitSession,
        masterPassword: this.masterPassword,
        accountNonExpired: this.accountNonExpired,
        accountNonBlocked: this.accountNonBlocked,
        credentialsNonExpired: this.credentialsNonExpired,
      })
      .then((response) => getAll())
      .catch((error) => console.log(error));
  }

  function actionButtons(data) {
    const actionButtons = [
      {
        icon: "edit",
        type: "edit",
        hint: "Alterar",
      },
      {
        icon: "trash",
        type: "trash",
        hint: "Excluir",
      },
      {
        icon: "activefolder",
        type: "view",
        hint: "Visualizar",
      },
    ];

    return (
      <ButtonGroup
        items={actionButtons}
        keyExpr="type"
        onItemClick={(e) => console.log(e)}
        stylingMode="contained"
      />
    );
  }

  function deleteRecords() {
    selectedItemKeys.forEach((key) => {
      console.log(`Apagando ${key}`);
      api
        .delete(`/u/category/${user.projectId}/${key}`)
        .then((_response) => getAll())
        .catch((error) => console.log(error));
    });
    setSelectedItemKeys([]);
  }

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <Title>
          <TitleContainer>
            <Typography color="textPrimary">Usuário</Typography>
          </TitleContainer>
          <div className="buttons">
            <Drawer handleNovo={handleNovo} />
            <Button
              disabled={!selectedItemKeys.length}
              onClick={deleteRecords}
              startIcon={<DeleteIcon />}
              color="secondary"
            >
              Excluir
            </Button>
          </div>
        </Title>
        <Grid item xs={12} sm={12}>
          <DataGrid
            dataSource={data}
            allowColumnResizing={true}
            noDataText="Nenhum registro encontrado"
            columnResizingMode="widget"
            onSelectedRowKeysChange={(selected) =>
              setSelectedItemKeys(selected)
            }
            keyExpr="id"
            selectedRowKeys={selectedItemKeys}
            wordWrapEnabled={true}
            columnAutoWidth={true}
            showBorders={true}
            showRowLines={true}
            showColumnLines={false}
          >
            <Selection mode="multiple" />
            <Column dataField="id" caption="Id" />
            <Column dataField="name" caption="Nome" />
            <Column dataField="account" caption="Conta" />
            <Column dataField="limitSession" caption="Limitar Sessões" />
            <Column dataField="accountNonExpired" caption="Conta não expira" />
            <Column
              dataField="accountNonBlocked"
              caption="Conta não bloqueada"
            />
            <Column
              dataField="credentialsNonExpired"
              caption="Credenciais não expiram"
            />
            <Column cellRender={actionButtons} width={280} alignment="right" />
          </DataGrid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
}
