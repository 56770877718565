const messages = {
  en : {
    translations : {
      titles : {
        app : "Q4BA",
        appDescription : "Quizzes for Business Analytics"
      },
      buttons : {
        add: "Add",
        save: "Save",
        delete: "Delete",
        cancel: "Cancel",
        close: "Close",
        new: "New",
        yes: "Yes",
        no: "No",
        file: "File",
        changeProject : "Switch project",
        logout : "Logout",
        preview : "Preview",
        import : "Import",
        continue : "Continue",
        viewResult : "View Result",
        view : "View",
        sendAnswer : "Send answers",
        send : "Send",
        next : "Next",
        change : "Edit",
        visualize : "View",
        published : "Published",
        publish : "Publish",
        campaigns : "Campaigns",
        draft : "Draft",
        copy : "Copy",
        update : "Update",
        sendMails : "Send Emails",
        hideAll : "Hide All",
        showAll : "Show All",
        select: "Select",
        startOccurrence: "Start Occurrence"
      },
      messages : {
        onloadError : "Unable to load data",
        saveSuccess : "Successfully saved",
        saveError : "Could not save",
        saveErrorTryAgain : "Could not save, try again",
        saveAnswersError : "Could not save answers. Please try again.",
        deleteSuccess : "Successfully deleted",
        deleteError : "Could not delete",
        deleteErrorTryAgain : "Could not delete, please try again",
        deleteErrorInUse : "Could not delete, item is in use",
        selectedDeleteConfirm : "Confirm the deletion of selected items?",
        deleteConfirm : "Confirm the deletion?",
        excluding : "Excluding...",
        loading : "Loading...",
        processing : "Processing...",
        publishing : "Publishing...",
        updatingPublish : "Updating Publication",
        saving : "Saving...",
        wait : "Wait...",
        changingProject : "Switching project...",
        sending : "Sending",
        waitRedirect : "Wait and you will be redirected.",
        updateSuccess : "Successfully updated",
        updateError : "Unable to update",
        updateErrorTryAgain : "Could not update, please try again",
        confirmation : "Confirmation",
        changeProjectConfirmation : "Are you sure you want to change the project?",
        noRecordsFound : "No records found",
        noMoreProjectsFound : "No project found other than the current one",
        emailSended : "Email sent.",
        emailError : "It was not possible to send the email",
        actionNotCompleted : "This action could not be completed. Please try again.",
        greaterThanZero : "Enter a value greater than zero.",
        participantNotAdded : "Could not add participant",
        participantDataNotUpdated : "It was not possible to update the participant's data",
        participantExcluded : "Participant successfully deleted",
        participantNotExcluded : "It was not possible to delete the participant",
        campaignNotAdded : "Could not add campaign",
        campaignDataNotUpdated : "It was not possible to update the campaign's data",
        campaignExcluded : "Campaign successfully deleted",
        campaignNotExcluded : "It was not possible to delete the campaign",
        copied : "Copy!",
        confirmSendSelectedEmails : "Confirm selected emails sending?",
        surveyConfigEmailNotFound: "Fill in all fields in the invitation and conclusion emails",
        initOccurrence: "Do you really want to start a new incident?"
      },
      menu : {
        projectAdministration : "Project Administration",
        baseParticipants: "Base Participants",
        questions: "Questions",
        surveys: "Quizzes",
      },
      pages : {
        campaign: {
          dialog: {
            list: "Campaign",
            create: "Create campaign's",
            edit: "Edit campaign",
            occurrences: {
              list: "Occurrences",
              participants: "Participants",
            }
          },
          form: {
            name: "Name Campaign",
            startdate: "Start",
            stopdate: "End",
            recurrence: "Recurrence",
            schtriggerjobSelected: "Selected",
            listSelected: "All Selected",
            manual: "Manually generated"
          },
          list: {
            campaign: "Campaign",
            participants: "Participants",
            recurrence: "Recurrence",
            startDate: "Start",
            stopDate: "End",
            edit: "Edit campaign",
            occurrence: "Campaign occurrences",
            delete: "Delete campaign",
            copyUrl: "Copy URL link",
            qrcode : "QR Code",
          },
          occurrences: {
            list: {
              occurrence: "Occurrence",
              startDate: "Start",
              stopDate: "End",
              status: "Status",
              active: "Active",
              inactive: "Inactive",
              response: "Answers",
              participants: "Participants",
              occurrenceCreated: "The occurrence has been created. Wait for the participant list to be processed.",
            },
            participants: {
              id: "ID",
              name: "Name",
              email: "E-mail",
              response: "Answered",
              phone: "Phone",
              cpf: "CPF",
              sent: {
                yes: "Yes",
                no: "No"
              },
              notify: {
                participant: "Notify selected participant",
                participants: "Notify selected {{count}} participants",
              }
            }
          },
        },
        signin : {
          user: "User",
          password: "Password",
          enter: "Enter",
          sessionExpired : "User session has expired",
          loginAgain : "This session is no longer valid. Please log in again."
        },
        answer : {
          alreadyAnswered : "You already answered this quiz",
          justSeeAnswers : ", you can just see your answers",
          surveyUnavailableCurrently  : "Quiz unavailable",
          wantViewDashboard : "Would you like to access the dashboard with the result of your answers?",
          completed : {
            surveyCompleted : "Completed Quiz",
            answerSended : "Your answer has been sent. Thank you.",
            clickAccessResult : "To access the dashboard with the result of your responses, click on the link below.",
            accessResult : "Access the result"
          },
          form : {
            identification : "Identification",
            termReaded : "I read and accept the",
            term : "Terms",
            linkSended : "An access link to the quiz was sent to the registered email.",
            checkSpam : "If you can't find the email in your inbox, check your spam inbox as well.",
            anonymous: "Anonymous response"
          }
        },
        category : {
          title : "Categories",
          grid : {
            name : "Name",
            description : "Description",
          }
        },
        group : {
          title : "Groups",
          grid : {
            name : "Name",
            description : "Description",
          }
        },
        participant : {
          title : "Participants",
          grid : {
            name : "Name",
            phone : "Phone",
            email : "E-mail"
          }
        },
        difficulty : {
          title : "Questions Difficulties",
          grid : {
            name : "Name",
            description : "Description",
          }
        },
        habilityGroup : {
          title : "Skill Groups",
          grid : {
            name : "Name",
            description : "Description",
          }
        },
        form : {
          title : "Identification Forms",
          grid : {
            name : "Name",
          },
          form : {
            identity : "Identifier",
            attribute : "Attribute",
            phone : "Telephone",
            name : "Name",
            required : "Required"
          }
        },
        term : {
          title : "Consentiment Terms",
          grid : {
            name : "Name",
          },
        },
        survey : {
          title : "Quizzes",
          titleSingular : "Quiz",
          surveyNotFound : "Quiz does not exist or does not belong to this project",
          surveyLoadError : "Could not load quiz",
          categorysLoadError : "Could not load categories",
          termsLoadError : "Could not load terms",
          tab : {
            editor : "Editor",
            config : "Settings"
          },
          form : {
            title : "Quiz Title",
            name : "Campaign Name",
            category : "Category",
            startDate : "Start date",
            endDate : "End date",
            recurrence: "Recurrence",
            term : "Acceptance term",
            description : "Quiz Description",
            backgroundColor : "Header Background Color",
            titleColor : "Header Title Color",
            logo : "Logo",
            upload : "Upload",
            formSelect : {
              title : "Form",
              select : "Select"
            },
            recurrenceSelect : {
              title : "Recurrence",
              select : "Select"
            },
          },
          option : {
            answer : "Answer",
            punctuation : "Punctuation",
            addOption : "Add answer option",
            deleteOption : "Delete answer option",
          },
          optionGrid : {
            answerOption : "Answer Options",
          },
          optionGridColumns : {
            column : "Column",
            twoColumnsMinimum : "You must have at least two columns",
            punctuation : "Punctuation",
            columnText : "Column text",
            addColumnText : "Add column text",
            deleteColumnText : "Delete column text",
          },
          optionGridLines : {
            twoLinesMinimum : "You must have at least two lines",
            line : "Line",
            columnPunctuation : "Column Score",
            lineText : "Line text",
            addTextLine : "Add line text",
            deleteTextLine : "Delete text from line",
          },
          optionList : {
            twoOptionsMinimum : "You must have at least two answer options",
            answerOption : "Answer Options",
          },
          pageSurvey : {
            noTitle : "Untitled",
            page : "Page",
            deleteConfirmation : "Confirm the deletion of the Page and its contents?",
            dragging : "Drag and drop a component type here",
          },
          pageSurveyList : {
            noTitle : "Untitled",
            unableToSave: "Could not save Page ",
            unableToDelete: "Could not delete the Page ",
            pageExcluded : "Deleted page",
            ondePageMinimum : "You must have at least one page",
          },
          question : {
            fillDescription : "Fill in the Description field",
            grid : {
              difficulty : "Difficulty",
              group : "Group",
              weight : "Weight",
            },
            requireAnswerEachLine : "Require an answer on each line",
            requiredQuestion : "Required Question",
            calculateResult : "Calculate result",
            questionDescription : "Question Description (optional)"
          },
          questionSample : {
            updateComponent : "Click to change component",
          },
          questionTypeList : {
            components : "Components",
            singleChoice : "Single Choice",
            multipleChoice : "Multiple choice",
            shortAnswer : "Short answer",
            longAnswer : "Long Answer",
            label : "Label",
            singleChoiceMatrix : "Single Choice Matrix",
            multipleChoiceMatrix : "Multiple Choice Matrix",
          },
          surveyConfig : {
            fillInviteEmail : "Fill in the email, name, subject and message fields for the invitation email",
            fillConclusionEmail : "Fill in the email, name, subject and message fields for the completion email",
            tab : {
              survey : "Quiz",
              integration : "Integrations",
              email : "E-mail"
            },
            showNumbering : "Display question numbering",
            restartPageNumbering : "Restart numbering every page",
            participantsCan : "Participants can:",
            editAfterSending : "Edit after submission",
            viewResultAfterSending : "View the overall result",
            viewResultGroupAfterSending : "View the result by group",
            accessOnlyWithLink : "Access the quiz only via e-mail",
            anonymousParticipant: "Allow anonymous participants",
            emailMacro : {
              description : "Use the macros below to customize the email",
              participante : " - will be replaced by the name of the participant (email recipient)",
              titulo : " - will be replaced by the title of the quiz",
              descricao : " - will be replaced by the description of the quiz",
              link : " - will be replaced by the access link to the quiz",
              linkResultado : " - will be replaced by the access link to the result of the quiz already answered",
            },
            inviteEmail : "Invitation email",
            inviteEmailPopover : "This is the email that will be sent to participants giving access to the quiz",
            inviteEmailFrom : "From Email",
            inviteEmailFromName : "From sender's name",
            inviteEmailSubject : "Subject",
            conclusionEmail : "Completion email",
            conclusionEmailPopover : "This is the email that will be sent to the participant after he answers the quiz",
          },
          surveyList : {
            created: "Created in: ",
            modified : "Modified into: ",
            surveyEdit : "Edit Quiz",
            surveyDelete : "Delete Quiz",
            surveyAccess : "Access Quiz",
            surveyView : "View Quiz",
          }
        },
        results : {
          wait : "Wait while we calculate your result.",
          unavailableResult : "Access the quiz only via the e-mail",
        }
      }
    }
  }
}

export { messages }
