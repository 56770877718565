import React, { useState } from "react";
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useAuth } from "../../contexts/auth";
import Q4baLogo from '../../img/q4ba-logo-svg.jsx';
import { i18n } from '../../translate/i18n';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://ceta.ceo/">
        CetaCEO
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    /* align: 'center', */
    /* position: 'absolute', */
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn() {
  const { signIn } = useAuth();
  const [signing, setSigning] = useState(false);
  const [message, setMessage] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>         
        <div >
          <Q4baLogo qFill="#3f51b5" 
               numeroFill="#ff3381"
                   baFill="#3f51b5"
                   escala="1" />
        </div>
{/*         <Typography component="h1" variant="h5">
          Q4BA
        </Typography> */}
        <Typography >
          { i18n.t('titles.appDescription') }
        </Typography>
        <form className={classes.form} noValidate
          onSubmit={(event) => {
            event.preventDefault();
            setSigning(true);
            setMessage("");
            signIn({ username, password }).then(function ({ signed, message }) {
              if (!signed) {
                setMessage(message);
                setSigning(signed);
              }
            });
          }}
        >
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label={ i18n.t('pages.signin.user') }
            type="email"
            value={username}
            onChange={(event) => setUsername(event.target.value)}
            required={true}
            autoComplete="email"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label={ i18n.t('pages.signin.password') }
            type="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            required={true}
          />
          <div>
              <label>{message}</label>
          </div>
       {/*    <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
          <Button
            type="submit"
            disabled={signing}
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            { i18n.t('pages.signin.enter') }
          </Button>
          <Grid container>
            <Grid item xs>
         {/*      <Link href="#" variant="body2">
                Forgot password?
              </Link> */}
            </Grid>
            <Grid item>
             {/*  <Link href="#" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link> */}
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}