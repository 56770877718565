import React, { useState, useCallback, forwardRef, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

// Internacionalização
import { i18n } from '../../../translate/i18n';

// API
import useFetch from "../../../services/useFetch";

import { useSnackbar } from "notistack";

import { Button, TextField } from "../../../components";

import { useAuth } from "../../../contexts/auth";

import { Form } from "../../style";

import {
  Grid,
  Slide,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
} from "@material-ui/core";

import {
  Close as CloseIcon,
  Cancel as CancelIcon,
  AddCircle as AddCircleIcon,
} from "@material-ui/icons";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  dialog: {
    top: "64px !important",
    bottom: "0",
    //left: "70px !important",
    right: "0",
  },
  buttons: {
    "& button": {
      marginLeft: theme.spacing(2),
    },
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function FullScreenDialog({ handleSubmit, id }) {
  const classes = useStyles();
  const { user } = useAuth();
  const api = useFetch();
  const history = useHistory();
  let location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const [groupId, setGroupId] = useState(id);
  const [open, setOpen] = useState(!!groupId);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const getGroup = useCallback(async () => {
    if (groupId) {
      await api
        .get(`/u/group/${user.projectId}/${groupId}`)
        .then(({ data }) => fillGroup(data))
        .catch((error) => {
          if (groupId) {
            history.push(location.pathname.replace(`/${groupId}`, ""));
            setGroupId(null);
          }
          setOpen(false);
          if (error.response && error.response.status === 404) {
            enqueueSnackbar( i18n.t("messages.noRecordsFound") , {
              variant: "error",
            });
          }
        });
      // ;
    } // eslint-disable-next-line
  }, [
    api,
    groupId,
    enqueueSnackbar,
    history,
    location.pathname,
    user.projectId,
  ]);

  const fillGroup = useCallback((data) => {
    setName(data.name);
    setDescription(data.description);
  }, []);

  useEffect(() => {
    getGroup();
  }, []); // eslint-disable-line

  function clean() {
    setName("");
    setDescription("");
  }

  return (
    <div>
      <Button startIcon={<AddCircleIcon />} onClick={() => setOpen(true)}>
        {i18n.t("buttons.new")}
      </Button>
      <Dialog
        fullScreen
        className={classes.dialog}
        open={open}
        onClose={() => {
          setOpen(false);
          if (groupId) {
            history.push(location.pathname.replace(`/${groupId}`, ""));
            setGroupId(null);
          }
          clean();
        }}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setOpen(false);
                if (groupId) {
                  history.push(location.pathname.replace(`/${groupId}`, ""));
                  setGroupId(null);
                }
                clean();
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
            { i18n.t("pages.group.title") }
            </Typography>
            <div className={classes.buttons}>
              <Button
                onClick={() => {
                  handleSubmit
                    .bind({
                      groupId,
                      name,
                      description,
                    })()
                    .then((_response) => {
                      enqueueSnackbar( i18n.t("messages.saveSuccess") , {
                        variant: "success",
                      });
                      setOpen(false);
                      history.push(
                        location.pathname.replace(`/${groupId}`, "")
                      );
                      setGroupId(null);
                      clean();
                    })
                    .catch((_error) => {
                      enqueueSnackbar( i18n.t("messages.saveError") , {
                        variant: "error",
                      });
                    });
                }}
              >
                { i18n.t("buttons.save") }
              </Button>
              <Button
                onClick={() => {
                  setOpen(false);
                  if (groupId) {
                    history.push(location.pathname.replace(`/${groupId}`, ""));
                    setGroupId(null);
                  }
                  clean();
                }}
                color="secondary"
                startIcon={<CancelIcon />}
              >
                { i18n.t("buttons.cancel") }
              </Button>
            </div>
          </Toolbar>
        </AppBar>
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                onChange={(e) => setName(e.target.value)}
                value={name}
                label={ i18n.t("pages.group.grid.name") }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                multiline
                rows={4}
                rowsMax={10}
                value={description}
                label={ i18n.t("pages.group.grid.description") }
                onChange={(e) => setDescription(e.target.value)}
              />
            </Grid>
          </Grid>
        </Form>
      </Dialog>
    </div>
  );
}
