import {
  AppBar,
  Dialog,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Grid,
  Hidden,
  Icon,
  IconButton,
  makeStyles,
  MenuItem,
  Slide,
  Switch,
  Toolbar,
  Typography,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { useSnackbar } from "notistack";
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import NumberFormat from "react-number-format";
import { Button, HtmlEditor, TextField } from "../../../../components";
import { useAuth } from "../../../../contexts/auth";
import useFetch from "../../../../services/useFetch";
import OptionList from "../Option/OptionList";
import OptionGrid from "../Option/OptionGrid";

// Internacionalização
import { i18n } from '../../../../translate/i18n';

import SurveyContext from "../SurveyContext";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

function Question() {
  const {
    surveyData,
    setSurveyData,
    questionData,
    setQuestionData,
  } = useContext(SurveyContext);

  const classes = useStyles();

  const descriptionRef = useRef(null);
  const commentRef = useRef(null);
  //const [difficultyId, setDifficultyId] = useState("");
  //const [groupId, setGroupId] = useState("");
  //const [weight, setWeight] = useState("");
  //const [required, setRequired] = useState("");

  const [groups, setGroups] = useState([]);
  const [difficulties, setDifficulties] = useState([]);

  const [saving, setSaving] = useState(false);

  const { user } = useAuth();
  const api = useFetch();
  const { enqueueSnackbar } = useSnackbar();

  const getDifficulties = useCallback(async () => {
    const { data } = await api.get(`/u/project/${user.projectId}/difficulty`);
    setDifficulties(data);
  }, [api, user.projectId]);

  const getGroups = useCallback(async () => {
    const { data } = await api.get(`/u/project/${user.projectId}/group`);
    setGroups(data);
  }, [api, user.projectId]);

  const handleChange = useCallback(
    (event, question) => {
      setQuestionData({ ...question, [event.target.name]: event.target.value });
    },
    [setQuestionData]
  );

  const fillQuestion = useCallback(
    (data) => {
      setQuestionData(data);
    },
    [setQuestionData]
  );

  useEffect(() => {
    getDifficulties();
    getGroups();
  }, []);

  useEffect(() => {
    if (questionData) {
      fillQuestion(questionData);
    }
  }, [fillQuestion, questionData]);

  const handleClose = useCallback(() => {
    setQuestionData(null);
  }, [setQuestionData]);

  const handleSubmit = useCallback((event) => {
    event.preventDefault();
    let comment = "";


    const description = descriptionRef.current.instance.option().value;
    
    if (!description) {
      enqueueSnackbar( i18n.t("pages.survey.question.fillDescription") , { variant: "warning" });
      return;
    }

    try {
      comment = commentRef.current.instance.option().value;
    } catch { }

    setSaving(true);

    const url = `/u/project/${user.projectId}/survey/${surveyData.id}/page/${
      questionData.pageSurvey.id
    }/question/${questionData.id || ""}`;

    const isPost = !questionData.id;
    api[isPost ? "post" : "put"](url, {
      ...questionData,
      calculation:
        questionData?.calculation === undefined
          ? true
          : questionData.calculation,
      questionTypeId: questionData.questionType.id,
      description,
      comment,
      index: questionData.id
        ? questionData.index
        : questionData.pageSurvey.questions.length,
    })
      .then(({ data, config: { method } }) => {
        enqueueSnackbar( i18n.t("messages.saveSuccess") , { variant: "success" });
        setQuestionData(data);

        const newPageSurveys = surveyData.pageSurveys.map((pageSurvey) => {
          if (pageSurvey.id === data.pageSurvey.id) {
            const newPageSurvey = pageSurvey;
            if (isPost) {
              newPageSurvey.questions.push(data);
              return newPageSurvey;
            } else {
              const newQuestions = newPageSurvey.questions.map((q) => {
                if (q.id === data.id) return data;
                else return q;
              });
              return { ...newPageSurvey, questions: newQuestions };
            }
          } else return pageSurvey;
        });

        setSurveyData({ ...surveyData, pageSurveys: newPageSurveys });
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar( i18n.t("messages.saveErrorTryAgain") ,
          { variant: "error" }
        );
      })
      .then(() => setSaving(false));
    }, [
      user.projectId,
      surveyData,
      questionData,
      api,
      enqueueSnackbar,
      setQuestionData,
      setSurveyData]);

  const handleBlurWeight = useCallback(
    (event, question) => {
      const value = event.target.value.split(",").join("");
      if (Number(value) === 0) {
        enqueueSnackbar( i18n.t("messages.greaterThanZero") , {
          variant: "warning",
        });
        setQuestionData({ ...question, [event.target.name]: "" });
      }
    },
    [enqueueSnackbar, setQuestionData]
  );

  return (
    <Dialog
      fullScreen
      open={!!questionData}
      TransitionComponent={Transition}
      className={classes.dialog}
    >
      <form onSubmit={handleSubmit} autoComplete="off">
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose}>
              <Icon>close</Icon>
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {questionData?.questionType?.name} | Página{" "}
              {questionData?.pageSurvey?.pageNumber}
            </Typography>
            <Hidden xsDown>
              <Button type="submit" disabled={saving} startIcon={<SaveIcon />}>
                {saving ? i18n.t("messages.saving") : i18n.t("buttons.save")}
              </Button>
            </Hidden>
          </Toolbar>
        </AppBar>
        <DialogContent className={classes.dialogContent}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <HtmlEditor
                ref={descriptionRef}
                defaultValue={questionData?.description}
                height={questionData?.questionType.code === "text" ? 400 : 250}
                name="description"
              />
            </Grid>
          </Grid>
          {questionData?.questionType.code !== "text" ? (
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12} sm={3}>
                <TextField
                  select
                  label={ i18n.t("pages.survey.question.grid.difficulty") }
                  name="difficultyId"
                  value={questionData?.difficultyId}
                  onChange={(event) => handleChange(event, questionData)}
                  required={true}
                >
                  {difficulties?.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  select
                  label={ i18n.t("pages.survey.question.grid.group") }
                  name="groupId"
                  value={questionData?.groupId}
                  onChange={(event) => handleChange(event, questionData)}
                  required={true}
                >
                  {groups?.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={1}>
                <TextField
                  label={ i18n.t("pages.survey.question.grid.weight") }
                  name="weight"
                  value={questionData?.weight}
                  required={true}
                  onChange={(event) => handleChange(event, questionData)}
                  onBlur={(event) => handleBlurWeight(event, questionData)}
                  inputProps={{
                    maxLength: "15",
                    style: { textAlign: "right" },
                  }}
                  InputProps={{
                    inputComponent: NumberFormatWeight,
                  }}
                  placeholder="1,00"
                />
              </Grid>
              <Grid item xs={12} sm style={{ paddingLeft: "15px" }}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        name="required"
                        checked={questionData?.required}
                        onChange={(event) =>
                          handleChange(
                            {
                              ...event,
                              target: {
                                ...event.target,
                                name: event.target.name,
                                value: event.target.checked,
                              },
                            },
                            questionData
                          )
                        }
                      />
                    }
                    label={
                      questionData?.questionType.hasOptions &&
                      ["grid_radio", "grid_checkbox"].includes(
                        questionData?.questionType.code
                      )
                        ? i18n.t("pages.survey.question.requireAnswerEachLine")
                        : i18n.t("pages.survey.question.requiredQuestion")
                    }
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        name="calculation"
                        checked={questionData?.calculation}
                        defaultChecked={
                          questionData?.calculation === undefined
                            ? true
                            : questionData.calculation
                        }
                        onChange={(event) =>
                          handleChange(
                            {
                              ...event,
                              target: {
                                ...event.target,
                                name: event.target.name,
                                value: event.target.checked,
                              },
                            },
                            questionData
                          )
                        }
                      />
                    }
                    label={i18n.t("pages.survey.question.calculateResult")}
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  style={{
                    borderBottom: "1px solid rgba(0,0,0,.1)",
                    marginTop: "15px",
                    marginBottom: "10px",
                  }}
                >
                  {i18n.t("pages.survey.question.questionDescription")}
                </Typography>
                <HtmlEditor
                  ref={commentRef}
                  defaultValue={questionData?.comment}
                  height={questionData?.questionType.code === "ggg" ? 400 : 250}
                  name="comment"
                />
              </Grid>
            </Grid>
          ) : (
            <React.Fragment />
          )}

          <Grid container spacing={1}>
            <Grid item xs={12}>
              {questionData?.questionType.hasOptions &&
                ["radio", "checkbox"].includes(
                  questionData?.questionType.code
                ) && <OptionList />}
              {questionData?.questionType.hasOptions &&
                ["grid_radio", "grid_checkbox"].includes(
                  questionData?.questionType.code
                ) && <OptionGrid />}
            </Grid>
          </Grid>

          <Hidden smUp>
            <Button
              type="submit"
              disabled={saving}
              startIcon={<SaveIcon />}
              style={{ marginTop: "20px" }}
              fullWidth
            >
              {saving ? i18n.t("messages.saving") : i18n.t("buttons.save") }
            </Button>
          </Hidden>
        </DialogContent>
      </form>
    </Dialog>
  );
}

function NumberFormatWeight(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString={true}
      decimalSeparator=","
      decimalScale={2}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  dialog: {
    top: `${window.innerWidth < 600 ? "0" : "70px !important"}`,
    bottom: "0",
    left: `${window.innerWidth < 600 ? "0" : "70px !important"}`,
    right: "0",
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  dialogContent: {
    paddingBottom: `${window.innerWidth < 600 ? "24px" : "150px"}`,
  },
}));

export default memo(Question);
